const axios = require('axios');
const config = require('../config/config');
const qs = require('qs');

/* APIs ENDPOINTS */
const SESSION_STORAGE_SESSION = 'session';
const SERVER_URL = config.backend.apiUrl;
const LOGIN_URL = SERVER_URL + '/auth/login';
const IS_VALID_JWT_URL = SERVER_URL + '/auth/is-valid-jwt';
const CHANGE_PASSWORD_URL = SERVER_URL + '/auth/change-password';
const RESET_URL = SERVER_URL + '/auth/reset';
const RESFRESH_TOKEN_URL = SERVER_URL + '/auth/refresh-token';

const CURRENT_USER_URL = SERVER_URL + '/user/me';
const CURRENT_USER_SUBSCRIPTION_URL = SERVER_URL + '/user/subscription';
const USERS_URL = SERVER_URL + '/user';
const USERS_INDEX_URL = SERVER_URL + '/user/{id}';
const USER_PATCH_MP_EMAIL_URL = USERS_URL + '/mpEmail';
const CHECK_USER_SESSION_URL = SERVER_URL + '/user/checkSession';

const USER_EMAIL_VALIDATION_URL = USERS_URL + '/validation';
const USER_ID_EMAIL_VALIDATION_URL = USERS_URL + '/validation/{id}';
const USER_ID_SEND_EMAIL_VALIDATION_URL =
  USERS_URL + '/validation/{id}/sendEmail';
const SUGGESTION_EMAIL_URL = USERS_URL + '/{id}/suggestion';

const PATIENTS_URL = SERVER_URL + '/patient';
const PATIENTS_INDEX_URL = SERVER_URL + '/patient/{id}';
const APPOINTMENTS_URL = SERVER_URL + '/appointment';
const APPOINTMENTS_INDEX_URL = SERVER_URL + '/appointment/{id}';
const APPOINTMENTS_REMINDER_URL = SERVER_URL + '/appointment/{id}/reminder';

const EXAMS_URL = SERVER_URL + '/exam';
const EXAMS_INDEX_URL = SERVER_URL + '/exam/{id}';
const PARENT_TEST_URL = SERVER_URL + '/parenttest';
const PARENT_TEST_INDEX_URL = SERVER_URL + '/parenttest/{id}';
const STUDIES_URL = SERVER_URL + '/studytype';
const STUDIES_INDEX_URL = SERVER_URL + '/studytype/{id}';
const REGISTER_URL = SERVER_URL + '/auth/register';

const ATTACHMENT_URL = SERVER_URL + '/attachment';
const ATTACHMENT_DOWNLOAD_URL = ATTACHMENT_URL + '/download/{uuidName}';
const ATTACHMENT_DELETE_URL = ATTACHMENT_URL + '/delete';
const ATTACHMENT_UPLOAD_URL = ATTACHMENT_URL + '/upload/{examId}';
const ATTACHMENT_EXAM_URL = ATTACHMENT_URL + '/{examId}';

const PROFILE_PICTURE_URL = USERS_URL + '/pfp';

const SUBSCRIPTION_URL = SERVER_URL + '/mercadopago';
export const PROCESS_PAYMENT_URL = SUBSCRIPTION_URL + '/token';
const SUBSCRIPTION_PLAN_URL = SUBSCRIPTION_URL + '/subscriptionPlan';

const ALPHA_WARNING_STORAGE = 'alpha-warning';

/* Auth APIs */

function logout() {
  localStorage.removeItem(SESSION_STORAGE_SESSION);
}

async function isAuthenticated() {
  const session = localStorage.getItem(SESSION_STORAGE_SESSION);
  if (session !== null) {
    try {
      let response = await axios.get(IS_VALID_JWT_URL, buildHeaders());
      return response.data;
    } catch (err) {
      localStorage.removeItem(SESSION_STORAGE_SESSION);
      return false;
    }
  } else {
    return false;
  }

  //return session !== null;
}

function buildHeaders() {
  if (localStorage.getItem(SESSION_STORAGE_SESSION)) {
    return {
      headers: {
        Authorization:
          'Bearer ' + JSON.parse(localStorage.getItem(SESSION_STORAGE_SESSION)),
      },
    };
  } else {
    return null;
  }
}

function login(payload) {
  logout(); // this will clear old token
  return axios
    .post(LOGIN_URL, {
      email: payload.email,
      password: payload.password,
    })
    .then(function (response) {
      localStorage.setItem(
        SESSION_STORAGE_SESSION,
        JSON.stringify(response.data.token),
      );
      return response;
    });
}

function register(payload) {
  return axios
    .post(REGISTER_URL, {
      firstName: payload.firstName,
      lastName: payload.lastName,
      password: payload.password,
      email: payload.email,
    })
    .then(function (response) {
      localStorage.setItem(
        SESSION_STORAGE_SESSION,
        JSON.stringify(response.data.token),
      );
      return response;
    });
}

function resetPassword(payload) {
  return axios
    .post(RESET_URL, {
      username: payload.email,
    })
    .then(function (response) {
      return response;
    });
}

function changePassword(payload) {
  return axios
    .post(
      CHANGE_PASSWORD_URL,
      {
        oldPassword: payload.oldPassword,
        newPassword: payload.newPassword,
      },
      buildHeaders(),
    )
    .then(function (response) {
      return response;
    });
}

function refreshToken(history) {
  return axios
    .get(RESFRESH_TOKEN_URL, buildHeaders())
    .then(response => {
      if (response.status === 401) {
        logout();
        if (history) {
          history.push('/login', {
            msg: 'Se cerró la sesión al detectar múltiples ingresos con la misma cuenta.',
          });
        }
      }
      if (response.status === 201) {
        localStorage.setItem(
          SESSION_STORAGE_SESSION,
          JSON.stringify(response.headers.token),
        );
      }
      return response;
    })
    .catch(e => {
      console.error(e);
      logout();
      if (history) {
        history.push('/login', {
          msg: 'Se cerró la sesión al detectar múltiples ingresos con la misma cuenta.',
        });
      }
    });
}

/* User APIs */

function getCurrentUser() {
  return axios.get(CURRENT_USER_URL, buildHeaders());
}

function getUsers() {
  return axios.get(USERS_URL, buildHeaders());
}

function getUsersCount() {
  return axios.get(USERS_URL + '/count', buildHeaders());
}

function getUser(userId) {
  return axios.get(USERS_INDEX_URL.replace('{id}', userId), buildHeaders());
}

function deleteUser(UserId) {
  return axios.delete(USERS_INDEX_URL.replace('{id}', UserId), buildHeaders());
}

function postUser(payload) {
  return axios.post(USERS_URL, payload, buildHeaders());
}

function patchUser(userId, payload) {
  return axios.patch(
    USERS_INDEX_URL.replace('{id}', userId),
    payload,
    buildHeaders(),
  );
}

function patchUserProfile(payload) {
  return axios.patch(USERS_URL, payload, buildHeaders());
}

function sendUserValidationEmail(userId) {
  return axios.post(
    USER_ID_SEND_EMAIL_VALIDATION_URL.replace('{id}', userId),
    {},
    buildHeaders(),
  );
}

function checkUserEmailValidation(userId) {
  return axios.get(
    `${USER_EMAIL_VALIDATION_URL}?${qs.stringify(
      { userId },
      { skipNulls: true },
    )}`,
  );
}

function validateUserEmail(userId, validationString) {
  return axios.post(
    `${USER_ID_EMAIL_VALIDATION_URL.replace('{id}', userId)}?${qs.stringify(
      { validationString },
      { skipNulls: true },
    )}`,
  );
}

function sendSuggestionEmail(userId, payload) {
  return axios.post(
    SUGGESTION_EMAIL_URL.replace('{id}', userId),
    payload,
    buildHeaders(),
  );
}

/* Patients APIs */

function getPatients(parameters = []) {
  return axios.get(
    `${PATIENTS_URL}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function getPatientsCount() {
  return axios.get(PATIENTS_URL + '/count', buildHeaders());
}

function getPatient(userId) {
  return axios.get(PATIENTS_INDEX_URL.replace('{id}', userId), buildHeaders());
}

function deletePatient(PatientId) {
  return axios.delete(
    PATIENTS_INDEX_URL.replace('{id}', PatientId),
    buildHeaders(),
  );
}

function postPatient(payload) {
  return axios.post(PATIENTS_URL, payload, buildHeaders());
}

function patchPatient(userId, payload) {
  return axios.patch(
    PATIENTS_INDEX_URL.replace('{id}', userId),
    payload,
    buildHeaders(),
  );
}

/* Appointments APIs */

function getAppointments() {
  return axios.get(APPOINTMENTS_URL, buildHeaders());
}

function getAppointmentsCount() {
  return axios.get(APPOINTMENTS_URL + '/count', buildHeaders());
}

function getAppointment(userId) {
  return axios.get(
    APPOINTMENTS_INDEX_URL.replace('{id}', userId),
    buildHeaders(),
  );
}

function deleteAppointment(AppointmentId) {
  return axios.delete(
    APPOINTMENTS_INDEX_URL.replace('{id}', AppointmentId),
    buildHeaders(),
  );
}

function postAppointment(payload) {
  return axios.post(APPOINTMENTS_URL, payload, buildHeaders());
}

function patchAppointment(appointmentId, payload) {
  return axios.patch(
    APPOINTMENTS_INDEX_URL.replace('{id}', appointmentId),
    payload,
    buildHeaders(),
  );
}

function getAppointmentReminder(id) {
  return axios.get(
    APPOINTMENTS_REMINDER_URL.replace('{id}', id),
    buildHeaders(),
  );
}

/* Exams APIs */

function getExams(parameters = []) {
  return axios.get(
    `${EXAMS_URL}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function getExamsCount() {
  return axios.get(EXAMS_URL + '/count', buildHeaders());
}

function getExam(id) {
  return axios.get(EXAMS_INDEX_URL.replace('{id}', id), buildHeaders());
}

function deleteExam(id) {
  return axios.delete(EXAMS_INDEX_URL.replace('{id}', id), buildHeaders());
}

function postExam(payload) {
  return axios.post(EXAMS_URL, payload, buildHeaders());
}

function patchExam(id, payload) {
  return axios.patch(
    EXAMS_INDEX_URL.replace('{id}', id),
    payload,
    buildHeaders(),
  );
}

//API Parent Test
function getParentTests(parameters = []) {
  return axios.get(
    `${PARENT_TEST_URL}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function getParentTestCount() {
  return axios.get(PARENT_TEST_URL + '/count', buildHeaders());
}

function getParentTest(id) {
  return axios.get(PARENT_TEST_INDEX_URL.replace('{id}', id), buildHeaders());
}

function deleteParentTest(id) {
  return axios.delete(
    PARENT_TEST_INDEX_URL.replace('{id}', id),
    buildHeaders(),
  );
}

function postParentTest(payload) {
  return axios.post(PARENT_TEST_URL, payload, buildHeaders());
}

function patchParentTest(id, payload) {
  return axios.patch(
    PARENT_TEST_INDEX_URL.replace('{id}', id),
    payload,
    buildHeaders(),
  );
}

//API Study Type
function getStudyTypes(parameters = []) {
  return axios.get(
    `${STUDIES_URL}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function getStudyTypeCount() {
  return axios.get(STUDIES_URL + '/count', buildHeaders());
}

function getStudyType(id) {
  return axios.get(STUDIES_INDEX_URL.replace('{id}', id), buildHeaders());
}

function deleteStudyType(id) {
  return axios.delete(STUDIES_INDEX_URL.replace('{id}', id), buildHeaders());
}

function postStudyType(payload) {
  return axios.post(STUDIES_URL, payload, buildHeaders());
}

function patchStudyType(id, payload) {
  return axios.patch(
    STUDIES_INDEX_URL.replace('{id}', id),
    payload,
    buildHeaders(),
  );
}

// API File Upload
function uploadExamAttachment(payload, examId) {
  return axios.post(
    ATTACHMENT_UPLOAD_URL.replace('{examId}', examId),
    payload,
    buildHeaders(),
  );
}

function downloadFile(uuidName) {
  return axios.get(ATTACHMENT_DOWNLOAD_URL.replace('{uuidName}', uuidName), {
    ...buildHeaders(),
    responseType: 'blob',
  });
}

function deleteFile(uuidName) {
  return axios.post(ATTACHMENT_DELETE_URL, { uuidName }, buildHeaders());
}

function getExamAttachments(examId) {
  return axios.get(
    ATTACHMENT_EXAM_URL.replace('{examId}', examId),
    buildHeaders(),
  );
}

// API Profile pictures
function setProfilePicture(payload) {
  return axios.post(PROFILE_PICTURE_URL, payload, buildHeaders());
}

function getProfilePicture() {
  return axios.get(PROFILE_PICTURE_URL, {
    ...buildHeaders(),
    responseType: 'blob',
  });
}

// API Subscription Plan

function getCurrentUserSubscription() {
  return axios
    .get(CURRENT_USER_SUBSCRIPTION_URL, buildHeaders())
    .then(response => {
      return response;
    })
    .catch(e => {
      console.error(e);
    });
}

function getSubscriptionPlan() {
  return axios.get(SUBSCRIPTION_PLAN_URL, buildHeaders());
}

function setHideAlphaAlert(boolState) {
  localStorage.setItem(ALPHA_WARNING_STORAGE, JSON.stringify(boolState));
}

function getHideAlphaAlert() {
  return localStorage.getItem(ALPHA_WARNING_STORAGE);
}

export default {
  SERVER_URL,
  logout,
  login,
  isAuthenticated,
  resetPassword,
  changePassword,
  refreshToken,
  getCurrentUser,
  getUsers,
  getUsersCount,
  getUser,
  deleteUser,
  postUser,
  patchUser,
  patchUserProfile,
  checkUserEmailValidation,
  sendUserValidationEmail,
  validateUserEmail,
  sendSuggestionEmail,

  getPatients,
  getPatientsCount,
  getPatient,
  deletePatient,
  postPatient,
  patchPatient,
  getAppointments,
  getAppointmentsCount,
  getAppointment,
  deleteAppointment,
  postAppointment,
  patchAppointment,
  getAppointmentReminder,

  getExams,
  getExamsCount,
  getExam,
  deleteExam,
  postExam,
  patchExam,
  getStudyTypes,
  getStudyTypeCount,
  getStudyType,
  deleteStudyType,
  postStudyType,
  patchStudyType,

  getParentTests,
  getParentTestCount,
  getParentTest,
  deleteParentTest,
  postParentTest,
  patchParentTest,

  uploadExamAttachment,
  downloadFile,
  deleteFile,
  getExamAttachments,

  setProfilePicture,
  getProfilePicture,

  getCurrentUserSubscription,
  getSubscriptionPlan,

  register,

  setHideAlphaAlert,
  getHideAlphaAlert,
};
