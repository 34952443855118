import React from 'react';
import { Link } from 'react-router-dom';
import './../Landing';
import apiHelper from './../../../utils/apiHelpers';
import { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import {
  GroupInput,
  Input,
  Icons,
  MsgError,
  leyendError,
} from '../elementsForm/Form';
import ComponentInput from '../elementsForm/ComponentInput';
import TermsAndConditionsModal from '../elementsForm/TermsAndConditionsModal';
import Swal from 'sweetalert2';
import { element } from 'prop-types';

const Register = props => {
  const [firstName, setFirstName] = useState({ data: '', validate: null });
  const [lastName, setLastName] = useState({ data: '', validate: null });
  const [password, setPassword] = useState({ data: '', validate: null });
  const [repeatPassword, setRepeatPassword] = useState({
    data: '',
    validate: null,
  });
  const [email, setEmail] = useState({ data: '', validate: null });
  const [repeatEmail, setRepeatEmail] = useState({ data: '', validate: null });
  const [termsAndConditionsBox, setTermsAndConditionsBox] = useState(false);
  const [showTOSModal, setShowTOSModal] = useState(false);

  const expretion = {
    usuario: /^[a-zA-Z0-9\_\-]{4,16}$/, // Letras, numeros, guion y guion_bajo
    nombre: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // Letras y espacios, pueden llevar acentos.
    password: /^.{4,12}$/, // 4 a 12 digitos.
    correo: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  };

  const [errorMessage, setErrorMessage] = useState(false);

  const validatePassword = () => {
    if (password.data.length > 0) {
      if (password.data !== repeatPassword.data) {
        setRepeatPassword(prevState => {
          return { ...prevState, validate: 'false' };
        });
      } else {
        setRepeatPassword(prevState => {
          return { ...prevState, validate: 'true' };
        });
      }
    }
  };

  const validateEmail = () => {
    if (email.data.length > 0) {
      if (email.data !== repeatEmail.data) {
        setRepeatEmail(prevState => {
          return { ...prevState, validate: 'false' };
        });
      } else {
        setRepeatEmail(prevState => {
          return { ...prevState, validate: 'true' };
        });
      }
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (
      [firstName, lastName, repeatEmail, repeatPassword].some(
        element => element.validate != 'true',
      )
    ) {
      // Terms and Conditions not checked
      setErrorMessage('Algunos datos no son válidos.');
      window.scrollTo(0, 0);
      return;
    }

    if (termsAndConditionsBox) {
      let respId;
      apiHelper
        .register({
          firstName: firstName.data,
          lastName: lastName.data,
          email: email.data,
          // username: username.data,
          password: password.data,
        })
        .then(response => {
          respId = response.data.user.id;
          return Swal.fire({
            title: 'Cuenta creada con éxito',
            text: `Se enviará un email de verificación a ${email.data}.`,
            icon: 'success',
            confirmButtonColor: '#22654e',
            confirmButtonText: 'Continuar',
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
              const confirmButton = Swal.getConfirmButton();
              confirmButton.disabled = true;
              setTimeout(() => {
                confirmButton.disabled = false;
              }, 5000);
            },
          });
        })
        .then(() => {
          return apiHelper.sendUserValidationEmail(respId).then(() => {
            apiHelper.logout();
            props.history.push('/login');
          });
        })
        .catch(error => {
          const statusCode = error.response.data.error;
          if (statusCode) {
            setErrorMessage(error.response.data.error);
          }
        });
    } else {
      // Terms and Conditions not checked
      setErrorMessage('Debe aceptar los Términos y Condiciones.');
    }
    window.scrollTo(0, 0);
  };

  let alert;
  if (errorMessage) {
    alert = <div className="alert alert-danger">{errorMessage}</div>;
  }

  return (
    <div
      className={`${props.className} justify-content-md-center fadeInDown signup-card`}>
      <TermsAndConditionsModal
        show={showTOSModal}
        onHide={() => setShowTOSModal(false)}
        animation={false}
        scrollable={true}
      />
      <ReactTooltip />

      <form onSubmit={handleSubmit}>
        <div className="fadeIn first container">
          {/*
          <label className="label-vpsy-font">Nombre de Usuario</label>
          <ComponentInput
            label="Nombre de Usuario"
            name="username"
            type="text"
            info={username}
            setInfo={setUserName}
            placeholder="No definido"
            dataTip="Lo utilizarás cada vez que te inicies sesión"
            //theError="Nombre de usuario NO esta disponible"
            regularExpretion={expretion.usuario}
          />
        */}

          <div className="row">
            <div className="col-6">
              <label className="label-vpsy-font">Nombre(s)</label>
              <ComponentInput
                label="inputFirstName"
                name="firstName"
                type="text"
                info={firstName}
                setInfo={setFirstName}
                placeholder="No definido"
                dataTip="Tu paciente visualizará este dato"
                regularExpretion={expretion.nombre}
                theError="Solo letras de la A a la Z"
              />
            </div>
            <div className="col-6">
              <label className="label-vpsy-font">Apellido</label>
              <ComponentInput
                label="inputLastName"
                name="lastName"
                type="text"
                info={lastName}
                setInfo={setLastName}
                placeholder="No definido"
                dataTip="Tu paciente visualizará este dato"
                regularExpretion={expretion.nombre}
                theError="Solo letras de la A a la Z"
              />
            </div>
          </div>

          <label className="label-vpsy-font">Correo electrónico</label>
          <ComponentInput
            label="Email"
            name="email"
            type="email"
            info={email}
            setInfo={setEmail}
            placeholder="ejemplo@mimail.com"
            theError="El mail ingresado es incorrecto: ejemplo@mail.com"
            regularExpretion={expretion.correo}
          />

          <label className="label-vpsy-font">Repetir Correo electrónico</label>
          <ComponentInput
            label="Repeat Email"
            name="repeatEmail"
            type="email"
            placeholder="ejemplo@mimail.com"
            theError="Los emails no coinciden"
            regularExpretion={expretion.correo}
            info={repeatEmail}
            setInfo={setRepeatEmail}
            validateEmail={validateEmail}
          />

          <label className="label-vpsy-font">Contraseña</label>
          <ComponentInput
            label="password"
            name="password"
            type="password"
            info={password}
            setInfo={setPassword}
            placeholder="No definido"
            theError="La contraseña debe tener entre 4 y 12 caracteres"
            regularExpretion={expretion.password}
          />

          <label className="label-vpsy-font">Repetir contraseña</label>
          <ComponentInput
            label="Repeat Password"
            name="repeatPassword"
            type="password"
            info={repeatPassword}
            setInfo={setRepeatPassword}
            placeholder="No definido"
            theError="Las contraseñas no coinciden"
            validatePassword={validatePassword}
          />

          <div className="form-check my-3 ml-3">
            <input
              className="form-check-input"
              type="checkbox"
              id="TermsAndConditionsBox"
              name="TermsAndConditionsBox"
              onChange={e => {
                setTermsAndConditionsBox(e.target.checked);
              }}
            />
            <label
              className="ml-0 label-vpsy-font form-check-label"
              htmlFor="TermsAndConditionsBox">
              Acepto los{' '}
              <a
                href="#"
                onClick={() => {
                  setShowTOSModal(true);
                }}>
                Términos y Condiciones
              </a>
            </label>
          </div>

          <div className="row justify-content-md-center mt-4">
            <div className="col mx-2">
              <Link to="/login">
                <button
                  className="btn btn-vpsy-outline btn-block fadeIn fourth"
                  formAction="index.html"
                  type="submit">
                  Cancelar
                </button>
              </Link>
            </div>

            <div className="col mx-2">
              <button
                id="sign-up"
                className="btn btn-vpsy-solid btn-block mr-4 fadeIn fourth"
                onClick={e => handleSubmit(e)}
                type="submit">
                Registrarme
              </button>
            </div>
          </div>
        </div>

        {alert && (
          <p>
            <b>
              <i className="fas fa-exclamation-triangle"></i> Error: {alert}
            </b>
          </p>
        )}
      </form>
    </div>
  );
};

export default Register;
