import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const TermsAndConditionsModal = props => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Términos y Condiciones
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>¡Bienvenidos a VPsycology!</h4>
        <div>
          Estos términos y condiciones describen las reglas y regulaciones para
          el uso del sitio web de Mentaly, ubicado en https://Mentaly.ar
          <br />
          Al acceder a este sitio web asumimos que acepta estos términos y
          condiciones. No continúe usando Mentaly si no está de acuerdo con
          todos los términos y condiciones establecidos en esta página.
          <br />
          La siguiente terminología se aplica a estos Términos y condiciones,
          Declaración de privacidad y Aviso de exención de responsabilidad y
          todos los Acuerdos: "Cliente", "Usted" y "Su" se refieren a usted, la
          persona que inicia sesión en este sitio web y cumple con los términos
          y condiciones de la Compañía. "La Compañía", "Nosotros mismos",
          "Nosotros", "Nuestro" y "Nosotros", se refiere a nuestra Compañía.
          "Parte", "Partes" o "Nosotros", se refiere tanto al Cliente como a
          nosotros mismos. Todos los términos se refieren a la oferta,
          aceptación y consideración del pago necesario para llevar a cabo el
          proceso de nuestra asistencia al Cliente de la manera más adecuada con
          el propósito expreso de satisfacer las necesidades del Cliente con
          respecto a la prestación de los servicios indicados por la Compañía,
          de conformidad con y sujeto a la legislación vigente de Argentina.
        </div>

        <h4>Cookies</h4>
        <div>
          Empleamos el uso de cookies. Al acceder a Mentaly, usted aceptó usar
          cookies de acuerdo con la Política de Privacidad de Mentaly.
          <br />
          La mayoría de los sitios web interactivos utilizan cookies para
          permitirnos recuperar los detalles del usuario para cada visita.
          Nuestro sitio web utiliza cookies para habilitar la funcionalidad de
          ciertas áreas para que sea más fácil para las personas que visitan
          nuestro sitio web. Algunos de nuestros socios afiliados/publicitarios
          también pueden usar cookies.
        </div>

        <h4>Licencia</h4>
        <div>
          A menos que se indique lo contrario, Mentaly y/o sus licenciatarios
          poseen los derechos de propiedad intelectual de todo el material de
          Mentaly. Todos los derechos de propiedad intelectual están reservados.
          Puede acceder a esto desde Mentaly para su uso personal sujeto a las
          restricciones establecidas en estos términos y condiciones.
          <br />
          No debes:
          <br />
          • Volver a publicar material de Mentaly
          <br />
          • Vender, alquilar o sublicenciar material de Mentaly
          <br />
          • Reproducir, duplicar o copiar material de Mentaly
          <br />
          • Redistribuir contenido de Mentaly
          <br />
          Este Acuerdo comenzará en la fecha del mismo. Nuestros Términos y
          Condiciones fueron creados con la ayuda de la Plantilla de Términos y
          Condiciones . Partes de este sitio web ofrecen una oportunidad para
          que los usuarios publiquen e intercambien opiniones e información en
          ciertas áreas del sitio web. Mentaly no filtra, edita, publica ni
          revisa los Comentarios antes de su presencia en el sitio web. Los
          comentarios no reflejan los puntos de vista y opiniones de Mentaly,
          sus agentes y/o afiliados. Los comentarios reflejan los puntos de
          vista y las opiniones de la persona que publica sus puntos de vista y
          opiniones. En la medida permitida por las leyes aplicables, Mentaly no
          será responsable de los Comentarios ni de ninguna obligación, daño o
          gasto causado y/o sufrido como resultado de cualquier uso y/o
          publicación y/o aparición de los Comentarios en este sitio web.
          Mentaly se reserva el derecho de monitorear todos los Comentarios y
          eliminar cualquier Comentario que pueda considerarse inapropiado,
          ofensivo o que cause el incumplimiento de estos Términos y
          Condiciones.
          <br />
          Usted garantiza y declara que:
          <br />
          • Tiene derecho a publicar los Comentarios en nuestro sitio web y
          tiene todas las licencias y consentimientos necesarios para hacerlo;
          <br />
          • Los Comentarios no invaden ningún derecho de propiedad intelectual,
          incluidos, entre otros, derechos de autor, patentes o marcas
          comerciales de terceros;
          <br />
          • Los Comentarios no contienen ningún material difamatorio,
          calumnioso, ofensivo, indecente o ilegal que sea una invasión de la
          privacidad.
          <br />
          • Los Comentarios no se utilizarán para solicitar o promover negocios
          o costumbres o presentar actividades comerciales o actividades
          ilegales.
          <br />
          Por la presente, otorga a Mentaly una licencia no exclusiva para usar,
          reproducir, editar y autorizar a otros a usar, reproducir y editar
          cualquiera de sus Comentarios en cualquiera y todas las formas,
          formatos o medios.
        </div>

        <h4>Hipervínculos a nuestro contenido</h4>
        <div>
          Las siguientes organizaciones pueden vincular a nuestro sitio web sin
          aprobación previa por escrito:
          <br />
          • Agencias gubernamentales;
          <br />
          • Los motores de búsqueda;
          <br />
          • Organizaciones de noticias;
          <br />
          • Los distribuidores de directorios en línea pueden vincularse a
          nuestro sitio web de la misma manera que vinculan a los sitios web de
          otras empresas enumeradas; y
          <br />
          • Empresas acreditadas en todo el sistema, excepto las que solicitan
          organizaciones sin fines de lucro, centros comerciales de caridad y
          grupos de recaudación de fondos de caridad que no pueden tener
          hipervínculos a nuestro sitio web.
          <br />
          Estas organizaciones pueden vincular a nuestra página de inicio,
          publicaciones u otra información del sitio web siempre que el enlace:
          (a) no sea engañoso de ninguna manera; (b) no implica falsamente
          patrocinio, respaldo o aprobación de la parte vinculada y sus
          productos y/o servicios; y (c) encaja dentro del contexto del sitio de
          la parte vinculada.
          <br />
          Podemos considerar y aprobar otras solicitudes de enlace de los
          siguientes tipos de organizaciones:
          <br />
          • fuentes de información comercial y/o de consumo comúnmente
          conocidas;
          <br />
          • sitios de la comunidad dot.com;
          <br />
          • asociaciones u otros grupos que representen organizaciones
          benéficas;
          <br />
          • distribuidores de directorios en línea;
          <br />
          • portales de internet;
          <br />
          • firmas de contabilidad, leyes y consultoría; y
          <br />
          • instituciones educativas y asociaciones comerciales.
          <br />
          Aprobaremos las solicitudes de enlace de estas organizaciones si
          decidimos que: (a) el enlace no nos haría mirar desfavorablemente a
          nosotros mismos oa nuestros negocios acreditados; (b) la organización
          no tiene registros negativos con nosotros; (c) el beneficio para
          nosotros de la visibilidad del hipervínculo compensa la ausencia de
          Mentaly; y (d) el enlace está en el contexto de información general de
          recursos.
          <br />
          Estas organizaciones pueden vincular a nuestra página de inicio
          siempre que el enlace: (a) no sea engañoso de ninguna manera; (b) no
          implica falsamente patrocinio, respaldo o aprobación de la parte
          vinculada y sus productos o servicios; y (c) encaja dentro del
          contexto del sitio de la parte vinculada.
          <br />
          Si usted es una de las organizaciones enumeradas en el párrafo 2
          anterior y está interesado en vincularse a nuestro sitio web, debe
          informarnos enviando un correo electrónico a Mentaly. Incluya su
          nombre, el nombre de su organización, la información de contacto, así
          como la URL de su sitio, una lista de las URL desde las que pretende
          vincular a nuestro sitio web y una lista de las URL de nuestro sitio a
          las que le gustaría Enlace. Espere 2-3 semanas para una respuesta.
          <br />
          Las organizaciones aprobadas pueden tener hipervínculos a nuestro
          sitio web de la siguiente manera:
          <br />
          • Mediante el uso de nuestro nombre corporativo; o
          <br />
          • Mediante el uso del localizador uniforme de recursos al que se
          vincula; o
          <br />
          • Mediante el uso de cualquier otra descripción de nuestro sitio web
          vinculado que tenga sentido dentro del contexto y el formato del
          contenido en el sitio de la parte vinculada.
          <br />
          No se permitirá el uso del logotipo de Mentaly u otra obra de arte
          para vincular en ausencia de un acuerdo de licencia de marca
          registrada.
        </div>

        <h4>iFrames</h4>
        <div>
          Sin aprobación previa y permiso por escrito, no puede crear marcos
          alrededor de nuestras páginas web que alteren de ninguna manera la
          presentación visual o la apariencia de nuestro sitio web.
        </div>

        <h4>Responsabilidad por el contenido</h4>
        <div>
          No seremos responsables de ningún contenido que aparezca en su sitio
          web. Usted acepta protegernos y defendernos contra todos los reclamos
          que surjan en su sitio web. Ningún enlace debe aparecer en ningún
          sitio web que pueda interpretarse como calumnioso, obsceno o criminal,
          o que infrinja, de otro modo viole o promueva la infracción u otra
          violación de los derechos de terceros.
        </div>

        <h4>Tu privacidad</h4>
        <div>Por favor, lea la Política de Privacidad</div>

        <h4>Reserva de Derechos</h4>
        <div>
          Nos reservamos el derecho de solicitarle que elimine todos los enlaces
          o cualquier enlace particular a nuestro sitio web. Usted aprueba
          eliminar inmediatamente todos los enlaces a nuestro sitio web a
          pedido. También nos reservamos el derecho de modificar estos términos
          y condiciones y su política de vinculación en cualquier momento. Al
          vincularse continuamente a nuestro sitio web, usted acepta estar
          sujeto y seguir estos términos y condiciones de vinculación.
        </div>

        <h4>Eliminación de enlaces de nuestro sitio web</h4>
        <div>
          Si encuentra algún enlace en nuestro sitio web que sea ofensivo por
          cualquier motivo, puede contactarnos e informarnos en cualquier
          momento. Consideraremos solicitudes para eliminar enlaces, pero no
          estamos obligados a hacerlo ni a responderle directamente.
          <br />
          No aseguramos que la información en este sitio web sea correcta, no
          garantizamos su integridad o exactitud; ni prometemos garantizar que
          el sitio web permanezca disponible o que el material del sitio web se
          mantenga actualizado.
        </div>

        <h4>Descargo de responsabilidad</h4>
        <div>
          En la máxima medida permitida por la ley aplicable, excluimos todas
          las representaciones, garantías y condiciones relacionadas con nuestro
          sitio web y el uso de este sitio web. Nada en este descargo de
          responsabilidad:
          <br />
          • limitar o excluir nuestra o su responsabilidad por muerte o lesiones
          personales;
          <br />
          • limitar o excluir nuestra o su responsabilidad por fraude o
          tergiversación fraudulenta;
          <br />
          • limitar cualquiera de nuestras responsabilidades o las suyas de
          cualquier manera que no esté permitida por la ley aplicable; o
          <br />
          • excluir cualquiera de nuestras o sus responsabilidades que no puedan
          ser excluidas bajo la ley aplicable.
          <br />
          Las limitaciones y prohibiciones de responsabilidad establecidas en
          esta Sección y en otras partes de este descargo de responsabilidad:
          (a) están sujetas al párrafo anterior; y (b) rigen todas las
          responsabilidades que surjan en virtud de la exención de
          responsabilidad, incluidas las responsabilidades que surjan por
          contrato, agravio y por incumplimiento del deber legal.
          <br />
          Siempre que el sitio web y la información y los servicios en el sitio
          web se proporcionen de forma gratuita, no seremos responsables de
          ninguna pérdida o daño de ningún tipo.
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Cerrar</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TermsAndConditionsModal;
