import apiHelper from '../../utils/apiHelpers';
import React from 'react';
import { Link } from 'react-router-dom';
import './Subscription.css';
import formatter from '../../utils/formatter';
import MercadoPagoForm from '../mercadoPagoForm/MercadoPagoForm';
import { Tooltip, Whisper } from 'rsuite';
import moment from 'moment';
import Swal from 'sweetalert2';
const config = require('../../config/config');

export default class Subscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: {
        id: null,
        username: null,
        password: null,
        roleId: null,
        pfpFilename: null,
        payerId: null,
        firstName: null,
        lastName: null,
        email: null,
        mpEmail: null,
        role: null,
      },
      subscription: { status: '' },
      subscriptionPlan: {
        auto_recurring: {
          frequency: null,
          frequency_type: null,
          transaction_amount: null,
          currency_id: null,
          billing_day: null,
          billing_day_proportional: null,
          transaction_amount_proportional: null,
        },
      },
      errorMessage: null,
    };
  }

  componentDidMount() {
    apiHelper
      .getCurrentUserSubscription()
      .then(response => {
        this.setState({
          subscription: response.data,
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          errorMessage: error,
        });
      });
    if (this.state.currentUser.id === null) {
      apiHelper
        .getCurrentUser()
        .then(currentUserResponse => {
          this.setState({ currentUser: currentUserResponse.data });
        })
        .catch(e => {
          console.log(e);
        });
    }
    if (
      this.state.subscriptionPlan.auto_recurring.transaction_amount === null
    ) {
      apiHelper
        .getSubscriptionPlan()
        .then(subPlanResponse => {
          this.setState({ subscriptionPlan: subPlanResponse.data });
        })
        .catch(e => {
          console.log(e);
        });
    }
  }

  fireCheckMercadopagoEmail() {
    const styledSwal = Swal.mixin({
      showCancelButton: false,
      showDenyButton: true,
      confirmButtonColor: '#22654e',
      customClass: {
        cancelButton: 'swal-cancel-btn', // Add your custom class for the cancel button
        denyButton: 'swal-cancel-btn',
      },
      allowOutsideClick: true,
      allowEscapeKey: true,
    });

    if (this.state.currentUser.mpEmail) {
      styledSwal
        .fire({
          title: `¿Es tu email de MercadoPago correcto?`,
          html: `El email de <b>MercadoPago</b> configurado es <br/> <b>${this.state.currentUser.mpEmail}</b> <br/> De esa cuenta se hará la suscripción.`,
          icon: 'warning',
          confirmButtonText: 'Continuar',
          denyButtonText: 'Editar',
          cancelButtonText: `Cancelar`,
        })
        .then(async result => {
          if (result.isConfirmed) {
            window.open(this.state.subscriptionPlan.init_point, '_blank');
          } else if (result.isDenied) {
            this.props.history.push('/editProfile');
          }
        })
        .catch(e => {
          console.log(e);
        });
    } else {
      // NO MP EMAIL SET
      styledSwal
        .fire({
          title: `Configure el email de MercadoPago`,
          html: `Ingrese el email de MercadoPago desde donde efectuarás el pago.`,
          icon: 'warning',
          showCancelButton: true,
          showDenyButton: false,
          confirmButtonText: 'Editar perfil',
          cancelButtonText: `Cancelar`,
        })
        .then(async result => {
          if (result.isConfirmed) {
            this.props.history.push('/editProfile');
          }
        })
        .catch(e => {
          console.log(e);
        });
    }
  }

  render() {
    let alert;
    let subscription = null;
    let end_date = null;
    let tooltip = <Tooltip>Suscripción.</Tooltip>;

    if (this.state.alertError) {
      alert = (
        <div className="alert alert-warning ml-5 mr-5 text-center">
          {this.state.errorMessage}
        </div>
      );
    }

    if (
      this.state.subscription.status == 'unsubscribed' ||
      this.state.subscription.status == 'cancelled'
    ) {
      subscription = (
        <>
          <div className="container w-100 h-100 no-conclusions-sign mb-0">
            <div className="row justify-content-center mb-3">
              <div className="col-8 text-center">
                <i className="fas fa-calendar-alt"></i>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-8 text-center no-conclusions-text font-weight-bold mb-2">
                TU PERÍODO DE PRUEBA HA CADUCADO
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-8 text-center no-conclusions-text mb-2">
                Suscribite via <b>MercadoPago</b> por{' '}
                <b>{`${this.state.subscriptionPlan.auto_recurring.transaction_amount} ${this.state.subscriptionPlan.auto_recurring.currency_id} `}</b>
                por mes y desbloquea todas las funcionalidades de nuestra
                plataforma.
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="d-flex justify-content-center w-100">
                <button
                  onClick={this.fireCheckMercadopagoEmail.bind(this)}
                  className="btn btn-lg-4 btn-vpsy-solid btn-block btn-login-font fadeIn fourth mt-2"
                  type="button">
                  Suscribite
                </button>
              </div>
            </div>
          </div>
        </>
      );
    } else if (this.state.subscription.status == 'freeTrial') {
      end_date = moment(this.state.subscription.end_date).format('DD/MM/YYYY');
      tooltip = (
        <Tooltip>
          Límite de <b>{this.state.subscription.maxPatients}</b> pacientes y{' '}
          <b>{this.state.subscription.maxAppointments}</b> turnos. Finaliza el{' '}
          <b>{end_date}</b>.
        </Tooltip>
      );

      subscription = (
        <>
          <div className="container w-100 h-100 no-conclusions-sign mb-0">
            <div className="row justify-content-center mb-3">
              <div className="col-8 text-center">
                <Whisper
                  placement="top"
                  controlId="control-id-hover"
                  trigger="hover"
                  speaker={tooltip}>
                  <i className="fas fa-calendar-alt"></i>
                </Whisper>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-8 text-center no-conclusions-text font-weight-bold mb-2">
                TU PERÍODO DE PRUEBA CADUCA EL{' '}
                {formatter.formatDate(this.state.subscription.end_date)}
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-8 text-center no-conclusions-text mb-2">
                Suscribite via <b>MercadoPago</b> por{' '}
                <b>{`${this.state.subscriptionPlan.auto_recurring.transaction_amount} ${this.state.subscriptionPlan.auto_recurring.currency_id} `}</b>
                por mes y desbloquea todas las funcionalidades de nuestra
                plataforma.
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="d-flex justify-content-center w-100">
                <a
                  className=""
                  target="_blank"
                  rel="noopener noreferrer"
                  href={this.state.subscriptionPlan.init_point}>
                  <button
                    className="btn btn-lg-4 btn-vpsy-solid btn-block btn-login-font fadeIn fourth mt-2"
                    type="button">
                    Suscribite
                  </button>
                </a>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      if (this.state.subscription.status.length) {
        subscription = (
          <>
            <div className="container w-100 h-100 no-conclusions-sign mb-0">
              <div className="row justify-content-center mb-3">
                <div className="col-8 text-center">
                  <i className="fas fa-calendar-alt"></i>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-8 text-center no-conclusions-text font-weight-bold mb-2">
                  TU SUSCRIPCIÓN ESTÁ ACTIVA
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-8 text-center no-conclusions-text mb-2">
                  Suscripción activa por{' '}
                  <b>{`${this.state.subscriptionPlan.auto_recurring.transaction_amount} ${this.state.subscriptionPlan.auto_recurring.currency_id} `}</b>
                  se renueva el día{' '}
                  <b>{`${this.state.subscriptionPlan.auto_recurring.billing_day}`}</b>{' '}
                  de cada mes para <b>{`${this.state.currentUser.mpEmail}`}</b>{' '}
                  via Mercado Pago
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="d-flex justify-content-center w-100">
                  <a
                    className=""
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.mercadopago.com.ar/subscriptions">
                    <button
                      className="btn btn-lg-4 btn-vpsy-solid btn-block btn-login-font fadeIn fourth mt-2"
                      type="button">
                      Administrar suscripción
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </>
        );
      }
    }

    return (
      <div>
        {alert}
        <div className="container subscription-container">{subscription} </div>
      </div>
    );
  }
}
