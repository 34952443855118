import React, { useLayoutEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import apiHelper from '../../utils/apiHelpers';
import { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import './Login.css';

const LOGIN_IMG_PATH = process.env.PUBLIC_URL + '/img/login-side-img.png';
const MIN_DISPLAY_WIDTH = 700;

// to track window size
const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};

const Login = props => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(
    !!history.location.state,
  );
  const [errorMessage, setErrorMessage] = useState('');
  const [currentWidth, currentHeight] = useWindowSize();

  useEffect(() => {
    if (currentWidth < MIN_DISPLAY_WIDTH) {
      setShowErrorMessage(true);
      setErrorMessage('La pantalla es muy pequeaña, ingrese desde la web.');
    } else {
      setShowErrorMessage(false);
      setErrorMessage('');
    }
  }, [currentWidth]);

  useEffect(() => {
    if (!!history.location.state) {
      // setErrorMessage(history.location.state.msg);
      window.history.replaceState({}, document.title); // Clears history location.state
    }
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      await apiHelper.login({
        email,
        password,
      });
      apiHelper
        .getCurrentUserSubscription()
        .then(response => {
          if (
            response.data.status == 'freeTrial' ||
            response.data.status == 'authorized'
          ) {
            history.push('/appointment');
          } else {
            history.push('/profile');
          }
        })
        .then(() => {
          if (!apiHelper.getHideAlphaAlert()) {
            Swal.fire({
              icon: 'warning',
              title: `Estamos en Alpha!`,
              text: `Esta es una versión de prueba abierta. Estamos constantemente mejorando nuestra plataforma. Tu experiencia es importante para nosotros, y agradecemos cualquier comentario puedas tener.`,
              showDenyButton: true,
              confirmButtonColor: '#22654e',
              confirmButtonText: 'Continuar',
              denyButtonColor: '#652222',
              denyButtonText: `No volver a mostrar`,
            }).then(result => {
              if (result.isDenied) {
                apiHelper.setHideAlphaAlert(true);
              }
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
    } catch (e) {
      const statusCode = e.response.status;
      setShowErrorMessage(true);
      if (statusCode === 401) {
        setErrorMessage('Email o contraseña invalidas');
      } else {
        setErrorMessage(e.response.data.error);
      }
    }
  };

  let alert;

  if (showErrorMessage && errorMessage) {
    alert = (
      <div className="login-alert login-alert-text p-3 mb-3">
        {errorMessage}
      </div>
    );
  }

  return (
    <div id="login-container" className="container justify-content-center">
      <div className="row h-100 justify-content-center">
        <div className="col-md-7 d-flex justify-content-md-center align-self-center">
          <form className="" onSubmit={handleSubmit}>
            <div className="fadeIn first">
              <img
                className="logo logo-login"
                src={process.env.PUBLIC_URL + '/img/LOGO VENT-07.png'}
                alt="logo"
              />
              {alert}
              {currentWidth < MIN_DISPLAY_WIDTH ? (
                <></>
              ) : (
                <>
                  <div className="form-group mb-0">
                    <label htmlFor="email" className="h5 label-vpsy-font">
                      <i className="fas fa-user" /> Email
                    </label>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      className="form-control fadeIn second"
                      placeholder="Usuario"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      required
                      autoFocus
                    />
                  </div>

                  <div className="form-group mt-3">
                    <label htmlFor="password" className="h5 label-vpsy-font">
                      <i className="fas fa-lock" /> Contraseña
                    </label>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      className="form-control fadeIn third"
                      placeholder="Contraseña"
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      required
                    />
                  </div>

                  <Link
                    to="/recover_password"
                    className="btn-recover-pass mb-3">
                    Olvidé mi Contraseña
                  </Link>

                  <button
                    className="btn btn-lg-4 btn-block btn-vpsy-gradient btn-login-font fadeIn fourth mt-3"
                    formAction="index.html"
                    type="submit"
                    onClick={e => handleSubmit(e)}>
                    Iniciar sesión
                  </button>

                  <Link to="/signup">
                    <button
                      id="sign-up"
                      className="btn btn-lg-4 btn-vpsy-outline btn-block btn-login-font fadeIn fourth mt-2"
                      formAction="index.html"
                      type="button">
                      Registrate
                    </button>
                  </Link>
                </>
              )}
            </div>
          </form>
        </div>

        <div id="login-side-img" className="col-sm">
          <div className="login-img-overlay"></div>
          <img src={LOGIN_IMG_PATH} />
        </div>
      </div>
    </div>
  );
};

export default Login;
