import React from 'react';
import './Help.css';

const YOUTUBE_THUMBNAIL_URL = 'https://img.youtube.com/vi/{id}/0.jpg';

const HelpCard = props => {
  const { cardData, showVideoModal, className } = props;

  return (
    <div key={props.key} className={'container ' + className}>
      <div className="row">
        <div className="col-lg-3">
          <img
            className="yt-thumbnail"
            width="168"
            height="94"
            src={YOUTUBE_THUMBNAIL_URL.replace('{id}', cardData.ytID)}
            onClick={() => {
              showVideoModal(cardData.ytID);
            }}
          />
        </div>
        <div className="col-lg-8 ml-3">
          <div className="h3 row helpcard-title"> {cardData.title} </div>
          <div className="row helpcard-description">
            {' '}
            {cardData.description}{' '}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpCard;
