import React from 'react';
import apiHelper from '../../../utils/apiHelpers';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';

export default class SelectPatient extends React.Component {
  constructor(props) {
    super(props);

    this.wto = null;

    this.isFetching = false;

    this.state = {
      options: [],
    };
    this.getOptions = (input, callback) => {
      clearTimeout(this.wto);
      this.wto = setTimeout(() => {
        let filters = {};

        if (!!input || input.length !== 0) {
          filters['where'] = {
            firstName: input,
          };
        }

        apiHelper
          .getPatients(filters)
          .then(users => {
            let usersForSelect = users.data.map(user => {
              return {
                value: user.id,
                label: user.firstName + ' ' + user.lastName,
                firstName: user.firstName,
                lastName: user.lastName,
                birthDate: user.birthDate,
              };
            });
            this.setState({
              options: usersForSelect,
            });
            callback(usersForSelect);
          })
          .catch(function (err) {
            callback(err, null);
          });
      }, 600);
    };
  }

  onChangeWrapper(selectedValue) {
    let event = { target: {} };
    event.target.name = this.props.name
      ? this.props.name
      : 'select-client-nameless';
    event.target.value = {
      id: selectedValue.value,
      birthDate: selectedValue.birthDate,
      firstName: selectedValue.firstName,
      lastName: selectedValue.lastName,
    };
    event.target.type = 'react-select';
    this.props.onChange(event);
  }

  getDefaultOptions(patient) {
    if (!patient) return;

    let filters = {
      where: {
        id: patient.id,
      },
    };
    this.isFetching = true;

    apiHelper
      .getPatients(filters)
      .then(users => {
        let usersForSelect = users.data.map(user => {
          return {
            value: user.id,
            label: user.firstName + ' ' + user.lastName,
            birthDate: user.birthDate,
          };
        });

        this.setState({
          options: usersForSelect,
        });
      })
      .then(() => {
        this.isFetching = false;
      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    let { value } = this.props;
    let userValue;

    if (value) {
      userValue = this.state.options.find(option => option.value === value.id);
    }

    if (value && !value.id) {
      if (!userValue && !this.isFetching) {
        this.getDefaultOptions(value);
      }
    }

    return (
      <AsyncSelect
        className={this.props.className}
        name={this.props.name}
        onChange={this.onChangeWrapper.bind(this)}
        value={userValue || ''} //Workaround to clear value with null. See https://github.com/JedWatson/react-select/issues/3066
        required={this.props.required}
        loadOptions={this.getOptions}
        placeholder={this.props.placeholder}
        menuContainerStyle={{ zIndex: 999 }}
        noOptionsMessage={() => 'Ingrese un valor para buscar'}
        isClearable={this.props.required}
        defaultOptions={true}
        styles={this.props.styles}
      />
    );
  }
}
SelectPatient.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.number,
  isClearable: PropTypes.bool,
};
