import React, { useEffect, useState, useRef } from 'react';
import Tiptap from './Tiptap';

const TestComp = () => {
  return (
    <div className="m-5 col-6 border border-danger">
      <Tiptap
        htmlContent={`<table>
						<tbody>
							<tr>
								<th>headtest1</th>
								<th colspan="3">head Colspan</th>
							</tr>
							<tr>
								<td>aa</td>
								<td>bbb</td>
								<td>ccccc </td>
								<td>dddddd</td>
							</tr>
						</tbody>
					</table>`}
        onChange={newHTML => {
          console.log(newHTML);
        }}></Tiptap>
    </div>
  );
};

export default TestComp;
