import React, { useContext, useRef } from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import apiHelpers from '../../../utils/apiHelpers';
import './ExamSection.css';
import SelectPatient from '../../common/selects/SelectPatient';
import { ExamContext } from '../ExamContext';
import { EXAM_SECTION } from '../ExamConstants';
import CreatableSelect from 'react-select/creatable';
import './Conclusions.css';
import {
  SECTION_SEPARATOR,
  TITLE_SEPARATOR,
  TITLE_INDEX,
  BODY_INDEX,
  SECTION_OPTIONS,
} from '../ExamConstants';
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const AttachmentModal = ({
  onFileChange,
  onFileUpload,
  show,
  onHide,
  animation,
  scrollable,
  fileRef,
  selectedFile,
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      animation={animation}
      scrollable={scrollable}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="conclusion-modal-title">
          Agregar adjunto
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container my-3">
          <div className="row">
            <div className="col-md-12">
              <button
                onClick={() => {
                  fileRef.current.click();
                }}
                className="btn-vpsy-outline btn-block exam-btn-txt my-3 mx-1 bg-white">
                {selectedFile ? selectedFile.name : 'Seleccionar archivo'}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-vpsy-outline" onClick={onHide}>
          Cancelar
        </Button>
        <Button
          onClick={() => {
            onFileUpload();
          }}
          className="btn-vpsy-solid">
          Aceptar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const ExamAttachments = ({ viewMode }) => {
  const {
    exam,
    setExam,
    patient,
    setPatient,
    currentSection,
    setCurrentSection,
  } = useContext(ExamContext);

  const [showAttachmentModal, setShowAttachmentModal] = useState(false);

  const saveTest = async e => {
    e.preventDefault();
    try {
      //If requests already exists, update it. If not, create it
      let response;
      if (!exam.id) {
        response = await apiHelpers.postExam(exam);
      } else {
        response = await apiHelpers.patchExam(exam.id, exam);
      }

      setExam({ ...exam, ...response.data });
      setCurrentSection(EXAM_SECTION.FINISH);
    } catch (e) {
      console.error(e);
    }
  };

  const [attachments, setAttachments] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [fileSrc, setFileSrc] = useState(null);

  const refreshAttachmentList = examId => {
    apiHelpers.getExamAttachments(examId).then(resp => {
      setAttachments(resp.data);
    });
  };

  useEffect(() => {
    refreshAttachmentList(exam.id);
  }, [exam.id]);

  // On file select (from the pop up)
  const onFileChange = event => {
    console.log(event.target.files[0]);
    // Update the state
    setSelectedFile(event.target.files[0]);
  };

  const onChangeFile2 = event => {
    event.preventDefault();

    let reader = new FileReader();
    setIsUploading(true);
    window.setTimeout(
      function () {
        setFileSrc(reader.result);
        setIsUploading(false);
      }.bind(this),
      1000,
    );

    setSelectedFile(event.target.files[0]);
  };

  const fileRef = useRef(null);

  // On file upload (click the upload button)
  const onFileUpload = () => {
    setIsUploading(true);
    // Create an object of formData
    const formData = new FormData();

    const FILE_SIZE_LIMIT = 10_000_000;
    if (selectedFile.size > FILE_SIZE_LIMIT) {
      Swal.fire({
        title: 'El archivo es muy grande.',
        text: 'No se puede subir archivos mayores a 10mB',
        icon: 'warning',
        showCancelButton: false,
        customClass: {
          confirmButton: 'swal-cancel-btn', // Add your custom class for the cancel button
        },
      });
      return;
    }

    // Update the formData object
    formData.append('img', selectedFile, selectedFile.name);

    // Request made to the backend api
    // Send formData object
    apiHelpers
      .uploadExamAttachment(formData, exam.id)
      .then(response => {
        if (response.status === 200) {
          setSelectedFile(null);
          refreshAttachmentList(exam.id);
        }
      })
      .finally(() => {
        setIsUploading(false);
        setShowAttachmentModal(false);
        setSelectedFile(null);
      });
  };

  const onDownloadClick = async index => {
    let { uuidName, fileName } = attachments[index];
    let svResponse = await apiHelpers.downloadFile(uuidName);

    const url = window.URL.createObjectURL(new Blob([svResponse.data]));
    const link = document.createElement('a');
    link.href = url;
    if (typeof window.navigator.msSaveBlob === 'function') {
      window.navigator.msSaveBlob(svResponse.data, fileName);
    } else {
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    }
  };

  const onDeleteClick = async index => {
    let { uuidName, fileName } = attachments[index];
    Swal.fire({
      title: `¿Desea eliminar "${fileName}"?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#22654e',
      customClass: {
        cancelButton: 'swal-cancel-btn', // Add your custom class for the cancel button
      },
      confirmButtonText: 'Si, eliminar.',
    }).then(result => {
      if (result.isConfirmed) {
        apiHelpers.deleteFile(uuidName).then(resp => {
          refreshAttachmentList(exam.id);
        });
        Swal.fire({
          title: `Adjunto "${fileName}" eliminado.`,
          icon: 'success',
          confirmButtonColor: '#22654e',
          confirmButtonText: 'Continuar',
        });
      }
    });
  };

  if (!attachments.length && viewMode) return <></>;

  return (
    <>
      <input
        type="file"
        id="file"
        ref={fileRef}
        onChange={onChangeFile2}
        hidden
      />
      <div className="d-flex flex-column h-100">
        <div className="exam-section-title my-2">
          {viewMode ? (
            <></>
          ) : (
            <>
              <div className="row">
                <div className="col-6 exam-section-title my-2">
                  Adjuntos de evaluación
                </div>
                <div className="col-6 px-0">
                  <div className="d-flex justify-content-end w-100">
                    <button
                      className="col-md-3 btn-vpsy-outline exam-btn-txt my-1 mx-1"
                      onClick={() => {
                        setCurrentSection(EXAM_SECTION.CONCLUSIONS);
                      }}>
                      Atrás
                    </button>

                    <button
                      className="col-md-3 btn-vpsy-gradient exam-btn-txt my-1 mx-1"
                      onClick={e => {
                        saveTest(e);
                      }}>
                      Continuar
                    </button>
                  </div>
                </div>
              </div>

              <div className="w-100 d-flex">
                <div className="exam-line-green" />
                <div className="exam-line-divider" />
              </div>
            </>
          )}
        </div>

        <div className={attachments.length > 0 ? 'mt-3' : 'mt-auto py-3'}>
          <div className="container">
            {attachments.length > 0 || viewMode ? (
              attachments.map((attachment, index) => {
                return (
                  <div key={index} className="row">
                    <div className="col-10 mt-2 w-100 conclusion-list-item  justify-content-center">
                      <p className="px-3 py-2">
                        <b>{`${attachment.fileName} `}</b>
                      </p>
                    </div>

                    <div className="col-2">
                      <button
                        type="button"
                        className="btn pl-2 pt-2"
                        title="Descargar"
                        onClick={() => {
                          onDownloadClick(index);
                        }}>
                        <span className="fas fa-download icon-hover"></span>
                      </button>
                      {!viewMode && (
                        <button
                          type="button"
                          className="btn icon-red pl-2 pt-2"
                          onClick={() => {
                            onDeleteClick(index);
                          }}
                          title="Eliminar adjunto">
                          <span className="fa fa-trash-alt icon-hover"></span>
                        </button>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <>
                <div className="container w-100 h-100 no-conclusions-sign">
                  <div class="row justify-content-center mb-3">
                    <div class="col-8 text-center">
                      <i className="fas fa-paperclip"></i>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-8 text-center no-conclusions-text font-weight-bold mb-2">
                      NO HAY ADJUNTOS CARGADOS
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-8 text-center no-conclusions-text mb-2">
                      ¿Desea continuar sin agregar adjuntos?
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div className="d-flex justify-content-center w-100">
                      <button
                        className="col-md-3 btn-vpsy-outline exam-btn-txt my-3 mx-1 bg-white"
                        onClick={() => {
                          setCurrentSection(EXAM_SECTION.FINISH);
                        }}>
                        Continuar sin agregar
                      </button>

                      <button
                        className="col-md-3 btn-vpsy-gradient exam-btn-txt my-3 mx-1"
                        onClick={() => {
                          setShowAttachmentModal(true);
                        }}>
                        Agregar adjuntos
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          <AttachmentModal
            show={showAttachmentModal}
            onHide={() => {
              setShowAttachmentModal(false);
              setSelectedFile(null);
            }}
            animation={false}
            onFileChange={onFileChange}
            onFileUpload={onFileUpload}
            fileRef={fileRef}
            selectedFile={selectedFile}
          />
        </div>
      </div>
    </>
  );
};

export default ExamAttachments;
