import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Landing from './../Landing';
import apiHelpers from '../../../utils/apiHelpers';

const containerVariants = {
  hidden: {
    opacity: 0,
    y: '50vh',
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      stiffness: 10,
    },
  },
};

const fondo = 'img/fondo.png';

var background = {
  //backgroundImage: `url(${fondo})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const Advantages = () => {
  const [testsArray, setTestsArray] = useState([]);

  useEffect(() => {
    if (testsArray.length === 0) {
      try {
        apiHelpers.getParentTests().then(res => {
          let orderedArray = res.data.sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
          });
          setTestsArray(orderedArray);
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, []);

  return (
    <>
      <div style={background} className="mt-5">
        <div>
          <h1 className="text-center">Funcionalidad del Sistema</h1>

          <div className="info-adv">
            {/* First Row */}
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="card w-60 text-dark bg-light mb-3 cardStyles">
                    <div className="text-center icons mt-3">
                      <i className="fas fa-user-edit"></i>{' '}
                    </div>
                    <div className="card-body subs">
                      <h5 className="card-title text-center">
                        Agenda de Pacientes
                      </h5>
                      <p className="card-text">
                        Registrá los datos de tus pacientes por única vez, y
                        siempre tendrás su información de contacto disponible.
                        Podrá llevar un registro individual de la historia
                        clínica de cada uno, disponible desde cualquier
                        computadora y teléfono.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="card w-60 text-dark bg-light mb-3 cardStyles">
                    <div className="text-center icons mt-3">
                      <i className="fas fa-notes-medical"></i>
                    </div>
                    <div className="card-body subs">
                      <h5 className="card-title text-center">
                        Seccion de Exámenes
                      </h5>
                      <p className="card-text">
                        Registre los puntajes obtenidos por cada paciente en los
                        exámenes realizados y deje que nuestro sistema calcule
                        el percentil correspondiente de forma inmediata y
                        automática.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Second Row */}
            <div className="container pt-4">
              <div className="row">
                <div className="col">
                  <div className="card w-60 text-dark bg-light mb-3 cardStyles">
                    <div className="text-center icons mt-3">
                      <i className="fas fa-calendar-alt"></i>{' '}
                    </div>
                    <div className="card-body subs">
                      <h5 className="card-title text-center">
                        Calendario de Turnos
                      </h5>
                      <p className="card-text">
                        Coordine sus consultas a través del sistema, utilizando
                        un calendario interactivo. Cada cita agendada enviará un
                        correo al paciente con el link a la videoconsulta, de
                        forma completamente automática.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="card w-60 text-dark bg-light mb-3 cardStyles">
                    <div className="text-center icons mt-3">
                      <i className="fas fa-save"></i>
                    </div>
                    <div className="card-body subs">
                      <h5 className="card-title text-center">
                        Seguridad de Datos
                      </h5>
                      <p className="card-text">
                        Contamos con un sistema de backups automáticos para
                        prevenir la pérdida de información y que puedas acceder
                        a ellos desde cualquier lugar y momento.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* THIRD Row */}
              <div className="container pt-4 w-50">
                <div className="row">
                  <div className="col">
                    <div className="card text-dark bg-light mb-3 cardStyles">
                      <div className="text-center icons mt-3">
                        <i className="fab fa-readme"></i>{' '}
                      </div>
                      <div className="card-body subs">
                        <h5 className="card-title text-center">
                          Tests disponibles en nuestra plataforma
                        </h5>
                      </div>
                      {/* PARENT TESTS COLUMNS */}
                      <div className="container mb-3">
                        <div className="row align-items-start">
                          <div className="col">
                            <ul className="list-group list-group-flush  text-center">
                              {
                                // Splitting the testsArray to map 2 columns
                                testsArray
                                  .slice(
                                    0,
                                    Math.ceil((testsArray.length - 1) / 2),
                                  )
                                  .map((parentTest, i) => {
                                    return (
                                      <li key={i} className="list-group-item">
                                        {parentTest.name}
                                      </li>
                                    );
                                  })
                              }
                            </ul>
                          </div>
                          <div className="col">
                            <ul className="list-group list-group-flush  text-center">
                              {testsArray
                                .slice(
                                  Math.ceil((testsArray.length - 1) / 2),
                                  testsArray.length,
                                )
                                .map((parentTest, i) => {
                                  return (
                                    <li key={i} className="list-group-item">
                                      {parentTest.name}
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Advantages;
