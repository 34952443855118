import { color } from '@amcharts/amcharts4/core';

// React PDF style object.
export const react_pdf_styles = {
  // Page styles
  page: {
    backgroundColor: '#FFFFFF',
    border: 0.6,
    borderColor: '#FFFFFF',
    padding: 3,
    paddingBottom: 30,
  },

  //  ++++ Header ++++
  header: {
    display: 'flex',
    flexDirection: 'row',
    width: '95%',
    marginTop: 0.5,
    marginBottom: 3,
    justifyContent: 'space-between',
    alignSelf: 'center',
  },
  headerLogo: {
    width: '40%',
    height: 'auto',
    marginTop: 3,
    padding: 1,
    alignSelf: 'center',
    // alignContent: 'center',
  },
  headerBox: {
    maxWidth: '50%',
    padding: 6,
    fontSize: 10,
    alignSelf: 'center',
    alignContent: 'center',
  },
  headerBoxTop: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 0.5,
  },
  headerText: {
    fontFamily: 'Poppins',
    color: '#5b5d5d',
    fontSize: 10,
    fontStyle: 'bold',
    fontWeight: 600,
  },
  headerBoxBottom: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  //  ++++ General styles ++++
  sectionTopBar: {
    width: '95%',
    marginTop: 10,
    marginBottom: 0,
    marginHorizontal: 0,
    height: 10,
    backgroundColor: '#3c7674',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    alignSelf: 'center',
  },
  section: {
    width: '95%',
    marginHorizontal: 10,
    marginTop: 0,
    marginBottom: 10,
    padding: 10,
    borderBottomRightRadius: 16,
    borderBottomLeftRadius: 16,
    border: 0.5,
    borderTop: 0,
    borderColor: '#a1a1a1',
    alignSelf: 'center',
  },
  sectionTitle: {
    fontFamily: 'Poppins',
    fontSize: 14,
    paddingVertical: 2,
    marginHorizontal: 5,
    marginVertical: 2,
    fontStyle: 'bold',
    fontWeight: 600,
    color: '#5b5d5d',
  },
  flexColumn: {
    // flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  flexRow: {
    // flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  borderBottom: {
    borderBottom: 0.5,
    borderColor: '#a1a1a1',
  },

  //  ++++ patient ++++
  patientFlexRow: {
    width: '100%',
    marginTop: 3,
  },
  patientSection: {
    border: 0.5,
    borderTop: 0,
    borderColor: '#a1a1a1',
    borderBottomRightRadius: 16,
    borderBottomLeftRadius: 16,
    marginHorizontal: 10,
    marginTop: 0,
    marginBottom: 10,
    width: '95%',
    maxWidth: '95%',
    padding: 10,
    alignSelf: 'center',
  },
  patientFirstColumn: {
    flex: 1,
    maxWidth: '40%',
  },
  patientSecondColumn: {
    flex: 0,
    minWidth: '60%',
  },
  patientName: {
    margin: 3,
    marginTop: 1,
    fontSize: 20,
    fontFamily: 'Poppins',
    fontStyle: 'bold',
    fontWeight: 600,
    color: '#22654e',
  },
  patientDataTitle: {
    margin: 1,
    fontFamily: 'Poppins',
    fontSize: 8,
    fontWeight: 600,
    textAlign: 'left',
    color: '#919191',
  },
  patientData: {
    marginVertical: 0,
    marginLeft: 2,
    fontSize: 10,
    fontFamily: 'Poppins',
    textAlign: 'left',
    color: '#5b5d5d',
    textOverflow: '-',
  },

  //  ++++ Result Section ++++
  examTopSection: {
    justifyContent: 'space-between',
    borderBottom: 0.5,
    borderColor: '#a1a1a1',
    marginTop: 2,
  },
  examTitle: {
    fontFamily: 'Poppins',
    fontSize: 14,
    paddingVertical: 2,
    marginHorizontal: 5,
    marginVertical: 2,
    fontStyle: 'bold',
    fontWeight: 600,
    color: '#5b5d5d',
  },
  examTitleDate: {
    fontFamily: 'Poppins',
    fontSize: 10,
    paddingVertical: 2,
    marginHorizontal: 5,
    marginVertical: 2,
    color: '#5b5d5d',
  },
  resultRow: {
    flexDirection: 'row',
    marginVertical: 2,
    alignItems: 'center',
  },
  nameColumn: {
    // flex: 1,
    marginLeft: 5,
    flexGrow: 3,
    flexShrink: 1,
    flexBasis: '25em',
  },
  percentileColumn: {
    // flex: 1,
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: '25em',
    display: 'flex',
    alignSelf: 'center',
  },
  scoreColumn: {
    // flex: 1,
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: '5em',
    display: 'flex',
    alignSelf: 'center',
  },
  referenceColumn: {
    // flex: 1,
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: '25em',
    display: 'flex',
    alignSelf: 'center',
  },
  tableFirstRowText: {
    fontFamily: 'Poppins',
    fontStyle: 'bold',
    fontWeight: 600,
    fontSize: 10,
    lineHeight: 1.56,
    color: '#5b5d5d',
  },
  tableText: {
    fontSize: 10,
    fontFamily: 'Poppins',
    color: '#5b5d5d',
  },
  textBold: {
    fontStyle: 'bold',
    fontWeight: 600,
  },
  textLeft: {
    textAlign: 'left',
  },
  textRight: {
    textAlign: 'right',
  },
  textCenter: {
    textAlign: 'center',
  },

  // ++++ Z score row +++++
  zScoreHeader: {
    flexGrow: 1,
    flexShrink: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    display: 'flex',
    alignSelf: 'center',
    flexBasis: '20%',
    border: 0.5,
    borderColor: '#555',
    height: 25,
    alignItems: 'stretch',
  },
  zScoreHeaderRow: {
    flexGrow: 1,
    flexShrink: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    display: 'flex',
    alignSelf: 'center',
    width: '100%',
    alignItems: 'stretch',
  },
  zScoreHeaderCell: {
    display: 'flex',
    width: '100%',
    flexWrap: 'nowrap',
    borderRightColor: '#aaa',
    borderRight: 0.4,
    fontSize: 5,
    justifyContent: 'center',
    alignSelf: 'center',
    textAlign: 'center',
    fontFamily: 'Poppins',
    color: '#333',
  },
  zScoreRow: {
    flexGrow: 1,
    flexShrink: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    display: 'flex',
    alignSelf: 'center',
    flexBasis: '20%',
    border: 0.5,
    borderColor: '#555',
    minHeight: '1em',
    alignItems: 'stretch',
  },
  zScoreCell: {
    width: '100%',
    flexWrap: 'nowrap',
    borderRightColor: '#bbb',
    borderRight: 0.4,
    fontSize: 8,
    justifyContent: 'center',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontStyle: 'bold',
    fontWeight: 600,
  },
  zScoreCellGray: {
    color: '#606060',
  },
  zScoreCellGreen: {
    color: '#1fac26',
  },
  zScoreCellYellow: {
    color: '#c7cb20',
  },
  zScoreCellRed: {
    color: '#aa1f1f',
  },
  zScoreZeroCell: {
    backgroundColor: '#aaa',
  },
  zScore1SigmaCell: {
    backgroundColor: '#ccc',
  },

  //  ++++ Conclusions ++++
  conclusionTopSection: {
    justifyContent: 'space-between',
    borderBottom: 0.5,
    borderColor: '#a1a1a1',
  },
  conclusion: {
    marginTop: 0,
    padding: 5,
  },
  conclusionTitle: {
    margin: 1,
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: 600,
    fontStyle: 'bold',
    textAlign: 'left',
    color: '#3b3d3d',
  },
  conclusionBody: {
    marginVertical: 0,
    marginLeft: 2,
    fontSize: 12,
    fontFamily: 'Poppins',
    textAlign: 'left',
    color: '#5b5d5d',
    textOverflow: '-',
  },
  //  ++++ Footer ++++
  footer: {
    justifyContent: 'space-around',
    alignItems: 'center',
    position: 'absolute',
    bottom: 15,
    left: 0,
    right: 0,
  },
  footerLeft: {
    fontSize: 8,
    fontFamily: 'Poppins',
    color: '#5b5d5d',
  },
  footerLink: {
    fontSize: 8,
    fontFamily: 'Poppins',
    color: '#5b5d5d',
  },
  pageNumber: {
    fontSize: 8,
    color: '#5b5d5d',
  },
};
