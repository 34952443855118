import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Loader from './Loader';
import './SpinnerModal.css';

function SpinnerModal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.titlestring}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Loader></Loader>
      </Modal.Body>
    </Modal>
  );
}

export default SpinnerModal;
