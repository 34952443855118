import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

export default class Tooltip extends React.Component {
  render() {
    let tooltip = (
      <ReactTooltip id={this.props.id} place="left" type="info" effect="float">
        <span className="fa fa-info-circle"></span>
      </ReactTooltip>
    );

    if (this.props.tooltipText) {
      tooltip = (
        <ReactTooltip
          id={this.props.id}
          place="left"
          type="info"
          effect="float">
          {this.props.tooltipText}
        </ReactTooltip>
      );
    }

    return tooltip;
  }
}

Tooltip.propTypes = {
  id: PropTypes.string,
  tooltipText: PropTypes.string,
};
