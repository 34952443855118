export const SECTION_SEPARATOR = '%%section%%';
export const TITLE_SEPARATOR = '%%title%%';
export const TITLE_INDEX = 0;
export const BODY_INDEX = 1;

// Sections autocomplete options
export const SECTION_OPTIONS = [
  { value: 'Edad', label: 'Edad' },
  { value: 'Escolaridad', label: 'Escolaridad' },
  { value: 'Motivo de consulta', label: 'Motivo de consulta' },
  { value: 'Diagnóstico', label: 'Diagnóstico' },
  { value: 'Aspectos generales', label: 'Aspectos generales' },
  { value: 'Estructura cognitiva', label: 'Estructura cognitiva' },
  {
    value: 'Desarrollo de madurez neuropsicológica',
    label: 'Desarrollo de madurez neuropsicológica',
  },
  { value: 'Memoria', label: 'Memoria' },
  { value: 'Atención', label: 'Atención' },
  { value: 'Funciones ejecutivas', label: 'Funciones ejecutivas' },
  { value: 'Visoespacialidad', label: 'Visoespacialidad' },
  { value: 'Habilidades académicas', label: 'Habilidades académicas' },
  { value: 'Conducta adaptativa', label: 'Conducta adaptativa' },
  { value: 'Síntesis y orientación', label: 'Síntesis y orientación' },
];

export const EXAM_SECTION = {
  PATIENT: 0,
  TESTS: 1,
  CONCLUSIONS: 2,
  ATTACHMENTS: 3,
  FINISH: 4,
};

// Tests specific order
export const TEST_ORDER_EXCEPTIONS = [
  {
    parentTest: 'WISC_V',
    categories: [
      {
        name: 'RENDIMIENTO INTELECTUAL GENERAL',
        studyTypeOrder: [
          'Comprensión Verbal',
          'Visoespacial',
          'Razonamiento fluido',
          'Velocidad de procesamiento',
          'Memoria de trabajo',
          'Escala Total',
        ],
      },
    ],
  },
];
