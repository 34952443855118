import React from 'react';
import { useEffect, useState, useRef, useLayoutEffect } from 'react';
import apiHelper from '../../utils/apiHelpers';
import { Form, Row, Col, Button, FormGroup } from 'react-bootstrap';
import SelectParentTest from '../common/selects/SelectParentTest';
import SelectStudyTypes from './../common/selects/SelectStudyTypes';
import formatter from '../../utils/formatter';

const NewExam = props => {
  let {
    isNewCategory,
    parentTestsRaw,
    studyTypesRaw,
    result,
    index,
    viewMode,
  } = props;
  const [parentTests, setParentTests] = useState([]);
  const [studyTypes, setStudyTypes] = useState([]);
  const [resultValue, setResultValue] = useState(result.result);
  const [timeoutId, setTimeoutId] = useState(null);

  // this is for first render
  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      let temparr = testsToSelectArray(parentTestsRaw);
      setParentTests(temparr);
      return;
    }
  });
  // end of first render ^^^^

  useEffect(() => {
    setParentTests(testsToSelectArray(parentTestsRaw));
  }, [parentTestsRaw]);

  useEffect(() => {
    setStudyTypes(
      studyTypesToSelectArray(studyTypesRaw, result.studyType.parentTest.id),
    );
  }, [result.studyType.parentTest]);

  useEffect(() => {
    setResultValue(result.result);
  }, [result.result]);

  const testsToSelectArray = parentTests => {
    return parentTests.map(test => {
      return {
        value: test,
        label: test.name,
      };
    });
  };

  const studyTypesToSelectArray = (studyTypes, selectedParentTestId) => {
    if (selectedParentTestId === -1) {
      return [];
    } else {
      let studiesForSelect = studyTypes.map(study => {
        return {
          value: study,
          label: study.name,
        };
      });
      // Filtering by parentTestId
      return studiesForSelect.filter(study => {
        return study.value.parentTest.id === selectedParentTestId;
      });
    }
  };

  const onChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    let newRow = { ...result, [name]: value };

    if (name === 'result') {
      setResultValue(value);
      clearTimeout(timeoutId);
      let newTimeout = setTimeout(() => {
        props.onChangeTest(newRow);
      }, 200);
      setTimeoutId(newTimeout);
    } else {
      props.onChangeTest(newRow);
    }
  };

  const resultDs = formatter.calculateDs(
    resultValue,
    result.studyType.mean,
    result.studyType.deviation,
  );

  if (result.studyType.category.categoryName === 'ÍNDICES SECUNDARIOS') {
    // ONLY EXCEPTION CASE
    return (
      <>
        {isNewCategory ? (
          <tr>
            <td colSpan="20" className="text-left pt-2 h5 font-weight-bold">
              <button
                className="btn button-deleteCategory m-1"
                type="button"
                onClick={e =>
                  props.deleteCategory(result.studyType.category.categoryName)
                }>
                {' '}
                <i className="fas fa-times"></i>
              </button>
              {result.studyType.category.categoryName}
            </td>
          </tr>
        ) : (
          <></>
        )}
        <tr key={index}>
          <td width="100%" className="col-test-name text-center px-1">
            {result.studyType.name}
          </td>
          <td>
            <input
              type="number"
              min={0}
              max={200}
              placeholder="..."
              name="result"
              onChange={e => onChange(e)}
              value={resultValue}
              className="new-exam-input"
              disabled={viewMode}
            />
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            {viewMode ? (
              <></>
            ) : (
              <button
                className="btn button-deleteExam m-1"
                type="button"
                onClick={e => props.removeTest(result)}>
                {' '}
                <i className="fa fa-trash-alt"></i>
              </button>
            )}
          </td>
        </tr>
      </>
    );
  }

  return (
    <>
      {isNewCategory ? (
        <tr>
          <td colSpan="20" className="text-left pt-2 h5 font-weight-bold">
            <button
              className="btn button-deleteCategory m-1"
              type="button"
              onClick={e =>
                props.deleteCategory(result.studyType.category.categoryName)
              }>
              {' '}
              <i className="fas fa-times"></i>
            </button>
            {result.studyType.category.categoryName}
          </td>
        </tr>
      ) : (
        <></>
      )}
      <tr key={index}>
        <td width="100%" className="col-test-name text-center px-1">
          {result.studyType.name}
        </td>
        <td>
          <input
            type="number"
            min={0}
            max={200}
            placeholder="..."
            name="result"
            onChange={e => onChange(e)}
            value={resultValue}
            className="new-exam-input"
            disabled={viewMode}
          />
        </td>
        <td>{result.studyType.mean}</td>

        <td>{result.studyType.deviation}</td>

        <td>
          {formatter.calculateResult(
            resultValue,
            result.studyType.mean,
            result.studyType.deviation,
          )}
        </td>
        <td className="zScoreCellRed">
          {resultDs === -3 ? <i className="fas fa-certificate "></i> : null}
        </td>
        <td className="zScoreCellRed">
          {resultDs === -2.5 ? <i className="fas fa-certificate "></i> : null}
        </td>
        <td className="zScoreCellRed">
          {resultDs === -2 ? <i className="fas fa-certificate "></i> : null}
        </td>
        <td className="zScoreCellRed">
          {resultDs === -1.5 ? <i className="fas fa-certificate "></i> : null}
        </td>
        <td className="zScoreCellGray">
          {resultDs === -1 ? <i className="fas fa-certificate "></i> : null}
        </td>
        <td className="zScoreCellGray">
          {resultDs === -0.5 ? <i className="fas fa-certificate "></i> : null}
        </td>
        <td className="zScoreCellGray">
          {resultDs === 0 ? <i className="fas fa-certificate "></i> : null}
        </td>
        <td className="zScoreCellGray">
          {resultDs === 0.5 ? <i className="fas fa-certificate "></i> : null}
        </td>
        <td className="zScoreCellGray">
          {resultDs === 1 ? <i className="fas fa-certificate "></i> : null}
        </td>
        <td className="zScoreCellGreen">
          {resultDs === 1.5 ? <i className="fas fa-certificate "></i> : null}
        </td>
        <td className="zScoreCellGreen">
          {resultDs === 2 ? <i className="fas fa-certificate "></i> : null}
        </td>
        <td className="zScoreCellGreen">
          {resultDs === 2.5 ? <i className="fas fa-certificate "></i> : null}
        </td>
        <td className="zScoreCellGreen">
          {resultDs === 3 ? <i className="fas fa-certificate "></i> : null}
        </td>
        <td>
          {viewMode ? (
            <></>
          ) : (
            <button
              className="btn button-deleteExam m-1"
              type="button"
              onClick={e => props.removeTest(result.studyType.id)}>
              {' '}
              <i className="fa fa-trash-alt"></i>
            </button>
          )}
        </td>
      </tr>
    </>
  );
};

export default NewExam;
