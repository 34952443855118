import apiHelper from '../../utils/apiHelpers';
import Footer from '../common/Footer';
import React, { useContext } from 'react';
import formatter from '../../utils/formatter';
import SelectUserRole from '../common/selects/SelectUserRole';
import Subscription from './Subscription';
import './User.css';
import Loader from '../common/Loader';
import { useState, useEffect } from 'react';
import apiHelpers from '../../utils/apiHelpers';
import ProfilePicture from './ProfilePicture';
import { UserContext, useUserContext } from '../../contexts/UserContext';
import { Link } from 'react-router-dom';

const User = props => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [isNew, setIsNew] = useState(props.match.params.id === 'new');
  const user = useUserContext();
  const [alertError, setAlertError] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);

  const handleChange = e => {
    const target = e.target;
    let value = formatter.formatFromTarget(target);
    const name = target.name;
    const user = this.state.user;
    user[name] = value;
    this.setState({
      user: user,
    });
  };

  const submitUser = async e => {
    e.preventDefault();
    let userId = user?.id;

    try {
      //If postalCodes already exists, update it. If not, create it
      const user = user;

      await apiHelper.patchUser(userId, user);
      setAlertSuccess(true);
    } catch (e) {
      const statusCode = e.response.status;
      let errorMessage;

      if (statusCode === 422) {
        setErrorMessage(true);
        errorMessage = 'Datos invalidos o incompletos';
      } else {
        setErrorMessage(true);
        errorMessage = e.message;
      }
    }
  };

  let title = user?.firstName + ' ' + user?.lastName;
  let alert;

  if (alertError) {
    alert = (
      <div className="alert alert-warning ml-5 mr-5 text-center">
        {errorMessage}
      </div>
    );
  }

  if (alertSuccess) {
    alert = (
      <div className="alert alert-success mt-3 ml-5 mr-5 text-center">
        Datos guardados exitosamente
      </div>
    );
  }

  return (
    <div
      key={user?.id}
      className={'d-flex flex-column h-100 ' + props.className}>
      <div className="container my-3 ">
        <div className="row justify-content-between align-items-center">
          <h3 className="patient-title mt-4 mb-4">
            <Link to={`${process.env.PUBLIC_URL}/patient`} className="">
              <i className="fas fa-chevron-left patient-title-arrow" />
            </Link>
            Mi perfil
          </h3>
        </div>

        <div className="px-5 pb-5 pt-2 userForm">
          {/* Tabs */}
          <ul className="nav nav-tabs flex-nowrap">
            <li className="nav-item text-nowrap">
              <a
                className={`nav-link nav-font
                ${currentTab == 0 ? ' active' : ''}`}
                href="#"
                onClick={() => {
                  setCurrentTab(0);
                }}>
                Perfil
              </a>
            </li>
            <li className="nav-item text-nowrap">
              <a
                className={`nav-link nav-font
                  ${currentTab == 1 ? ' active' : ''}`}
                href="#"
                onClick={() => {
                  setCurrentTab(1);
                }}>
                Suscripción
              </a>
            </li>
          </ul>
          {/* Content */}
          {currentTab === 0 ? (
            <>
              <div className="row justify-content-between align-items-center my-3 flex-nowrap">
                <div className="d-flex flex-row ">
                  <ProfilePicture clickable profileCard />
                  <p className="h3 patient-title align-self-center my-2 mx-3">
                    {`${user?.firstName} ${user?.lastName}`}
                  </p>
                </div>

                <div>
                  <Link
                    to={`${process.env.PUBLIC_URL}/editProfile`}
                    className="px-1 my-1">
                    <button
                      type="button"
                      className="btn btn-vpsy-solid text-nowrap">
                      <i className="fa fa-edit text-white mr-1"></i>
                      Editar
                    </button>
                  </Link>
                </div>
              </div>

              <div className="row justify-content-center mt-3">
                <div className="col-md-12 col-lg-4">
                  <div className="form-group">
                    <label htmlFor="name" className="patient-label">
                      Usuario
                    </label>
                    <input
                      disabled
                      type="text"
                      name="lastName"
                      className="form-control patient-input px-4"
                      placeholder="Usuario no definido"
                      value={user?.username}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 col-lg-4">
                  <div className="form-group">
                    <label htmlFor="name" className="patient-label">
                      Nombre(s)
                    </label>
                    <input
                      disabled
                      type="text"
                      name="firstName"
                      className="form-control patient-input px-4"
                      placeholder="Nombre no definido"
                      value={user?.firstName}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 col-lg-4">
                  <div className="form-group">
                    <label htmlFor="name" className="patient-label">
                      Apellido(s)
                    </label>
                    <input
                      disabled
                      type="text"
                      name="lastName"
                      className="form-control patient-input px-4"
                      placeholder="Apellido no definido"
                      value={user?.lastName}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className="row justify-content-center mt-3">
                <div className="col-md-12 col-lg-4">
                  <div className="form-group">
                    <label htmlFor="name" className="patient-label">
                      Email
                    </label>
                    <input
                      disabled
                      type="text"
                      name="lastName"
                      className="form-control patient-input px-4"
                      placeholder="Email no definido"
                      value={user?.email}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 col-lg-4">
                  <div className="form-group">
                    <label htmlFor="mpEmail" className="patient-label">
                      Email de MercadoPago
                    </label>
                    <input
                      disabled
                      type="text"
                      name="mpEmail"
                      className="form-control patient-input px-4"
                      placeholder=" Sin Email de MercadoPago"
                      value={user?.mpEmail}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 col-lg-4">
                  <div className="form-group">
                    <label htmlFor="password" className="patient-label w-100">
                      Contraseña
                      <p
                        className="float-right edit-pass-label mr-2"
                        onClick={() => {
                          props.history.push('/changePassword');
                        }}>
                        Editar
                      </p>
                    </label>
                    <input
                      disabled
                      type="text"
                      name="password"
                      className="form-control patient-input px-4"
                      placeholder=" Cambiar contraseña"
                      value={'**************'}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <Subscription history={props.history}></Subscription>
            </>
          )}
        </div>

        {alert}

        <div className="text-center">
          {function () {
            return (
              <button
                className=" button-submit btn btn-block my-  mt-3"
                onClick={submitUser}
                type="submit">
                Guardar <i className="fas fa-save saveicon"></i>
              </button>
            );
          }}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default User;
