import React, { useEffect, useState } from 'react';
import apiHelper from '../../../utils/apiHelpers';
import AsyncSelect from 'react-select/async';
import { useHistory } from 'react-router';

const SearchPatient = props => {
  const history = useHistory();

  const [wto, setWto] = useState(null);
  const [options, setOptions] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const getOptions = (input, callback) => {
    clearTimeout(wto);
    setWto(
      setTimeout(() => {
        let filters = {};

        if (!!input || input.length !== 0) {
          filters['where'] = {
            firstName: input,
          };
        }

        apiHelper
          .getPatients(filters)
          .then(users => {
            let usersForSelect = users.data.map(user => {
              return {
                value: user.id,
                label: user.firstName + ' ' + user.lastName,
                firstName: user.firstName,
                lastName: user.lastName,
                birthDate: user.birthDate,
              };
            });
            setOptions(usersForSelect);
            callback(usersForSelect);
          })
          .catch(function (err) {
            callback(err, null);
          });
      }, 250),
    );
  };

  const onChangeWrapper = selectedValue => {
    let event = { target: {} };
    event.target.name = props.name ? props.name : 'select-client-nameless';
    event.target.value = {
      id: selectedValue.value,
      birthDate: selectedValue.birthDate,
      firstName: selectedValue.firstName,
      lastName: selectedValue.lastName,
    };
    event.target.type = 'react-select';
    history.push(`/patient/${selectedValue.value}/records`);
  };

  const getDefaultOptions = patient => {
    if (!patient) return;

    let filters = {
      where: {
        id: patient.id,
      },
    };
    setIsFetching(true);

    apiHelper
      .getPatients(filters)
      .then(users => {
        let usersForSelect = users.data.map(user => {
          return {
            value: user.id,
            label: user.firstName + ' ' + user.lastName,
            birthDate: user.birthDate,
          };
        });

        setOptions(usersForSelect);
      })
      .then(() => {
        setIsFetching(false);
      })
      .catch(err => {
        console.log(err);
      });
  };

  let { value } = props;
  let userValue;

  if (value) {
    userValue = options.find(option => option.value === value.id);
  }

  if (value && !value.id) {
    if (!userValue && !isFetching) {
      getDefaultOptions(value);
    }
  }

  return (
    <AsyncSelect
      className={props.className}
      name={props.name}
      onChange={onChangeWrapper}
      value={userValue || ''} //Workaround to clear value with null. See https://github.com/JedWatson/react-select/issues/3066
      required={props.required}
      loadOptions={getOptions}
      placeholder={props.placeholder}
      menuContainerStyle={{ zIndex: 999 }}
      noOptionsMessage={() => 'Ingrese un valor para buscar'}
      isClearable={props.required}
      defaultOptions={true}
      styles={props.styles}
    />
  );
};

export default SearchPatient;
