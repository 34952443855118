import apiHelper from '../../utils/apiHelpers';
import Footer from '../common/Footer';
import React from 'react';
import formatter from '../../utils/formatter';
import './User.css';
import { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router';

const EditProfile = props => {
  const [alertError, setAlertError] = useState(null);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [user, setUser] = useState(null);
  const history = useHistory();

  const handleSubmit = async e => {
    e.preventDefault();

    Swal.fire({
      title: `¿Confirma que quiere editar sus datos de perfil?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#22654e',
      customClass: {
        cancelButton: 'swal-cancel-btn', // Add your custom class for the cancel button
      },
      confirmButtonText: 'Si, editar.',
    }).then(result => {
      if (result.isConfirmed) {
        apiHelper
          .patchUserProfile(user)
          .then(response => {
            Swal.fire({
              title: `Perfil ha sido editado.`,
              icon: 'success',
              confirmButtonColor: '#22654e',
              confirmButtonText: 'Continuar',
            })
              .then(() => {
                history.push('/profile');
              })
              .finally(() => {
                window.location.reload();
              });
          })
          .catch(error => {
            console.log(error);
            if (error.response.status == 401) {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'La contraseña es incorrecta.',
              });
            }
          });
      }
    });
  };

  const handleChange = e => {
    const target = e.target;
    let value = formatter.formatFromTarget(target);
    const propName = target.name;
    setUser(prevState => {
      return {
        ...prevState,
        [propName]: value,
      };
    });
  };

  useEffect(() => {
    if (user === null) {
      apiHelper
        .getCurrentUser()
        .then(response => {
          setUser({ ...response.data, password: '' });
        })

        .catch(e => {
          console.log(e);
        });
    }
  }, []);

  useEffect(() => {
    if (alertError) {
      setAlertError(null);
    }
  }, [user]);

  // Alert signs
  let alert;
  if (alertError) {
    alert = (
      <div className="alert alert-warning ml-5 mr-5 text-center">
        {alertError}
      </div>
    );
  }

  if (alertSuccess) {
    alert = (
      <div className="alert alert-success mt-3 ml-5 mr-5 text-center">
        Los cambios fueron guardados.
      </div>
    );
  }

  return (
    <div className={'d-flex flex-column h-100 fill-height ' + props.className}>
      <div className="container mt-3 ">
        <div className="p-3 m-auto userForm ">
          <form className="form-group" onSubmit={handleSubmit}>
            <div className="row justify-content-center mt-3">
              <div className="col-md-12 col-lg-5">
                <div className="form-group">
                  <label htmlFor="firstName">Nombre</label>
                  <input
                    type="text"
                    required
                    onChange={handleChange}
                    name="firstName"
                    className="form-control"
                    placeholder={user ? user.firstName : 'Nombre'}
                    value={user ? user.firstName : ''}
                  />
                </div>
              </div>

              <div className="col-md-12 col-lg-5">
                <div className="form-group">
                  <label htmlFor="firstName">Apellido</label>
                  <input
                    type="text"
                    required
                    onChange={handleChange}
                    name="lastName"
                    className="form-control"
                    placeholder={user ? user.lastName : 'Apellido'}
                    value={user ? user.lastName : ''}
                  />
                </div>
              </div>
            </div>

            <div className="row justify-content-center mt-3">
              <div className="col-md-12 col-lg-5">
                <div className="form-group">
                  <label htmlFor="firstName">Email</label>
                  <input
                    type="email"
                    required
                    onChange={handleChange}
                    name="email"
                    className="form-control"
                    placeholder={user ? user.email : 'Email'}
                    value={user ? user.email : ''}
                  />
                </div>
              </div>

              <div className="col-md-12 col-lg-5">
                <div className="form-group">
                  <label htmlFor="firstName">Email de MercadoPago</label>
                  <input
                    type="email"
                    onChange={handleChange}
                    name="mpEmail"
                    className="form-control"
                    placeholder={user ? user.mpEmail : 'Email de MercadoPago'}
                    value={user ? user.mpEmail : ''}
                  />
                </div>
              </div>
            </div>

            {/* ALERT SIGN */}
            {alert}

            {/* Button submit */}
            <>
              <div className="w-100 mb-3">
                <div className="d-flex justify-content-center w-100">
                  <button
                    className="col-md-3 btn-vpsy-outline exam-btn-txt my-3 mx-3"
                    onClick={() => {
                      history.push(`/profile`);
                    }}>
                    Atrás
                  </button>

                  <button
                    className="col-md-3 btn-vpsy-gradient exam-btn-txt my-3 mx-3"
                    disabled={alertSuccess}
                    type="submit">
                    Guardar cambios
                  </button>
                </div>
              </div>
            </>
          </form>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default EditProfile;
