import React, { useContext, Fragment } from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import apiHelper from '../../../utils/apiHelpers';
import './ExamSection.css';
import SelectPatient from '../../common/selects/SelectPatient';
import { ExamContext } from '../ExamContext';
import NewExam from '../NewExam';
import moment from 'moment';
import Swal from 'sweetalert2';
import { EXAM_SECTION, TEST_ORDER_EXCEPTIONS } from '../ExamConstants';

let newTestFlag = false;
// TestTable only shows score table
const TestsTable = ({
  currentParentTestId,
  parentTests,
  studyTypes,
  viewMode,
}) => {
  const [testRows, setTestRows] = useState();
  const {
    exam,
    setExam,
    patient,
    setPatient,
    currentSection,
    setCurrentSection,
  } = useContext(ExamContext);
  const categoriesScoreTypeDirect = []; // direct score: true, T score: false

  useEffect(() => {
    setTestRows(studyArrayToJSX());
  }, [exam.results, currentParentTestId]);

  const triggerScoreTypeAlert = () => {
    const hasDirectScore = categoriesScoreTypeDirect.some(e => e === true);
    const hasTScore = categoriesScoreTypeDirect.some(e => e === false);
    const scoreType =
      hasDirectScore && hasTScore
        ? 'directo y T'
        : hasDirectScore
        ? 'directo'
        : 'T';

    Swal.fire({
      title: `Puntaje ${scoreType} `,
      text: `Este test se completa con puntaje ${scoreType}.`,
      showCancelButton: false,
      confirmButtonColor: '#22654e',
      confirmButtonText: 'Continuar',
    });
  };

  const removeTest = studyTypeId => {
    Swal.fire({
      title: `¿Desea eliminar el estudio de la lista?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#22654e',
      customClass: {
        cancelButton: 'swal-cancel-btn', // Add your custom class for the cancel button
      },
      confirmButtonText: 'Si, eliminar.',
    }).then(result => {
      if (result.isConfirmed) {
        let newResultsArray = exam.results.slice();
        newResultsArray.splice(
          newResultsArray.findIndex(test => {
            return test.studyType.id === studyTypeId;
          }),
          1,
        );
        const newExam = { ...exam, ['results']: newResultsArray };
        setExam(newExam);
        Swal.fire({
          title: `Estudio eliminado.`,
          icon: 'success',
          confirmButtonColor: '#22654e',
          confirmButtonText: 'Continuar',
        });
      }
    });
  };

  const deleteCategory = category => {
    Swal.fire({
      title: `¿Desea eliminar la categoría "${category}"?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#22654e',
      customClass: {
        cancelButton: 'swal-cancel-btn', // Add your custom class for the cancel button
      },
      confirmButtonText: 'Si, eliminar.',
    }).then(result => {
      if (result.isConfirmed) {
        setExam(currExam => {
          let results = currExam.results.filter(result => {
            return (
              result.studyType.category.categoryName !== category &&
              result.studyType.parentTest.id === currentParentTestId
            );
          });
          return { ...currExam, results };
        });
        Swal.fire({
          title: `Categoría "${category}" eliminada.`,
          icon: 'success',
          confirmButtonColor: '#22654e',
          confirmButtonText: 'Continuar',
        });
      }
    });
  };

  const onChangeTest = result => {
    let newResultsArray = exam.results.slice();
    let index = newResultsArray.findIndex(test => {
      return test.studyType.id === result.studyType.id;
    });
    newResultsArray.splice(index, 1, result);
    const newExam = { ...exam, ['results']: newResultsArray };
    setExam(newExam);
  };

  const studyArrayToJSX = () => {
    // Get all categories
    const categories = [
      ...new Set(
        exam.results
          .filter(result => {
            return result.studyType.parentTest.id === currentParentTestId;
          })
          .map(result => result.studyType.category.categoryName),
      ),
    ];
    let categoryTables = categories.map((category, i) => {
      let categoyTests = exam.results
        .filter(result => {
          return (
            result.studyType.category.categoryName === category &&
            result.studyType.parentTest.id === currentParentTestId
          );
        })
        .sort((resultA, resultB) => {
          return resultA.studyType.id - resultB.studyType.id;
        });

      // Some tests have specific order
      const parentTestName = categoyTests[0].studyType.parentTest.name; // [0] because every index should have the same parentTest
      const testOrderData = TEST_ORDER_EXCEPTIONS.find(
        data => data.parentTest === parentTestName,
      );
      let studyTypeOrder = [];
      if (testOrderData)
        studyTypeOrder =
          testOrderData.categories.find(
            categoryData => categoryData.name == category,
          )?.studyTypeOrder || [];
      if (studyTypeOrder.length) {
        categoyTests = categoyTests.sort((a, b) => {
          return (
            studyTypeOrder.indexOf(a.studyType.name) -
            studyTypeOrder.indexOf(b.studyType.name)
          );
        });
      }

      let isDirectScore =
        new Set(
          categoyTests.map(e => {
            return e.studyType.mean;
          }),
        ).size > 1;
      categoriesScoreTypeDirect.push(isDirectScore);

      return (
        <Fragment key={i}>
          <div className="d-flex flex-row w-100 border-bottom mt-3">
            <div className="category-label pl-3">{category}</div>
            <div className="ml-auto">
              {viewMode ? (
                <></>
              ) : (
                <button
                  className="btn button-deleteExam m-1"
                  type="button"
                  onClick={e => deleteCategory(category)}>
                  {' '}
                  <i className="fa fa-trash-alt"></i>
                </button>
              )}
            </div>
          </div>
          <table className="table-responsive table-fixed">
            <thead className="headExams">
              <tr>
                <th className="col-test-name"></th>
                <th className="col-number-input px-0">{`Puntaje${
                  isDirectScore ? '·PD' : '·T'
                }`}</th>
                <th className="col-number px-1">Media</th>
                <th className="col-number px-1">Desvio</th>
                <th className="col-number px-1">Resultado</th>
                <th>
                  -1
                  <hr />
                  -3
                </th>
                <th>
                  1<hr />
                  -2,5
                </th>
                <th>
                  2<hr />
                  -2
                </th>
                <th>
                  7<hr />
                  -1,5
                </th>
                <th>
                  16
                  <hr />
                  -1
                </th>
                <th>
                  31
                  <hr />
                  -0,5
                </th>
                <th>
                  50
                  <hr />0
                </th>
                <th>
                  69
                  <hr />
                  0,5
                </th>
                <th>
                  84
                  <hr />1
                </th>
                <th>
                  93
                  <hr />
                  1,5
                </th>
                <th>
                  98 <hr />2
                </th>
                <th>
                  99
                  <hr />
                  2,5
                </th>
                <th>
                  99 <hr />3
                </th>
              </tr>
            </thead>
            <tbody className="tableExams">
              {categoyTests.map((test, i) => {
                return (
                  <NewExam
                    key={i}
                    isNewCategory={false}
                    result={test}
                    removeTest={removeTest}
                    index={i}
                    onChangeTest={onChangeTest}
                    parentTestsRaw={parentTests}
                    studyTypesRaw={studyTypes}
                    deleteCategory={deleteCategory}
                    viewMode={viewMode}
                  />
                );
              })}
            </tbody>
          </table>
        </Fragment>
      );
    });

    if (
      !viewMode &&
      currentParentTestId !== null &&
      newTestFlag &&
      categoriesScoreTypeDirect.length
    ) {
      triggerScoreTypeAlert();
      newTestFlag = false;
    }

    return categoryTables;
  };

  return <>{testRows}</>;
};

const ExamTests = ({ viewMode }) => {
  const history = useHistory();

  const {
    exam,
    setExam,
    patient,
    setPatient,
    currentSection,
    setCurrentSection,
  } = useContext(ExamContext);

  const [parentTests, setParentTests] = useState([]);
  const [studyTypes, setStudyTypes] = useState([]);
  const [addedParentTestIds, setAddedParentTestIds] = useState([
    ...new Set(exam.results?.map(result => result.studyType.parentTest.id)),
  ]);
  const [currentParentTestId, setCurrentParentTestId] = useState(0);

  // UseEffect: Always
  const patientAge = moment().diff(exam.patient.birthDate, 'years');
  useEffect(() => {
    apiHelper
      .getParentTests()
      .then(parentTestsRes => {
        const filteredParentTests = parentTestsRes.data.filter(
          test => test.minAge <= patientAge && patientAge <= test.maxAge,
        );
        setParentTests(filteredParentTests);
      })
      .catch(e => {
        console.error(e);
      });

    apiHelper
      .getStudyTypes()
      .then(studyTypesRes => {
        setStudyTypes(studyTypesRes.data);
      })
      .catch(e => {
        console.error(e);
      });
  }, [patient]);

  useEffect(() => {
    if (viewMode) {
      apiHelper
        .getParentTests()
        .then(parentTestsRes => {
          const filteredParentTests = parentTestsRes.data.filter(
            test => test.minAge <= patientAge && patientAge <= test.maxAge,
          );
          setParentTests(filteredParentTests);
          const newAddedIds = [
            ...new Set(
              exam.results.map(result => result.studyType.parentTest.id),
            ),
          ];
          setAddedParentTestIds(newAddedIds);
          setCurrentParentTestId(newAddedIds[0]);
        })
        .catch(e => {
          console.error(e);
        });
    }
  }, [exam]);

  // Call addCompleteParentTest to Add complete test to Exam context
  const addCompleteParentTest = parentTest => {
    let filters = {
      where: { parentTest },
    };

    if (!addedParentTestIds.some(id => id === parentTest.id))
      setAddedParentTestIds(currentIds => [...currentIds, parentTest.id]);

    apiHelper
      .getStudyTypes(filters)
      .then(studiesResponse => {
        // Only adds studies that are new.
        let filteredResultsArray = studiesResponse.data.filter(study => {
          return !exam.results?.some(result => {
            return result.studyType.id === study.id;
          });
        });
        if (filteredResultsArray?.length > 0) {
          // Building object array
          let parentArr = filteredResultsArray.map(study => {
            return {
              result: '',
              studyType: study,
            };
          });

          // Adding new array
          let newResultsArray = parentArr.concat(exam.results);
          const newExam = { ...exam, ['results']: newResultsArray };
          setExam(newExam);
        }
      })
      .catch(e => {
        console.log(e);
      });
  };

  const deleteParentTest = parentTestId => {
    //DELETE PARENT TEST SWAL
    let parentTestName = parentTests.find(test => {
      return test.id === parentTestId;
    }).name;

    Swal.fire({
      title: `¿Desea eliminar el test "${parentTestName}"?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#22654e',
      customClass: {
        cancelButton: 'swal-cancel-btn', // Add your custom class for the cancel button
      },
      confirmButtonText: 'Si, eliminar.',
    }).then(result => {
      if (result.isConfirmed) {
        setExam(currExam => {
          let results = currExam.results.filter(result => {
            return result.studyType.parentTest.id !== parentTestId;
          });
          setAddedParentTestIds([
            ...new Set(results.map(result => result.studyType.parentTest.id)),
          ]);
          return { ...currExam, results };
        });
        setCurrentParentTestId(null);
        window.scrollTo(0, 0);
      }
    });
  };

  const saveTest = async e => {
    e.preventDefault();
    try {
      // clear all results with no score
      let cleanResults = exam.results.filter(score => {
        return parseFloat(score.result) !== 'NaN' && score.result !== '';
      });
      let cleanExam = { ...exam, results: cleanResults };

      //If requests already exists, update it. If not, create it
      let response;
      if (!exam.id) {
        response = await apiHelper.postExam(cleanExam);
      } else {
        response = await apiHelper.patchExam(exam.id, cleanExam);
      }
      // setExam(response.data);
      await apiHelper.getExam(response.data.id).then(result => {
        setExam(result.data);
      });

      Swal.fire({
        title: `Test guardado con éxito.`,
        text: '¿Desea agregar otro test?',
        icon: 'success',
        showCancelButton: true,
        confirmButtonColor: '#22654e',
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        customClass: {
          cancelButton: 'swal-cancel-btn', // Add your custom class for the cancel button
        },
      }).then(result => {
        if (result.isConfirmed) {
          setCurrentParentTestId(0);
        } else {
          setCurrentSection(EXAM_SECTION.CONCLUSIONS);
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  const getTextBootstrapColor = name => {
    let firstLetter = name.charAt(0).toLowerCase();
    return firstLetter < 'e'
      ? 'text-primary'
      : firstLetter >= 'e' && firstLetter < 'j'
      ? 'text-success'
      : firstLetter >= 'o' && firstLetter < 't'
      ? 'text-danger'
      : firstLetter >= 't'
      ? 'text-warning'
      : 'text-info';
  };

  return (
    <>
      <div className="d-flex flex-column h-100">
        <div className=" exam-section-title my-1 mb-3">
          {/* TABS with parent test */}

          {currentParentTestId ? (
            <>
              {viewMode ? (
                <div className="row">
                  <div className="col-6 exam-section-title my-2">
                    Tabla de resultados
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-6 exam-section-title my-2">
                    Seleccionar Test
                  </div>
                  <div className="col-6 px-0">
                    <div className="d-flex justify-content-end w-100">
                      <button
                        className="col-md-3 btn-vpsy-outline exam-btn-txt my-1 mx-1"
                        onClick={() => {
                          deleteParentTest(currentParentTestId);
                        }}>
                        Eliminar
                      </button>
                      <button
                        className="col-md-3 btn-vpsy-gradient exam-btn-txt my-1 mx-1"
                        onClick={saveTest}>
                        Continuar
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : viewMode ? (
            <div className="row">
              <div className="col-6 exam-section-title my-2">
                Este examen no tiene agregado ningún test
              </div>
            </div>
          ) : (
            <>
              <div className="row">
                <div className="col-6 exam-section-title my-2">
                  Seleccionar Test
                </div>
                <>
                  <div className="col-6 px-0">
                    <div className="d-flex justify-content-end w-100">
                      <button
                        className="col-md-3 btn-vpsy-outline exam-btn-txt my-1 mx-1"
                        onClick={() => {
                          setCurrentParentTestId(null);
                          setCurrentSection(EXAM_SECTION.PATIENT);
                          window.scrollTo(0, 0);
                        }}>
                        Atrás
                      </button>
                      <button
                        className="col-md-3 btn-vpsy-gradient exam-btn-txt my-1 mx-1"
                        disabled={!addedParentTestIds.length}
                        onClick={() => {
                          setCurrentParentTestId(addedParentTestIds[0]);
                          window.scrollTo(0, 0);
                        }}>
                        Puntajes
                      </button>
                    </div>
                  </div>
                </>
              </div>

              <div className="w-100 d-flex">
                <div className="exam-line-green" />
                <div className="exam-line-divider" />
              </div>
            </>
          )}
        </div>

        {currentParentTestId ? (
          <ul className="nav nav-tabs exam-nav-tabs">
            {addedParentTestIds.map((parentTestId, index) => {
              let parentTest = parentTests.find(test => {
                return parentTestId === test.id;
              });
              return (
                <li className="nav-item" key={index}>
                  <a
                    className={`nav-link nav-font
											${parentTestId == currentParentTestId ? ' active' : ''}`}
                    href="#"
                    onClick={() => {
                      setCurrentParentTestId(parentTest.id);
                    }}>
                    {parentTest?.name}
                  </a>
                </li>
              );
            })}
          </ul>
        ) : (
          <></>
        )}

        <div className={`${parentTests.length ? 'test-tables mt-3' : ''}`}>
          {/* TESTS SELECTORS */}
          {!currentParentTestId && parentTests.length && !viewMode ? (
            parentTests
              .sort(function (a, b) {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              })
              .map((parentTest, i) => {
                return (
                  <button
                    key={i}
                    className="test-select-btn m-2"
                    onClick={() => {
                      setCurrentParentTestId(parentTest.id);
                      addCompleteParentTest(parentTest);
                      newTestFlag = true;
                    }}>
                    <i
                      className={`test-select-icon fas fa-file-alt ${getTextBootstrapColor(
                        parentTest.name,
                      )}`}></i>
                    <div className="test-select-btn-txt">{parentTest.name}</div>
                  </button>
                );
              })
          ) : parentTests.length == 0 ? (
            <>
              <div className="container w-100 h-100 no-conclusions-sign">
                <div class="row justify-content-center mb-3">
                  <div class="col-8 text-center">
                    <i className="fas fa-search"></i>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-8 text-center no-conclusions-text font-weight-bold mb-2">
                    NO SE ENCONTRARON TESTS
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-8 text-center no-conclusions-text mb-2">
                    La edad del paciente no corresponde con ningún test.
                    Verifique la edad.
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div className="d-flex justify-content-center w-100">
                    <button
                      className="col-md-3 btn-vpsy-outline exam-btn-txt my-3 mx-1 bg-white"
                      onClick={() => {
                        history.push(`/patient/${patient.id}`);
                      }}>
                      Editar paciente
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          {currentParentTestId || viewMode ? (
            <TestsTable
              currentParentTestId={currentParentTestId}
              parentTests={parentTests}
              studyTypes={studyTypes}
              viewMode={viewMode}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default ExamTests;
