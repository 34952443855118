import React from 'react';
import RecoverPassword from '../login/RecoverPassword';
import Login from '../login/Login';
import UserDetail from '../user/User';
import ChangePassword from '../user/ChangePassword';
import ChangeMPEmail from '../user/ChangeMPEmail';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import './App.css';
import PrivateRoute from '../../utils/PrivateRoute';
import Patient from '../patient/Patient';
import PatientDetail from '../patient/PatientDetail';
import Exams from '../exam/Exams';
import ExamsRows from '../exam/ExamsRows';
import Appointment from '../appointment/Appointment';
import AppointmentDetail from '../appointment/AppointmentDetail';
//import { format } from 'date-fns/format';
import Landing from '../landing/Landing';
import About from '../landing/sections/About';
import Advantages from '../landing/sections/Advantages';
import Subscription from '../landing/sections/Subscription';
//import Footer from '../common/Footer';
import { VTasksWidget } from 'vtasks-widget';
import LandingMercadoPago from '../landingMercadoPago/landingMercadoPago';
import AccesPayedOnlyRoute from '../../utils/AccesPayedOnlyRoute';
import EditProfile from '../user/EditProfile';
import PatientRecords from '../patient/PatientRecords';
import ExamView from '../exam/ExamView';
import Help from '../help/Help';
import EmailValidation from '../user/EmailValidation';
import CacheBuster from 'react-cache-buster';
import Loader from '../common/Loader';
import * as Sentry from '@sentry/react';
import Suggestions from '../suggestions/Suggestions';
import TestComp from '../tipTap/RichTextEditor';
const config = require('../../config/config');

const history = createBrowserHistory();
Sentry.init({
  dsn: config.sentry.dsn,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: config.sentry.tracePropagationTargets,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
  return (
    <>
      <CacheBuster
        currentVersion={process.env.npm_package_version}
        isEnabled={true} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
        loadingComponent={<Loader />} //If not pass, nothing appears at the time of new version check.
        metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
      >
        <Router history={history}>
          <Switch>
            <Route name="test" path={`/test`} component={TestComp} />
            <Route name="landing" path={`/landing`} component={Landing} />
            <Route name="signUp" path={`/signup`} component={Subscription} />
            <Route name="about" path={`/about`} component={About} />
            <Route
              name="advantages"
              path={`/advantages`}
              component={Advantages}
            />
            <Route
              name="subscription"
              path={`/subscription`}
              component={Subscription}
            />
            <Route name="login" path={`/login`} component={Login} />
            <Route
              name="recover_password"
              path={`/recover_password`}
              component={RecoverPassword}
            />
            <PrivateRoute
              name="landing-mercadopago"
              path={`/landing-mercadopago`}
              component={LandingMercadoPago}
            />
            <PrivateRoute
              name="patientRecords"
              path={`/patient/:id/records`}
              component={PatientRecords}
            />
            <AccesPayedOnlyRoute
              name="patient"
              path={`/patient/:id`}
              component={PatientDetail}
            />
            <PrivateRoute
              name="patients"
              path={`/patient`}
              component={Patient}
            />
            <AccesPayedOnlyRoute
              name="appointment"
              path={`/appointment/:id`}
              component={AppointmentDetail}
            />
            <PrivateRoute
              name="appointments"
              path={`/appointment`}
              component={Appointment}
            />
            <PrivateRoute name="help" path={`/help`} component={Help} />
            <PrivateRoute
              name="suggestions"
              path={`/suggestions`}
              component={Suggestions}
            />
            <PrivateRoute
              name="user"
              path={`/profile`}
              component={UserDetail}
            />
            <PrivateRoute
              name="changePassword"
              path={`/changePassword`}
              component={ChangePassword}
            />
            <PrivateRoute
              name="editProfile"
              path={`/editProfile`}
              component={EditProfile}
            />
            <PrivateRoute
              name="exam"
              path={`/exam/:id/view`}
              component={ExamView}
            />
            <AccesPayedOnlyRoute
              name="exam"
              path={`/exam/:id`}
              component={Exams}
            />
            <AccesPayedOnlyRoute
              name="exams"
              path={`/exam`}
              component={Exams}
            />
            <Route
              name="emailValidation"
              path={`/validation`}
              component={EmailValidation}
            />
            <PrivateRoute name="default" path={`/`} component={UserDetail} />
            <Route name="default" path={`/`} component={Landing} />
          </Switch>
        </Router>
      </CacheBuster>
    </>
  );
}

export default App;
