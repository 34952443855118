import React, { useContext, useRef } from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import apiHelper from '../../../utils/apiHelpers';
import './ExamSection.css';
import SelectPatient from '../../common/selects/SelectPatient';
import { ExamContext } from '../ExamContext';
import { EXAM_SECTION } from '../ExamConstants';
import CreatableSelect from 'react-select/creatable';
import './Conclusions.css';
import {
  SECTION_SEPARATOR,
  TITLE_SEPARATOR,
  TITLE_INDEX,
  BODY_INDEX,
  SECTION_OPTIONS,
} from '../ExamConstants';
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Tiptap from '../../tipTap/Tiptap';

export const deentitizeHTML = htmlFromDB => {
  let ret = htmlFromDB.replace(/&gt;/g, '>');
  ret = ret.replace(/&lt;/g, '<');
  ret = ret.replace(/&quot;/g, '"');
  ret = ret.replace(/&apos;/g, "'");
  ret = ret.replace(/&amp;/g, '&');
  return ret;
};

const ConclusionModal = ({
  conclusion,
  onConclusionChange,
  index,
  show,
  onHide,
  animation,
  scrollable,
  editable,
}) => {
  const editorRef = useRef();

  const loadAndHide = () => {
    if (editorRef.current && editable)
      onConclusionChange(editorRef.current.getHTML(), index, 'body');
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      animation={animation}
      scrollable={scrollable}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="conclusion-modal-title">
          {editable ? `Agregar conclusión` : conclusion.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="my-4">
          <div className="my-3 d-flex flex-column justify-content-around">
            {editable && (
              <div className="col-12 d-inline-flex flex-column">
                <div className="input-group-prepend align-middle">
                  <CreatableSelect
                    className="section-title"
                    isClearable
                    defaultValue={{
                      value: conclusion.title || 'Título...',
                      label: conclusion.title || 'Título...',
                    }}
                    onChange={(newValue, actionMeta) => {
                      onConclusionChange(newValue, index, 'title');
                    }}
                    options={SECTION_OPTIONS.sort(function (a, b) {
                      if (a.label < b.label) {
                        return -1;
                      }
                      if (a.label > b.label) {
                        return 1;
                      }
                      return 0;
                    })}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderRadius: '50px',
                        padding: '3px 17.4px 3px 20px',
                        border: 'solid 1px #e1e1e1',
                      }),
                      container: (baseStyles, state) => ({
                        ...baseStyles,
                        width: '50%',
                        height: '43px',
                      }),
                    }}
                  />{' '}
                </div>
              </div>
            )}
            <div className="m-3">
              <Tiptap
                ref={editorRef}
                htmlContent={deentitizeHTML(conclusion.body)}
                editable={editable}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-vpsy-solid" onClick={loadAndHide}>
          Aceptar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const ExamConclusions = ({ viewMode }) => {
  const {
    exam,
    setExam,
    patient,
    setPatient,
    currentSection,
    setCurrentSection,
  } = useContext(ExamContext);

  const [conclusions, setConclusions] = useState([]);
  const [shownConclusionIndex, setShownConclusionIndex] = useState(null);

  useEffect(() => {
    let newConclusions = [];
    if (exam.testConclusion?.length) {
      newConclusions = exam.testConclusion;
    } else if (exam.conclusion) {
      // legacy conclusion string processing
      let examSections = exam.conclusion.split(SECTION_SEPARATOR);
      for (const section of examSections) {
        if (section.length > 0) {
          let splitSection = section.split(TITLE_SEPARATOR);
          newConclusions.push({
            title: splitSection[TITLE_INDEX],
            body: splitSection[BODY_INDEX],
          });
        }
      }
    }

    setConclusions(newConclusions);
  }, [exam.testConclusion, exam.conclusion]);

  const onConclusionChange = (e, index, propname) => {
    let value = '';
    if (e !== null) {
      value = propname === 'body' ? e : e.value;
    }
    const newConclusions = [...conclusions];
    newConclusions[index][propname] = value;

    setExam({ ...exam, testConclusion: newConclusions });
  };

  const conclutionsArrayToRaw = conclusions => {
    let newRawConclusion = '';
    for (const conclusion of conclusions) {
      newRawConclusion =
        newRawConclusion +
        conclusion.title +
        TITLE_SEPARATOR +
        conclusion.body +
        SECTION_SEPARATOR;
    }
    setRawConclusions(newRawConclusion);
  };

  const addConclusion = () => {
    let newConclusions = [...conclusions];
    newConclusions.push({ title: '', body: '' });
    setConclusions(newConclusions);
    setExam({ ...exam, testConclusion: newConclusions });
    setShownConclusionIndex(newConclusions.length - 1);
  };

  const deleteConclusion = index => {
    Swal.fire({
      title: `¿Desea eliminar la conclusión?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#22654e',
      customClass: {
        cancelButton: 'swal-cancel-btn', // Add your custom class for the cancel button
      },
      confirmButtonText: 'Si, eliminar.',
    }).then(result => {
      if (result.isConfirmed) {
        const newConclusions = [...conclusions];
        newConclusions.splice(index, 1);
        // conclutionsArrayToRaw(newConclusions);
        setConclusions(newConclusions);
        Swal.fire({
          title: `Conclusión eliminada.`,
          icon: 'success',
          confirmButtonColor: '#22654e',
          confirmButtonText: 'Continuar',
        });
      }
    });
  };

  const handleChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setPatient(value);
    setExam(exam => {
      return { ...exam, patient: value };
    });
  };

  const setRawConclusions = rawConclusions => {
    let newExamState = { ...exam, conclusion: rawConclusions };
    setExam(newExamState);
  };

  const saveTest = async e => {
    e.preventDefault();
    try {
      const newConclusions = conclusions.filter(
        c => !(c.title === '' && c.body === ''),
      );
      //If requests already exists, update it. If not, create it
      let response;
      if (!exam.id) {
        response = await apiHelper.postExam({
          ...exam,
          testConclusion: newConclusions,
        });
      } else {
        response = await apiHelper.patchExam(exam.id, {
          ...exam,
          testConclusion: newConclusions,
        });
      }
      setExam({ ...exam, ...response.data });

      Swal.fire({
        title: `Conclusiones guardadas con éxito.`,
        text: '¿Desea agregar otra conclusión?',
        icon: 'success',
        showCancelButton: true,
        confirmButtonColor: '#22654e',
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        customClass: {
          cancelButton: 'swal-cancel-btn', // Add your custom class for the cancel button
        },
      }).then(result => {
        if (result.isConfirmed) {
          addConclusion();
        } else {
          setCurrentSection(EXAM_SECTION.ATTACHMENTS);
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <div className="d-flex flex-column h-100">
        <div className="exam-section-title my-2">
          {viewMode ? (
            <></>
          ) : (
            <>
              <div className="row">
                <div className="col-6 exam-section-title my-2">
                  Conclusiones de evaluación
                </div>
                <div className="col-6 px-0">
                  <div className="float-right d-flex justify-content-end w-100">
                    <button
                      className="col-md-3 btn-vpsy-outline exam-btn-txt my-1 mx-1"
                      onClick={() => {
                        setCurrentSection(EXAM_SECTION.TESTS);
                      }}>
                      Atrás
                    </button>
                    <button
                      className="col-md-3 btn-vpsy-gradient exam-btn-txt my-1 mx-1"
                      onClick={e => {
                        saveTest(e);
                      }}>
                      Continuar
                    </button>
                  </div>
                </div>
              </div>

              <div className="w-100 d-flex">
                <div className="exam-line-green" />
                <div className="exam-line-divider" />
              </div>
            </>
          )}
        </div>

        <div
          className={
            conclusions.length > 0 ? 'test-tables mt-3' : 'mt-auto py-3'
          }>
          <div className="container">
            {conclusions.length || viewMode ? (
              conclusions.map((conclusion, index) => {
                return (
                  <div key={index} className="row">
                    <div
                      className={`${
                        viewMode ? 'col-11' : 'col-10'
                      } mt-2 w-100 conclusion-list-item  justify-content-center`}>
                      <p className="px-3 py-2">
                        <b>{`${conclusion.title} | `}</b>
                        <i>{`${deentitizeHTML(conclusion.body)
                          .replace(/<[^>]*>?/gm, ' ')
                          .replace(/\s+/g, ' ')
                          .slice(0, 80)}...`}</i>
                      </p>
                    </div>
                    {viewMode ? (
                      <div className="col-1">
                        <button
                          type="button"
                          className="btn pl-2 pt-2"
                          title="Editar conclusión"
                          onClick={() => {
                            setShownConclusionIndex(index);
                          }}>
                          <span className="fa fa-eye icon-hover"></span>
                        </button>
                      </div>
                    ) : (
                      <div className="col-2">
                        <button
                          type="button"
                          className="btn pl-2 pt-2"
                          title="Editar conclusión"
                          onClick={() => {
                            setShownConclusionIndex(index);
                          }}>
                          <span className="fa fa-edit icon-hover"></span>
                        </button>
                        <button
                          type="button"
                          className="btn icon-red pl-2 pt-2"
                          onClick={() => {
                            deleteConclusion(index);
                          }}
                          title="Eliminar conclusión">
                          <span className="fa fa-trash-alt icon-hover"></span>
                        </button>
                      </div>
                    )}
                  </div>
                );
              })
            ) : (
              <>
                <div className="container w-100 h-100 no-conclusions-sign">
                  <div class="row justify-content-center mb-3">
                    <div class="col-8 text-center">
                      <i className="fas fa-pen-nib"></i>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-8 text-center no-conclusions-text font-weight-bold mb-2">
                      NO HAY CONCLUSIONES CARGADAS
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-8 text-center no-conclusions-text mb-2">
                      ¿Desea continuar sin agregar conclusiones?
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div className="d-flex justify-content-center w-100">
                      <button
                        className="col-md-3 btn-vpsy-outline exam-btn-txt my-3 mx-1 bg-white"
                        onClick={() => {
                          setCurrentSection(EXAM_SECTION.ATTACHMENTS);
                        }}>
                        Continuar sin agregar
                      </button>

                      <button
                        className="col-md-3 btn-vpsy-gradient exam-btn-txt my-3 mx-1"
                        onClick={addConclusion}>
                        Agregar conclusiones
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          {typeof shownConclusionIndex === 'number' ? (
            <ConclusionModal
              conclusion={conclusions[shownConclusionIndex] ?? null}
              onConclusionChange={onConclusionChange}
              deleteConclusion={deleteConclusion}
              index={shownConclusionIndex}
              show={!isNaN(shownConclusionIndex)}
              onHide={e => {
                setShownConclusionIndex(null);
              }}
              animation={false}
              editable={!viewMode}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default ExamConclusions;
