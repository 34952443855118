import React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import apiHelper from '../../../utils/apiHelpers';
import { withAlert } from 'react-alert';
import moment from 'moment';
import './PatientSummary.css';
import formatter from '../../../utils/formatter';

const PatientSummary = props => {
  const { patientId } = props;
  const [exams, setExams] = useState([]);
  const [patient, setPatient] = useState({
    name: 'nombre',
    lastName: 'apelido',
    birthDate: '1.1.1',
    email: 'asd@asd',
    phone: '1111111111',
    description: 'descri',
    age: {
      years: -1,
      months: -1,
    },
  });

  useEffect(() => {
    apiHelper
      .getPatient(patientId)
      .then(response => {
        let years = moment().diff(moment(response.data.birthDate), 'year');
        let months = moment()
          .subtract(years, 'years')
          .diff(moment(response.data.birthDate), 'months');
        setPatient({
          ...response.data,
          age: {
            years: years,
            months: months,
          },
        });
      })
      .catch(error => {
        console.log(error);
      });
  }, [patientId]);

  return (
    <>
      <div className="patient-summary w-100">
        <div className="w-100 patient-summary-topbar"></div>
        <div className="container w-100">
          <div className="row justify-content-between summary-top px-3">
            <div className="patient-name">
              <i className={`fas fa-user-circle patient-icon`} />
              {`${patient.firstName} ${patient.lastName}`}
            </div>
            <Link
              to={`${process.env.PUBLIC_URL}/patient/${patientId}`}
              className="pl-1">
              <span className="edit-patient">Editar datos</span>
            </Link>
          </div>
          <div className="row px-3 pt-3 patient-data">
            <div className="col-md-6">
              <div className="row">
                <label className="patient-label mb">Edad</label>
                <p className="w-100 px-4 mb-4">
                  {`${patient.age.years} años, ${patient.age.months} meses`}
                </p>
              </div>
              <div className="row">
                <label className="patient-label">Email</label>
                <p className="w-100 px-4 mb-4">{patient.email}</p>
              </div>
              <div className="row">
                <label className="patient-label">Teléfono</label>
                <p className="w-100 px-4 mb-4">
                  {formatter.formatPhoneNumber(patient.phone)}
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <label className="patient-label">Detalles</label>
                <p className="w-100 px-4  mb-4">
                  {patient.description || 'Sin notas'}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientSummary;
