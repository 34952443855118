import apiHelper from '../../utils/apiHelpers';
import Footer from '../common/Footer';
import React, { useContext } from 'react';
import './Help.css';
import { useState, useEffect } from 'react';
import { UserContext, useUserContext } from '../../contexts/UserContext';
import { Link } from 'react-router-dom';
import HelpCard from './HelpCard';
import { object } from 'prop-types';
import Modal from 'react-bootstrap/Modal';

const TAB_TITLES = ['Pacientes', 'Tests', 'Agenda', 'Perfil', 'Suscripción'];
export const HELP_DATA = {
  Pacientes: [
    {
      title: 'Ingresar nuevo paciente',
      description: 'Agregue pacientes antes de agendar turnos o evaluarlos.',
      ytID: 'V6buHPruuBs',
    },
    {
      title: 'Agregar consulta con paciente',
      description: 'Agende turnos en el calendario con o sin videollamadas.',
      ytID: 'IutOfR2egXA',
    },
  ],
  Evaluaciones: [
    {
      title: 'Cargar un examen',
      description: 'Evalue a un paciente aplicando múltiples tests.',
      ytID: '_1vQi3y-CTw',
    },
    {
      title: 'Exportar informe',
      description: 'Genere un informe con los resultados de los tests.',
      ytID: 'HU8I7JXgOSU',
    },
  ],
};

export const videoWidth = 700;
export const videoHeight = parseInt(videoWidth / (16 / 9));

const Help = props => {
  const user = useUserContext();
  const [currentTab, setCurrentTab] = useState(0);
  const [modalVideoID, setModalVideoID] = useState('');

  const showVideoModal = ytID => {
    setModalVideoID(ytID);
  };

  return (
    <>
      <Modal
        show={modalVideoID}
        animation={false}
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => {
          setModalVideoID('');
        }}
        className="flex-column"
        centered>
        <iframe
          width={videoWidth}
          height={videoHeight}
          src={'https://www.youtube.com/embed/{id}?autoplay=1&rel=0'.replace(
            '{id}',
            modalVideoID,
          )}
          title="YouTube video player"
          frameborder="0"
          allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
      </Modal>

      <div
        key={user?.id}
        className={'d-flex flex-column h-100 ' + props.className}>
        <div className="container my-3 ">
          <div className="row justify-content-between align-items-center">
            <h3 className="patient-title mt-4 mb-4">
              <i className="fas fa-chevron-left patient-title-arrow" />
              Ayuda
            </h3>
          </div>

          <div className="px-5 pb-5 pt-2 userForm">
            {/* Tabs */}
            <ul className="nav nav-tabs">
              {Object.keys(HELP_DATA).map((tabTitle, i) => {
                return (
                  <li className="nav-item" key={i}>
                    <a
                      className={`nav-link nav-font
                      ${currentTab == i ? ' active' : ''}`}
                      href="#"
                      onClick={() => {
                        setCurrentTab(i);
                      }}>
                      {tabTitle}
                    </a>
                  </li>
                );
              })}
            </ul>

            {/* Content */}
            <div className="p-3">
              {HELP_DATA[Object.keys(HELP_DATA)[currentTab]].map(
                (tabData, i) => {
                  return (
                    <HelpCard
                      cardData={tabData}
                      showVideoModal={showVideoModal}
                      className="mt-3"
                    />
                  );
                },
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Help;
