import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../common/Footer';
import apiHelper from '../../utils/apiHelpers';
import Pagination from '../common/Pagination';
import { withAlert } from 'react-alert';
import moment from 'moment';
import Exam from './Exam';
import './Exam.css';
import { Steps } from 'intro.js-react';
import ExamSideBar from './examSection/ExamSideBar';
import '../common/Steps.css';
import 'intro.js/introjs.css';
import { examsSteps } from './ExamSteps';
import { stepsStorage, STEPS_SECTIONS } from '../common/Steps';
import ExamPatient from './examSection/ExamPatient';
import ExamTests from './examSection/ExamTests';
import { ExamContext } from './ExamContext';
import ExamConclusions from './examSection/ExamConclusions';
import { EXAM_SECTION } from './ExamConstants';
import ExamAttachments from './examSection/ExamAttachments';
import ExamFinish from './examSection/ExamFinish';
import CollapsableCharts from './CollapsableCharts';
import ExportPDFBtn from './ExportPDFBtn';
import { generatePDFDocument } from './ExamPDF';
import SpinnerModal from '../common/SpinnerModal';

const STEPS_SECTION = STEPS_SECTIONS.EXAMS;

const ExamView = props => {
  const examId = props.match.params.id;
  const [exam, setExam] = useState({
    id: null,
    createdAt: '',
    conclusion: '',
    patient: {
      id: null,
      firstName: '',
      lastName: '',
    },
    results: [],
  });
  const [patient, setPatient] = useState(null);
  const [currentSection, setCurrentSection] = useState(0);
  const examContextValue = {
    exam,
    setExam,
    patient,
    setPatient,
    currentSection,
    setCurrentSection,
  };

  // Charts PNG data && PDF export
  const [triggerExport, setTriggerExport] = useState(false);
  const [graphsPngObj, setGraphsPngObj] = useState(null); // {..., graphId: Promise<blobString>, ...}
  const [pdfExportOptions, setPdfExportOptions] = useState({
    includeConclusions: true,
    includeCharts: false,
    includedParentTests: [],
  });
  // UseEffect: png render
  const pdfExportingFlag = useRef(null);
  useEffect(() => {
    if (pdfExportingFlag.current) return;
    if (triggerExport && !pdfExportOptions.includeCharts) {
      // No chart rendering
      pdfExportingFlag.current = setTimeout(function () {
        generatePDFDocument(
          pdfExportOptions,
          exam,
          graphsPngObj,
          setTriggerExport,
        );
        pdfExportingFlag.current = null;
      }, 1000);
    } else if (triggerExport && graphsPngObj !== null) {
      let pngPromisesLoaded = Object.keys(graphsPngObj).length !== 0;
      if (pngPromisesLoaded) {
        const promiseArrayPNG = [];
        for (const id in graphsPngObj) {
          promiseArrayPNG.push(graphsPngObj[id]);
        }
        setTimeout(function () {
          generatePDFDocument(
            pdfExportOptions,
            exam,
            graphsPngObj,
            setTriggerExport,
          );
          pdfExportingFlag.current = null;
        }, 1000);
      }
    }
  }, [triggerExport, graphsPngObj]);

  useEffect(() => {
    // if test already exists:
    if (examId) {
      apiHelper.getExam(examId).then(result => {
        setExam(result.data);
      });
    }
  }, [examId]);

  return (
    <>
      <SpinnerModal
        titlestring={'Exportando informe...'}
        show={triggerExport}
        animation={false}
        onHide={() => {}}
      />
      <ExamContext.Provider value={examContextValue}>
        <div className={'d-flex flex-column fill-height ' + props.className}>
          <div className="container my-3">
            <div className="row justify-content-between align-items-center">
              <h3 className="patient-title mt-4 mb-4">
                <Link
                  to={`${process.env.PUBLIC_URL}/patient/${exam.patient.id}/records`}
                  className="">
                  <i className="fas fa-chevron-left patient-title-arrow" />
                </Link>
                {`${exam.patient.firstName} ${exam.patient.lastName}`}
              </h3>
              <div className="row justify-content-between mr-2 flex-nowrap">
                <Link
                  to={`${process.env.PUBLIC_URL}/exam/${exam.id}`}
                  className="h-100 px-1 my-1">
                  <button type="button" className="btn btn-vpsy-outline">
                    <i className="fa fa-edit icon-hover mr-2 md-hide"></i>Editar
                  </button>
                </Link>
                <ExportPDFBtn
                  className="col-sm btn btn-vpsy-solid btn-dl-pdf mr-2 my-1 text-nowrap"
                  // disabled={exam.results.length <= 0 || patient.id < 0}
                  exam={exam}
                  setTriggerExport={setTriggerExport}
                  pdfExportOptions={pdfExportOptions}
                  setPdfExportOptions={setPdfExportOptions}
                />
              </div>
            </div>

            <div className="exam-card w-100">
              <div className="exam-card-topbar w-100" />
              <div className="container w-100 pb-5">
                <div className="row">
                  <div className="col-12">
                    <ExamTests viewMode />
                  </div>
                </div>
                {/* Graphs and Collapsibles */}
                {/* {exam.results.length &&
                exam.results.some(result => {
                  return (
                    result.studyType.parentTest.name === 'WISC_V' ||
                    result.studyType.parentTest.name === 'WAIS_IV'
                  );
                }) ? (
                  <div className="row">
                    <div className="col-12">
                      <CollapsableCharts
                        {...{
                          exam,
                          patientName: exam.patient.firstName,
                          graphsPngObj: graphsPngObj,
                          setGraphsPngObj: setGraphsPngObj,
                          triggerExport:
                            triggerExport && pdfExportOptions.includeCharts,
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )} */}

                {exam.conclusion || exam.testConclusion ? (
                  <div className="row">
                    <div className="col-12">
                      <ExamConclusions viewMode />
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                {exam.attachment ? (
                  <div className="row">
                    <div className="col-12">
                      <ExamAttachments viewMode />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </ExamContext.Provider>
    </>
  );
};

export default ExamView;
