import React, { useState, useEffect } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import apiHelpers from './apiHelpers';
import NavBarUX from '../components/common/NavBarUX';
import { UserContext } from '../contexts/UserContext';
import Loader from '../components/common/Loader';

const AccesPayedOnlyRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();
  const [stay, setStay] = useState(null);
  const [user, setUser] = useState(undefined);

  const getAllStates = () => {
    apiHelpers
      .getCurrentUser()
      .then(response => {
        setUser(response.data);
      })
      .catch(e => {
        console.log(e);
      });

    apiHelpers
      .refreshToken(history)
      .then(() => {
        !stay &&
          apiHelpers
            .getCurrentUserSubscription()
            .then(response => {
              if (
                response.data.status.toString() === 'authorized' ||
                response.data.status.toString() === 'freeTrial'
              ) {
                setStay(true);
              } else {
                setStay(false);
              }
            })
            .catch(error => {
              console.log(error);
              setStay(null);
            });
      })
      .catch(error => {
        console.log(error);
        apiHelpers.logout();
        history.push('/login');
      });
    if (!stay) {
      apiHelpers
        .getCurrentUserSubscription()
        .then(response => {
          if (
            response.data.status.toString() === 'authorized' ||
            response.data.status.toString() === 'freeTrial'
          ) {
            setStay(true);
          } else {
            setStay(false);
          }
        })
        .catch(error => {
          console.log(error);
          setStay(null);
        });
    }
  };

  useEffect(() => {
    getAllStates();
  }, []);

  if (!user || stay === null) return <Loader />;

  return (
    <>
      {stay ? (
        <Route
          {...rest}
          render={props => {
            return user.isEmailValid ? (
              <>
                <UserContext.Provider value={user}>
                  <NavBarUX />
                  <Component {...props} className="navbar-margin" />
                </UserContext.Provider>
              </>
            ) : (
              <UserContext.Provider value={user}>
                <Redirect to={`/validation?userId=${user.id}`} />
              </UserContext.Provider>
            );
          }}
        />
      ) : (
        <Redirect to="/landing-mercadopago" />
      )}
    </>
  );
};

export default AccesPayedOnlyRoute;
