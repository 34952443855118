import React from 'react';
import apiHelper from '../../utils/apiHelpers';
import './landingMercadoPago.css';
import Subscription from '../user/Subscription';
import { motion } from 'framer-motion';
import Register from '../landing/sections/Register';
import { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
const config = require('../../config/config');

var background = {
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const containerVariants = {
  hidden: {
    opacity: 0,
    y: '50vh',
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      stiffness: 10,
    },
  },
};

const LandingMercadoPago = props => {
  const { history } = props;
  const [subscriptionPlan, setSubscriptionPlan] = useState({
    auto_recurring: {
      frequency: null,
      frequency_type: null,
      transaction_amount: null,
      currency_id: null,
      billing_day: null,
      billing_day_proportional: null,
      transaction_amount_proportional: null,
    },
  });
  const [currentUser, setCurrentUser] = useState({
    first_name: '',
    last_name: '',
    username: '',
    role: {
      userRole: '',
    },
    password: '',
    roleId: '',
    email: '',
  });

  useEffect(() => {
    apiHelper
      .getCurrentUser()
      .then(response => {
        setCurrentUser(response.data);
      })
      .catch(e => {
        console.error(e);
      });
    apiHelper
      .getSubscriptionPlan()
      .then(response => {
        setSubscriptionPlan(response.data);
      })
      .catch(e => {
        console.error(e);
      });
  }, []);

  const fireCheckMercadopagoEmail = () => {
    const styledSwal = Swal.mixin({
      showCancelButton: false,
      showDenyButton: true,
      confirmButtonColor: '#22654e',
      customClass: {
        cancelButton: 'swal-cancel-btn', // Add your custom class for the cancel button
        denyButton: 'swal-cancel-btn',
      },
      allowOutsideClick: true,
      allowEscapeKey: true,
    });

    if (currentUser.mpEmail) {
      styledSwal
        .fire({
          title: `¿Es tu email de MercadoPago correcto?`,
          html: `El email de <b>MercadoPago</b> configurado es <br/> <b>${currentUser.mpEmail}</b> <br/> De esa cuenta se hará la suscripción.`,
          icon: 'warning',
          confirmButtonText: 'Continuar',
          denyButtonText: 'Editar',
        })
        .then(async result => {
          if (result.isConfirmed) {
            window.open(subscriptionPlan.init_point, '_blank');
          } else if (result.isDenied) {
            history.push('/editProfile');
          }
        })
        .catch(e => {
          console.log(e);
        });
    } else {
      // NO MP EMAIL SET
      styledSwal
        .fire({
          title: `Configure el email de MercadoPago`,
          html: `Ingrese el email de MercadoPago desde donde efectuarás el pago.`,
          icon: 'warning',
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: 'Editar perfil',
          cancelButtonText: `Cancelar`,
        })
        .then(async result => {
          if (result.isConfirmed) {
            history.push('/editProfile');
          }
        })
        .catch(e => {
          console.log(e);
        });
    }
  };

  return (
    <div className={' ' + props.className}>
      <div style={background} className="mt-5 container">
        <div className="row info">
          <div className="h2 col-12 text-center">
            Se parte hoy de{' '}
            <img
              className="logo-vpsy logo-vpsy-mp"
              src={process.env.PUBLIC_URL + '/img/landingLogo.png'}
              alt="logo"
            />
          </div>
        </div>

        <div className="row info justify-content-center">
          {/* <div className="mp-text card cardStyles p-4  infoText subs  col-lg-6 col-md-6 col-sm-12 col-sx-12"> */}
          <div className="text-center mp-text card cardStyles p-4 infoText subs col-lg-10 col-md-10 col-sm-12 col-sx-12">
            <div className="h3 mb-2">
              <strong>FINALIZÓ EL PERIODO DE PRUEBA. </strong>
            </div>
            <div className="h5 mb-2">
              Sumate hoy a nuestra plataforma <strong>Mentaly</strong> por tan
              solo
              <strong>
                {' '}
                {subscriptionPlan.auto_recurring.transaction_amount}{' '}
                {subscriptionPlan.auto_recurring.currency_id}{' '}
              </strong>{' '}
              por mes abonando con cualquier medio de pago disponible en{' '}
              <strong>MercadoPago</strong>.
              <br />
              <br />
              Tendrás la opción de desuscribirte en cualquier momento desde el
              perfil de tu usuario.
              <br />
            </div>
            <div className="smallInfo">
              *Configurá en la página de perfil tu email de MercadoPago para que
              podamos verificar la procedencia del pago. Lo podés hacer antes o
              después de haberte suscripto.
            </div>
            <div className="row justify-content-center">
              <div className="d-flex justify-content-center w-100">
                <button
                  onClick={fireCheckMercadopagoEmail}
                  className="w-50 btn btn-lg-4 btn-vpsy-solid btn-block btn-login-font fadeIn fourth mt-4"
                  type="button">
                  Suscribite
                </button>
              </div>
            </div>
          </div>

          <div className="redes">
            <img
              className="mercadoPago"
              src={process.env.PUBLIC_URL + '/img/mercadopago-logo.png'}
              alt="logo"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default LandingMercadoPago;
