import React, { useState, useEffect } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import apiHelpers from '../utils/apiHelpers';
import NavBarUX from '../components/common/NavBarUX';
import { UserContext } from '../contexts/UserContext';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();
  const [isJWTValid, setIsJWTValid] = useState(null);
  const [user, setUser] = useState(undefined);

  useEffect(() => {
    if (isJWTValid === null) {
      getTokenStatus()
        .then(res => {
          setIsJWTValid(res);
        })
        .catch(e => {
          history.push('/login');
        });
    }
    apiHelpers
      .getCurrentUser()
      .then(response => {
        setUser(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }, []);

  const getTokenStatus = async () => {
    return await apiHelpers.isAuthenticated();
  };

  if (isJWTValid === null) {
    return <></>;
  } else {
    return (
      <Route
        {...rest}
        render={props =>
          isJWTValid === true ? (
            <>
              <UserContext.Provider value={user}>
                <NavBarUX />
                <Component {...props} className="navbar-margin" />
              </UserContext.Provider>
            </>
          ) : (
            <Redirect to="/login" />
          )
        }
      />
    );
  }
};

export default PrivateRoute;
