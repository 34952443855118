import apiHelper from '../../utils/apiHelpers';
import Footer from '../common/Footer';
import React from 'react';
import formatter from '../../utils/formatter';
import SelectUserRole from '../common/selects/SelectUserRole';
import Subscription from './Subscription';
import './User.css';
import Loader from '../common/Loader';
import { useState, useEffect } from 'react';

const ChangeMPEmail = props => {
  const [alertError, setAlertError] = useState(null);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [mpEmail, setMpEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [user, setUser] = useState(null);

  const handleSubmit = async e => {
    if (!password || !mpEmail) {
      setAlertError('Error: Complete todos los campos.');
    } else {
      apiHelper
        .patchMPEmail({
          password,
          newMPEmail: mpEmail,
        })
        .then(response => {
          setAlertError(null);
          setAlertSuccess(true);
        })
        .catch(error => {
          console.log(error);
          if (error.response.status == 401) {
            setAlertError('Error: La contraseña es incorrecta.');
          }
        });
    }
  };

  const handleEmailChange = e => {
    const target = e.target;
    let value = formatter.formatFromTarget(target);
    setMpEmail(value);
  };

  const handlePasswordChange = e => {
    const target = e.target;
    let value = formatter.formatFromTarget(target);
    setPassword(value);
  };

  useEffect(() => {
    apiHelper
      .getCurrentUser()
      .then(response => {
        setUser(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    if (alertError) {
      setAlertError(null);
    }
  }, [password]);

  // Alert signs
  let alert;
  if (alertError) {
    alert = (
      <div className="alert alert-warning ml-5 mr-5 text-center">
        {alertError}
      </div>
    );
  }

  if (alertSuccess) {
    alert = (
      <div className="alert alert-success mt-3 ml-5 mr-5 text-center">
        Email de MercadoPago ha sido cambiado.
      </div>
    );
  }

  return (
    <div className="d-flex flex-column h-100">
      <div className="container-fluid mt-3 ">
        <div className="row container-fluid p-3">
          <div className="col-md-9  p-3 m-auto userForm ">
            <h4 className="text-center title">
              Cambio de Email de MercadoPago
            </h4>

            <div className="form-group">
              <div className="form-group">
                <label htmlFor="firstName">Nuevo Email de MercadoPago</label>
                <input
                  type="email"
                  onChange={handleEmailChange}
                  name="mpEmail"
                  className="form-control"
                  placeholder={user ? user.mpEmail : 'Email de MercadoPago'}
                  value={mpEmail}
                />
              </div>

              <div className="form-group">
                <label htmlFor="firstName">Contraseña actual</label>
                <input
                  type="password"
                  onChange={handlePasswordChange}
                  name="password"
                  className="form-control"
                  placeholder="Contraseña actual"
                  value={password}
                />
              </div>
            </div>

            {/* ALERT SIGN */}
            {alert}

            {/* Button submit */}
            <button
              disabled={alertSuccess}
              type="button"
              className="btn btn-outline-danger btn-block"
              onClick={handleSubmit}>
              Guardar Email (MercadoPago)
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ChangeMPEmail;
