import React from 'react';
import './RecorverPassword.css';
import apiHelper from '../../utils/apiHelpers';
import formatter from '../../utils/formatter';
import { Link } from 'react-router-dom';

const LOGIN_IMG_PATH = process.env.PUBLIC_URL + '/img/login-side-img.png';

class RecoverPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      email: '',
      errorMessage: null,
      successMessage: null,
    };
  }

  handleChange(event) {
    const target = event.target;
    const value = formatter.formatFromTarget(target);
    const name = target.name;

    this.setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }

  async handleSubmit(event) {
    event.preventDefault();

    try {
      let res = await apiHelper.resetPassword({
        email: this.state.email,
      });
      //this.props.history.push('/login');
      if (res.status == 200) {
        this.setState({
          successMessage:
            'Se ha enviado su nueva contraseña al correo electrónico.',
        });
      }
    } catch (error) {
      const statusCode = error.response.status;
      let errorMessage;

      if (statusCode === 401) {
        errorMessage = 'Email no se encuentra registrado.';
      } else {
        errorMessage = error.response.data.error;
      }

      this.setState({
        errorMessage: errorMessage,
      });
    }
  }

  render() {
    let alert;

    if (this.state.errorMessage) {
      alert = (
        <div className="alert alert-warning">{this.state.errorMessage}</div>
      );
    }
    if (this.state.successMessage) {
      alert = (
        <div className="alert alert-success">{this.state.successMessage}</div>
      );
    }

    return (
      <div id="login-container" className="container justify-content-center">
        <div className="row h-100">
          <div className="col-7 d-flex justify-content-md-center align-self-center">
            <form
              className="form-signin"
              onSubmit={this.handleSubmit.bind(this)}>
              <div className="fadeIn first mt-2">
                <img
                  className="logo logo-login"
                  src={process.env.PUBLIC_URL + '/img/LOGO VENT-07.png'}
                  alt="logo"
                />
              </div>
              <div className="fadeIn first">
                <div className="forgot-pass-font">
                  ¿Olvidaste tu contraseña?
                </div>
                <p className="p-recover ml-2 mb-3">
                  Ingresa tu direccion de Email para recuperarla
                </p>
                <div className="panel-body">
                  <div className="form-group">
                    <label htmlFor="username" className="label-vpsy-font">
                      <i className="fas fa-envelope" /> Correo electrónico
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="form-control fadeIn second"
                      placeholder="Email"
                      value={this.state.email}
                      onChange={this.handleChange.bind(this)}
                      required
                      autoFocus
                    />
                  </div>

                  {alert}

                  <button
                    className="btn btn-vpsy-gradient btn-block p-3"
                    type="submit">
                    Recuperar contraseña
                  </button>
                </div>

                <Link to="/login" className="back-recover">
                  Volver al Login
                </Link>
              </div>
            </form>
          </div>

          <div id="login-side-img" className="col">
            <div className="login-img-overlay"></div>
            <img src={LOGIN_IMG_PATH} />
          </div>
        </div>
      </div>
    );
  }
}
export default RecoverPassword;
