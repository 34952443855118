import styled, { css } from 'styled-components';

const colors = {
  border: '#0075FF',
  error: 'bb2929',
  exito: '1ed12d',
};

const Label = styled.label`
  display: block;
  font-weight: 700;
  padding: 10px;
  min-height: 40px;
  cursor: pointer;
`;

const GroupInput = styled.div`
  position: relative;
  z-index: 80;
`;

const Input = styled.input`
  width: 100%;
  background: #fff;
  border-radius: 3px;
  height: 45px;
  line-height: 45px;
  padding: 0 40px 0 10px;
  transition: 0.3s ease all;
  border: 2px solid transparent;

  &:focus {
    border: 2px solid ${colors.border};
    outline: none;
    box-shadow: 3px 0px 30px rgba(163, 163, 163, 0.4);
  }

  ${props =>
    props.isValid === 'true' &&
    css`
      border: 2px solid transparent;
    `}
  ${props =>
    props.isValid === 'false' &&
    css`
      border: 2px solid red !important;
    `}
`;
const leyendError = styled.p`
  font-size: 12px;
  margin-bottom: 0;
  color: ${colors.error};
  display: none;
`;

const Icons = styled.i`
  position: absolute;
  right: 10px;
  bottom: 5px;
  z-index: 120;
  font-size: 16px;
  opacity: 0
    ${props =>
      props.isValid === 'true' &&
      css`
        opacity: 1 !important;
        color: green;
      `}
    ${props =>
      props.isValid === 'false' &&
      css`
        opacity: 1 !important;
        bottom: 27px;
        color: red;
      `};
`;

const ErrorMsg = styled.div`
  height: 15px;
  line-height: 5px;
  color: #f66060;
  padding: 0px;
  border-radius: 3px;
  margin: 0;
  display: none;

  ${props =>
    props.isValid === 'true' &&
    css`
      display: none;
    `}
  ${props =>
    props.isValid === 'false' &&
    css`
      display: block;
    `}
`;

export { GroupInput, Input, Icons, ErrorMsg, leyendError };
