import React, { useContext } from 'react';
import './NavBarUX.css';
import { Link } from 'react-router-dom';
import apiHelper from '../../utils/apiHelpers';
import { useState, useEffect } from 'react';
import SearchPatient from './selects/SearchPatient';
import ProfilePicture from '../user/ProfilePicture';
import { useUserContext } from '../../contexts/UserContext';

const searchBarEnabledRoutes = [
  'patient',
  'appointment',
  'editProfile',
  'exam',
];

const NavBarUX = () => {
  const pathname = window.location.pathname;
  const user = useUserContext();
  const [collapsed, setCollapsed] = useState(true);

  const onClickExpand = () => {
    if (collapsed) {
      setCollapsed(false);
    } else {
      setCollapsed(true);
    }
  };

  const clickCollapse = () => {
    if (collapsed === false) {
      setCollapsed(true);
    }
  };

  return (
    <>
      {/* TOPBAR */}
      <div className={`topBar ${!collapsed ? 'active' : ''}`}>
        <div className={collapsed ? 'collapsed' : 'noncol'}>
          <button
            type="button"
            collapsed={collapsed.toString()}
            className={`btn ${!collapsed ? 'activeButton' : ''} mx-3 my-2`}
            onClick={onClickExpand}>
            <i
              className={`fas ${
                collapsed ? 'fa-align-left' : 'fa-align-right'
              } `}
            />
          </button>
        </div>
        {searchBarEnabledRoutes.some(route => pathname.includes(route)) && (
          <SearchPatient
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderRadius: '50px',
              }),
              container: (baseStyles, state) => ({
                ...baseStyles,
                width: '50%',
                textWrap: 'nowrap',
              }),
            }}
            placeholder={` Buscar paciente...`}
          />
        )}
        <div className="topBar-profile-box ml-auto mr-3 d-flex flex-row">
          <div className="d-flex mx-2">
            <ProfilePicture />
          </div>
          <div className="d-flex flex-column justify-content-center">
            <div className="topBar-username">{`${user?.firstName} ${user?.lastName}`}</div>
            <Link
              to={`${process.env.PUBLIC_URL}/profile`}
              className="topBar-edit-profile">
              editar mi perfil
            </Link>
          </div>
        </div>
      </div>

      {/* SIDEBAR */}
      <div className={`sidebar pl-3 ${!collapsed ? 'active' : ''}`}>
        <div className={collapsed ? 'collapsed' : 'noncol'}>
          <button
            type="button"
            collapsed={collapsed.toString()}
            className={`btn ${!collapsed ? 'activeButton' : ''}`}
            onClick={onClickExpand}>
            <img
              src={`${process.env.PUBLIC_URL + '/img/mentaly-06.png'}`}
              hidden={!collapsed}
              className="navLogo"
              alt="Mentaly logo"
            />
            <img
              src={`${process.env.PUBLIC_URL + '/img/landingLogo.png'}`}
              hidden={collapsed}
              className="navLogo"
              alt="Mentaly logo"
            />
          </button>
        </div>

        {/* NAVBAR ITEMS */}
        <ul className="nav-list">
          <li onClick={clickCollapse} className="d-inline-block">
            <Link
              to={`${process.env.PUBLIC_URL}/appointment`}
              className={pathname.includes('appointment') ? 'active-font' : ''}>
              <i
                id="navItem"
                className={`fas fa-calendar fa-sm fa-fw mr-2  ${
                  pathname.includes('appointment') ? 'active-icon' : ''
                }`}></i>{' '}
              <span hidden={collapsed}>Agenda</span>
              <span hidden={!collapsed} className="tooltip">
                Agenda
              </span>
            </Link>
            <div
              className={`itemIndicator ${
                pathname.includes('appointment') ? 'activeItem' : ''
              }`}></div>
          </li>

          <li
            onClick={clickCollapse}
            className={pathname.includes('exam') ? 'activeItem' : ''}>
            <Link to={`${process.env.PUBLIC_URL}/exam`}>
              <i
                id="navItem"
                className={`fas fa-file-signature fa-sm fa-fw mr-2  ${
                  pathname.includes('exam') ? 'active-icon' : ''
                }`}></i>{' '}
              <span hidden={collapsed}>Crear nuevo examen</span>
              <span hidden={!collapsed} className="tooltip text-nowrap">
                Crear nuevo examen
              </span>
            </Link>
            <div
              className={`itemIndicator ${
                pathname.includes('exam') ? 'activeItem' : ''
              }`}></div>
          </li>

          <li
            onClick={clickCollapse}
            className={pathname.includes('patient') ? 'activeItem' : ''}>
            <Link to={`${process.env.PUBLIC_URL}/patient`}>
              <i
                id="navItem"
                className={`fas fa-users fa-sm fa-fw mr-2  ${
                  pathname.includes('patient') ? 'active-icon' : ''
                }`}></i>{' '}
              <span hidden={collapsed}>Pacientes</span>
              <span hidden={!collapsed} className="tooltip">
                Pacientes
              </span>
            </Link>
            <div
              className={`itemIndicator ${
                pathname.includes('patient') ? 'activeItem' : ''
              }`}></div>
          </li>

          <li
            onClick={clickCollapse}
            className={pathname.includes('profile') ? 'activeItem' : ''}>
            <Link to={`${process.env.PUBLIC_URL}/profile`}>
              <i
                id="navItem"
                className={`fas fa-user-circle fa-sm fa-fw mr-2  ${
                  pathname.includes('profile') ? 'active-icon' : ''
                }`}></i>
              <span hidden={collapsed}>Perfil</span>
              <span hidden={!collapsed} className="tooltip">
                Perfil
              </span>
            </Link>
            <div
              className={`itemIndicator ${
                pathname.includes('profile') ? 'activeItem' : ''
              }`}></div>
          </li>

          <li
            onClick={clickCollapse}
            className={pathname.includes('help') ? 'activeItem' : ''}>
            <Link to={`${process.env.PUBLIC_URL}/help`}>
              <i
                id="navItem"
                className={`fab fa-youtube fa-sm fa-fw mr-2  ${
                  pathname.includes('help') ? 'active-icon' : ''
                }`}></i>
              <span hidden={collapsed}>Ayuda</span>
              <span hidden={!collapsed} className="tooltip">
                Ayuda
              </span>
            </Link>
            <div
              className={`itemIndicator ${
                pathname.includes('help') ? 'activeItem' : ''
              }`}></div>
          </li>
          <li
            onClick={clickCollapse}
            className={pathname.includes('suggestions') ? 'activeItem' : ''}>
            <Link to={`${process.env.PUBLIC_URL}/suggestions`}>
              <i
                id="navItem"
                className={`fas fa-comment-dots fa-sm fa-fw mr-2  ${
                  pathname.includes('suggestions') ? 'active-icon' : ''
                }`}></i>
              <span hidden={collapsed}>Sugerencias Opiniones</span>
              <span hidden={!collapsed} className="tooltip">
                Sugerencias Opiniones
              </span>
            </Link>
            <div
              className={`itemIndicator ${
                pathname.includes('suggestions') ? 'activeItem' : ''
              }`}></div>
          </li>

          <hr />

          <li onClick={apiHelper.logout}>
            <Link to="/login" onClick={apiHelper.logout}>
              <i
                id="navItem"
                className="fas fa-sign-out-alt fa-sm fa-fw mr-2 "></i>{' '}
              <span hidden={collapsed}>Cerrar Sesión</span>
              <span hidden={!collapsed} className="tooltip">
                Cerrar Sesión
              </span>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default NavBarUX;
