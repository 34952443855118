import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import './../Landing';

const containerVariants = {
  hidden: {
    opacity: 0,
    y: '50vh',
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      stiffness: 10,
    },
  },
};

let fondo = 'img/fondo.png';

let background = {
  //backgroundImage: `url(${fondo})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const About = () => {
  return (
    <div style={background} className="mb-5">
      <div>
        <h1 className="text-center about">
          Sobre{' '}
          <img
            className="logo-vpsy"
            src={process.env.PUBLIC_URL + '/img/landingLogo.png'}
            alt="logo"
          />
        </h1>
        <div className="row about-info">
          <div className="card cardStyles infoText p-5 subs col-lg-5 col-md-12 col-sm-12 col-sx-12">
            <p>
              <strong>Mentaly</strong> es un sistema online para profesionales
              de la psicología que permite llevar{' '}
              <strong>registro e historia clínica</strong> de pacientes, agenda
              y acceso a video consultas. Asimismo, el sistema permite
              actualizar los resultados de diversos exámenes psicológicos, y{' '}
              <strong>calcular automáticamente</strong> el percentil asociado en
              base a la edad del paciente y demás variables relacionadas al
              mismo.
            </p>
          </div>
          <div className="images col-lg-5 col-md-12 col-sm-12 col-sx-12">
            <img
              className="imagen1"
              src={process.env.PUBLIC_URL + '/img/uno.png'}
              alt="imagen sitema"
            />
            <img
              className="imagen2"
              src={process.env.PUBLIC_URL + '/img/tres.png'}
              alt="imagen sitema"
            />
            <img
              className="imagen3"
              src={process.env.PUBLIC_URL + '/img/cuatro.png'}
              alt="imagen sitema"
            />
          </div>
        </div>
        {/* <motion.h2
            className="arrow"
            initial={{ y: -5 }}
            animate={{
              y: 5,
              transition: {
                duration: 1,
                ease: 'easeIn',
                repeat: Infinity,
                repeatType: 'reverse',
              },
            }}>
            {' '}
            <Link className="linkto" to="/advantages">
              <i className="fas fa-angle-double-down"></i>
            </Link>
          </motion.h2> */}
      </div>
    </div>
  );
};

export default About;
