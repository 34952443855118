import apiHelper from '../../utils/apiHelpers';
import Footer from '../common/Footer';
import React from 'react';
import formatter from '../../utils/formatter';
import SelectUserRole from '../common/selects/SelectUserRole';
import Subscription from './Subscription';
import './User.css';
import Loader from '../common/Loader';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const ChangePassword = props => {
  const [alertError, setAlertError] = useState(null);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [password, setPassword] = useState({
    oldPassword: null,
    newPassword: null,
    newPasswordRep: null,
  });

  const handleSubmit = async e => {
    if (
      !password.oldPassword ||
      !password.newPassword ||
      !password.newPasswordRep
    ) {
      setAlertError('Error: Complete todos los campos.');
    } else {
      apiHelper
        .changePassword(password)
        .then(response => {
          setAlertError(null);
          setAlertSuccess(true);
        })
        .catch(error => {
          console.log(error);
          if (error.response.status == 401) {
            setAlertError('Error: La contraseña actual es incorrecta.');
          }
        });
    }
  };

  const handleChange = e => {
    const target = e.target;
    let value = formatter.formatFromTarget(target);
    const name = target.name;
    setPassword({
      ...password,
      [name]: value,
    });
  };

  useEffect(() => {
    if (password.newPassword) {
      if (password.newPassword.length < 6) {
        setAlertError('La contraseña debe tener por lo menos 6 caracteres.');
        setAlertSuccess(false);
      } else if (password.newPassword != password.newPasswordRep) {
        setAlertError('Las contraseñas no coinciden.');
        setAlertSuccess(false);
      } else if (alertError) {
        setAlertError(null);
      }
    }
  }, [password]);

  // Alert signs
  let alert;
  if (alertError) {
    alert = (
      <div className="alert alert-warning ml-5 mr-5 text-center">
        {alertError}
      </div>
    );
  }

  if (alertSuccess) {
    alert = (
      <div className="alert alert-success mt-3 ml-5 mr-5 text-center">
        La contraseña ha sido cambiada.
      </div>
    );
  }

  return (
    <div className={'d-flex flex-column h-100 fill-height ' + props.className}>
      <div className="container mt-3 ">
        <div className="px-5 py-4 w-50 m-auto userForm ">
          <h4 className="text-center vpsy-font vpsy-font-color my-4">
            Cambiar de contraseña
          </h4>

          <div className="form-group">
            <label htmlFor="firstName">Contraseña actual</label>
            <input
              type="password"
              onChange={handleChange}
              name="oldPassword"
              className="form-control"
              placeholder="Contraseña actual"
              value={password.oldPassword}
            />
          </div>

          <div className="form-group">
            <label htmlFor="firstName">Nueva contraseña</label>
            <input
              type="password"
              onChange={handleChange}
              name="newPassword"
              className="form-control"
              placeholder="Nueva contraseña"
              value={password.newPassword}
            />
          </div>

          <div className="form-group mb-5">
            <input
              type="password"
              onChange={handleChange}
              name="newPasswordRep"
              className="form-control"
              placeholder="Repita nueva contraseña"
              value={password.newPasswordRep}
            />
          </div>

          {/* ALERT SIGN */}
          {alert}

          {/* Button submit */}
          <div className="row justify-content-md-center my-3">
            <div className="col mx-2">
              <Link to="/profile">
                <button
                  className="btn btn-vpsy-outline btn-block fadeIn fourth"
                  formAction="index.html"
                  type="submit">
                  Cancelar
                </button>
              </Link>
            </div>

            <div className="col mx-2">
              <button
                id="sign-up"
                className="btn btn-vpsy-solid btn-block mr-4 fadeIn fourth"
                onClick={handleSubmit}
                type="button"
                disabled={alertSuccess}>
                Confirmar
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ChangePassword;
