import apiHelper from '../../utils/apiHelpers';
import Footer from '../common/Footer';
import React from 'react';
import SelectDates from '../common/selects/SelectDates';
import formatter from '../../utils/formatter';
import './AppointmentDetail.css';
import LoadingOverlay from 'react-loading-overlay';
import { withAlert } from 'react-alert';
import SelectPatient from '../common/selects/SelectPatient';
import { useState, useEffect } from 'react';
import Loader from './../common/Loader';
import { Steps } from 'intro.js-react';
import { appointmentDetailSteps } from './AppointmentSteps';
import '../common/Steps.css';
import 'intro.js/introjs.css';
import { stepsStorage, STEPS_SECTIONS } from '../common/Steps';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';

const STEPS_SECTION = STEPS_SECTIONS.APPOINTMENT_DETAIL; // onboarding section ID

const AppointmentDetail = props => {
  const [isNew, setIsNew] = useState(props.match.params.id === 'new');
  const [error, setError] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [appointment, setAppointment] = useState({
    id: props.match.params.id,
    appointmentDate: '',
    startTime: '',
    endTime: '',
    description: '',
    meetingUrl: '',
    patient: {
      firstName: '',
      lastName: '',
    },
    includeVideoCall: true,
  });
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    if (!isNew) {
      apiHelper
        .getAppointment(props.match.params.id)
        .then(appointmentResponse => {
          setAppointment(appointmentResponse.data);
        })
        .catch(e => {
          console.log(e);
        });
    }
    if (currentUser === null) {
      apiHelper
        .getCurrentUser()
        .then(currentUserResponse => {
          setCurrentUser(currentUserResponse.data);
        })
        .catch(e => {
          console.log(e);
        });
    }
  }, []);

  const deleteAppointment = async () => {
    Swal.fire({
      title: `¿Desea eliminar el turno?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#22654e',
      customClass: {
        cancelButton: 'swal-cancel-btn', // Add your custom class for the cancel button
      },
      confirmButtonText: 'Si, eliminar.',
    }).then(async result => {
      if (result.isConfirmed) {
        setFetching(true);
        await apiHelper.deleteAppointment(appointment.id);
        setFetching(false);
        props.history.push('/appointment');
        Swal.fire({
          title: 'Turno eliminado',
          icon: 'success',
          confirmButtonColor: '#22654e',
          confirmButtonText: 'Continuar',
        });
      }
    });
  };

  const sendAppointmentReminder = async () => {
    setFetching(true);
    await apiHelper.getAppointmentReminder(appointment.id);
    setFetching(false);
    Swal.fire({
      title: 'Email de recordatorio enviado',
      icon: 'success',
      confirmButtonColor: '#22654e',
      confirmButtonText: 'Continuar',
    });
  };

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    let newappointment = { ...appointment, [name]: value };
    setAppointment(newappointment);
  };

  const startMeeting = () => {
    var win = window.open(
      `${appointment.meetingUrl}?jwt=${appointment.meetingJwt}`,
      '_blank',
    );
    win.focus();
  };

  const validateTime = () => {
    const numberStartTime = parseInt(appointment.startTime.replace(':', ''));
    const numberEndTime = parseInt(appointment.endTime.replace(':', ''));
    if (!numberStartTime || !numberEndTime) return false;
    if (numberStartTime >= numberEndTime) return false;
    return true;
  };

  const submitAppointment = async event => {
    event.preventDefault();

    if (!validateTime()) {
      setError('Los horarios no son válidos.');
      return;
    }

    let response;
    Swal.fire({
      title: `¿El turno será virtual o presencial?`,
      icon: 'warning',
      showCancelButton: false,
      showDenyButton: true,
      confirmButtonColor: '#22654e',
      customClass: {
        cancelButton: 'swal-cancel-btn', // Add your custom class for the cancel button
        denyButton: 'swal-cancel-btn',
      },
      confirmButtonText: 'Virtual',
      denyButtonText: `Presencial`,
      allowOutsideClick: false,
      allowEscapeKey: false,
    })
      .then(async result => {
        let includeVideoCall = true;
        if (result.isConfirmed) {
          try {
            includeVideoCall = true;
          } catch (e) {
            console.log(e);
          }
        }
        if (result.isDenied) {
          // face to face
          includeVideoCall = false;
        }

        setFetching(true);
        if (isNew) {
          response = await apiHelper.postAppointment({
            ...appointment,
            includeVideoCall,
          });
        } else {
          response = await apiHelper.patchAppointment(appointment.id, {
            ...appointment,
            includeVideoCall,
          });
        }
        setFetching(false);

        let alrtMsg = '';
        if (response && response.data && response.data.sent_mails) {
          alrtMsg = 'Se enviaron ' + response.data.sent_mails + ' emails';
        }
        Swal.fire({
          title: `Evento ${isNew ? 'creado' : 'editado'} con éxito`,
          text: alrtMsg,
          icon: 'success',
          confirmButtonColor: '#22654e',
        }).then(() => {
          props.history.push('/appointment');
        });
      })
      .catch(error => {
        console.log(error);
        setFetching(false);
        const statusCode = error.response.status;
        let errorMessage;
        if (statusCode === 422) {
          errorMessage = 'Datos invalidos o incompletos';
        } else if (statusCode === 403) {
          // FreeTrial limit
          errorMessage = 'Límite de la prueba gratuita excedido.';
        } else {
          errorMessage = error.response.data;
        }
        setError(errorMessage);
      });
  };

  // OnBoarding
  const onExit = () => {
    stepsStorage.setDone(STEPS_SECTION);
  };

  /*componentWillUnmount() {
        //this._isMounted = false;
        this.deleteAppointment.bind(this);
        this.submitAppointment.bind(this);
    }*/

  let title = 'Crear evento';
  let deleteButton = false;
  let linkRow = false;
  let disabled = false;
  let save = false;
  let callDuration = null;
  let alert = null;

  if (!isNew) {
    title = appointment.patient.firstName + ' ' + appointment.patient.lastName;

    linkRow = (
      <div className="row justify-content-between align-items-end mb-3">
        <div className="form-group col-lg-7 col-md-7 col-sm-12 col-sx-12">
          <label htmlFor="join_url"> Link de llamada </label>
          <input
            type="text"
            name="join_url"
            id="join_url"
            className="form-control w-100"
            placeholder=""
            value={appointment.meetingUrl || 'Turno presencial'}
            onChange={handleChange}
            onClick={() => {
              navigator.clipboard.writeText(appointment.meetingUrl);
              props.alert.success('Link copiado con éxito');
            }}
            readonly
          />
        </div>
        <div className="row col-lg-5 col-md-5 col-sm-12 col-sx-12">
          <button
            className="btn btn-vpsy-outline btn-appointment-link col-lg-6 col-md-5 col-sm-12 col-sx-12 mx-3"
            onClick={sendAppointmentReminder}
            type="submit">
            <i className={`fas fa-envelope mx-2`} />
            Enviar recordatorio
          </button>
          {appointment.meetingUrl && (
            <button
              type="button"
              className="btn btn-vpsy-gradient btn-appointment-link col-lg-5 col-md-5 col-sm-12 col-sx-12"
              onClick={startMeeting}>
              <i className={`fas fa-play-circle mx-2`} />
              Iniciar reunión
            </button>
          )}
        </div>
      </div>
    );

    deleteButton = (
      <button
        className="btn button-delete btn-vpsy-outline pull-right"
        type="button"
        onClick={deleteAppointment}
        disabled={fetching}
        title="Eliminar">
        <i className="fa fa-trash-alt"></i>
      </button>
    );
    save = (
      <>
        <div className="row justify-content-center">
          <Link
            to={`${process.env.PUBLIC_URL}/appointment`}
            className="col-md-5 btn-lg btn-vpsy-outline my-3 mx-1">
            <button className="" type="button">
              Cancelar
            </button>
          </Link>

          <button
            className="col-md-5 btn-lg btn-vpsy-gradient my-3 mx-1"
            type="submit">
            Guardar
          </button>
        </div>
      </>
    );
  } else {
    save = (
      <>
        <div className="row justify-content-center">
          <Link
            to={`${process.env.PUBLIC_URL}/appointment`}
            className="col-md-5 btn-lg btn-vpsy-outline my-3 mx-1">
            <button className="" type="button">
              Cancelar
            </button>
          </Link>

          <button
            className="col-md-5 btn-lg btn-vpsy-gradient my-3 mx-1"
            type="submit">
            Guardar
          </button>
        </div>
      </>
    );
  }

  if (
    parseInt(appointment.scheduled_end_time) -
      parseInt(appointment.scheduled_start_time) >=
    0
  ) {
    callDuration = (
      <p>
        {formatter.calculateTimeDifference(
          appointment.scheduled_start_time,
          appointment.scheduled_end_time,
        )}
      </p>
    );
  }

  if (error) {
    alert = (
      <div className="appointment-alert-text appointment-alert p-3 mt-3">
        {error}
      </div>
    );
  }

  return (
    <>
      {/* <Steps
        enabled={!stepsStorage.isDone(STEPS_SECTION)}
        steps={appointmentDetailSteps.steps}
        initialStep={appointmentDetailSteps.initialStep}
        options={{
          nextLabel: 'Siguiente',
          prevLabel: 'Anterior',
          skipLabel: 'Saltar',
          doneLabel: 'Finalizar',
          showStepNumbers: true,
        }}
        onExit={onExit}
      /> */}
      {/* NEW PATIENT BANNER */}
      <div className={'container mt-3 '}>
        <div className="row">
          <div className="col">
            <div className="add-patient-banner mt-2">
              {/* banner content */}
              <div className="container h-100">
                <div className="row h-100 align-items-center px-3">
                  <div className="col-1 mx-3 add-patient-banner-icon">
                    <i className="fas fa-user-plus"></i>
                  </div>
                  <div className="col">
                    <div className="row">
                      <p className="text-white add-patient-banner-text mb-0">
                        <span className="font-weight-bold">¡Recordá</span> que
                        para agendar un turno{' '}
                        <span className="font-weight-bold">
                          el paciente debe estar ingresado!
                        </span>
                      </p>
                    </div>
                    <div className="row">
                      <Link
                        to={`${process.env.PUBLIC_URL}/patient/new`}
                        className="pl-1">
                        <span className="add-patient-banner-link">
                          AGREGAR PACIENTE
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        key={appointment.id}
        className={'d-flex flex-column h-100 fill-height ' + props.className}>
        <div className="container">
          {fetching && <Loader />}
          {!fetching && (
            <>
              <div className="row mt-3 mb-3">
                <div className="col-lg-6 col-md-6 col-sm-6 col-sx-12">
                  <h3 className="patient-title mt-4 mb-4">
                    <Link
                      to={`${process.env.PUBLIC_URL}/appointment`}
                      className="">
                      <i className="fas fa-chevron-left patient-title-arrow" />
                    </Link>
                    {title}
                  </h3>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-sx-12">
                  {deleteButton}
                </div>
              </div>
              <form onSubmit={submitAppointment} className="form-card p-4">
                {alert}
                <div className="row justify-content-center mt-3">
                  <div className="col-lg-4 col-md-4 col-sm-4 col-sx-12">
                    <div className="form-group secondStep">
                      <label htmlFor="patient"> Paciente </label>
                      <SelectPatient
                        name="patient"
                        placeholder={
                          !isNew
                            ? appointment.patient.firstName +
                              ' ' +
                              appointment.patient.lastName
                            : 'Ingrese el nombre...'
                        }
                        value={appointment.patient}
                        onChange={handleChange}
                        isDisabled={disabled}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderRadius: '50px',
                          }),
                          container: (baseStyles, state) => ({
                            ...baseStyles,
                            width: '100%',
                            height: '43px',
                          }),
                        }}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-8 col-sm-8 col-sx-12">
                    <div className="form-group">
                      <label htmlFor="description"> Descripción </label>
                      <input
                        type="text"
                        name="description"
                        id="description"
                        className="form-control"
                        placeholder=""
                        value={appointment.description}
                        onChange={handleChange}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center  ">
                  <div className="col-lg-4 col-md-4 col-sm-4 col-sx-12 ">
                    <div className="form-group">
                      <label htmlFor="appointmentDate">Fecha</label>
                      <SelectDates
                        name="appointmentDate"
                        placeholder={'DD/MM/AAAA'}
                        value={appointment.appointmentDate}
                        onChange={handleChange}
                        isDisabled={disabled}
                        yearsFirst={false}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-sx-12 ">
                    <div className="form-group">
                      <label htmlFor="startTime">Horario de inicio</label>
                      <input
                        type="time"
                        name="startTime"
                        className="form-control"
                        placeholder="No definido"
                        value={appointment.startTime}
                        onChange={handleChange}
                        disabled={disabled}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-sx-12">
                    <div className="form-group">
                      <label htmlFor="endTime">Horario de finalización</label>
                      <input
                        type="time"
                        name="endTime"
                        className="form-control"
                        placeholder="No definido"
                        value={appointment.endTime}
                        onChange={handleChange}
                        disabled={disabled}
                        required
                      />
                      {callDuration}
                    </div>
                  </div>
                </div>
                {linkRow}
                {save}
              </form>
            </>
          )}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default withAlert()(AppointmentDetail);
