import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Register from './Register';
import './../Landing';
import apiHelpers from '../../../utils/apiHelpers';

const containerVariants = {
  hidden: {
    opacity: 0,
    y: '50vh',
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      stiffness: 10,
    },
  },
};

// const fondo = 'img/fondo.png';

var background = {
  //backgroundImage: `url(${fondo})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const Subscription = props => {
  const [subsPlanPriceData, setSubsPlanPriceData] = useState({
    currency_id: 'ARS',
    transaction_amount: -1,
  });

  useEffect(() => {
    apiHelpers
      .getSubscriptionPlan()
      .then(response => {
        setSubsPlanPriceData(response.data.auto_recurring);
      })
      .catch(e => {
        console.error(e);
      });
  }, []);

  return (
    <div className="container">
      <div className="col align-self-center">
        <div className="h5 mt-5 mb-0 row justify-content-center vpsy-font-color signup-font">
          SÉ PARTE DE{' '}
        </div>
        <div className="row justify-content-center">
          <img
            className="w-25"
            src={process.env.PUBLIC_URL + '/img/landingLogo.png'}
            alt="logo"
          />
        </div>
        <div className="signup-card-title my-3">
          <b>Registrate</b> para comenzar el <b>período de prueba</b>
        </div>
        <div className="row justify-content-center">
          {<Register className="w-50" history={props.history} />}
        </div>
      </div>
    </div>
  );
};

export default Subscription;
