import React, { useState, useEffect } from 'react';
import { useUserContext } from '../../contexts/UserContext';
import './Suggestions.css';
import apiHelpers from '../../utils/apiHelpers';
import Swal from 'sweetalert2';

const Suggestions = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [suggestion, setSuggestion] = useState('');
  const user = useUserContext();

  const handleSubmit = async e => {
    if (!suggestion) {
      setAlert('Error: Complete todos los campos.');
    } else {
      setIsLoading(true);
      apiHelpers
        .sendSuggestionEmail(user.id, { suggestion })
        .then(() => {
          setIsLoading(false);
          Swal.fire({
            title: `Mensaje enviado`,
            text: 'Gracias por tu colaboración',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#22654e',
            confirmButtonText: 'Cerrar',
            heightAuto: false,
          }).then(() => {
            setSuggestion('');
          });
        })
        .catch(e => {
          console.log(e);
          setIsLoading(false);
        });
    }
  };

  const handleSuggestionChange = e => {
    setSuggestion(e.target.value);
  };

  return (
    <>
      <div className="container mt-3 mb-5">
        <div className="px-5 py-4 w-50 m-auto userForm ">
          <h4 className="text-center vpsy-font vpsy-font-color my-4">
            Dejanos tu opinión o sugerencia
          </h4>

          <div className="form-group">
            <textarea
              className="w-100 section-text mt-2 p-2"
              rows="3"
              placeholder="Texto libre..."
              value={suggestion}
              onChange={handleSuggestionChange}
            />
          </div>

          {/* ALERT SIGN */}
          {alert && <div className="alert alert-danger">{alert}</div>}

          {/* Button submit */}
          <div className="row justify-content-md-center my-3">
            <div className="col mx-2">
              <button
                id="sign-up"
                className="btn btn-vpsy-solid btn-block mr-4 fadeIn fourth"
                onClick={handleSubmit}
                type="button">
                {isLoading ? (
                  <div class="spinner-border text-light" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  'Enviar'
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Suggestions;
