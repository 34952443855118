import React from 'react';
import { Link } from 'react-router-dom';
import './Patient.css';
import Footer from '../common/Footer';
import apiHelper from '../../utils/apiHelpers';
import Pagination from '../common/Pagination';
import PatientFilters from './/PatientFilters';
import { withAlert } from 'react-alert';
import moment from 'moment';
import { useState, useEffect } from 'react';
import Loader from './../common/Loader';
import { Steps } from 'intro.js-react';
import { patientSteps } from './PatientSteps';
import '../common/Steps.css';
import 'intro.js/introjs.css';
import { stepsStorage, STEPS_SECTIONS } from '../common/Steps';
import Swal from 'sweetalert2';
import formatter from '../../utils/formatter';
import { useHistory } from 'react-router';
const STEPS_SECTION = STEPS_SECTIONS.PATIENT;

const Patient = props => {
  const history = useHistory();
  const [fetching, setFetching] = useState(true);
  const [patients, setPatients] = useState([]);
  const [filters, setFilters] = useState({
    order: {
      lastName: '',
      birthDate: '',
    },
  });
  const [pageSize] = useState(10);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getPatients();
  }, [filters]);

  useEffect(() => {
    getPatients();
  }, [currentPage]);

  const handleChangePage = pageOfItems => {
    setCurrentPage(pageOfItems);
  };

  const handleChangeFilters = filters => {
    setFilters(filters);
    setCurrentPage(1);
  };

  const getNumberOfPages = () => {
    return Math.ceil(count / pageSize);
  };

  const getPatients = async () => {
    try {
      // For more information on how to build this object: https://loopback.io/doc/en/lb3/Skip-filter.html
      setFetching(true);
      const filtersWithPaging = {
        ...filters,
        limit: pageSize,
        skip: (currentPage - 1) * pageSize,
      };

      const [patients, patientsCount] = await Promise.all([
        await apiHelper.getPatients(filtersWithPaging),
        await apiHelper.getPatientsCount(filters),
      ]);

      setPatients(patients.data);
      setCount(patientsCount.data.count);
      setFetching(false);
    } catch (error) {
      setFetching(false);
      const statusCode = error.response.status;
      if (statusCode === 400 || statusCode === 422 || statusCode === 204) {
        console.error('Error al cargar pacientes');
      } else if (error.request) {
        console.error('Sin respuesta del Servidor');
      } else {
        console.error(error.message);
      }
    }
  };

  const onExit = () => {
    stepsStorage.setDone(STEPS_SECTION);
  };

  const filterOrderArrow = fieldOrderState => {
    return fieldOrderState == 'ASC'
      ? 'fa-sort-down'
      : fieldOrderState
      ? 'fa-sort-up'
      : 'fa-sort';
  };

  const deletePatient = async patient => {
    Swal.fire({
      title: `¿Desea eliminar al paciente ${patient.lastName} ${patient.firstName}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#22654e',
      customClass: {
        cancelButton: 'swal-cancel-btn', // Add your custom class for the cancel button
      },
      confirmButtonText: 'Si, eliminar.',
    }).then(async result => {
      if (result.isConfirmed) {
        await apiHelper.deletePatient(patient.id);
        Swal.fire({
          title: `Paciente eliminado.`,
          icon: 'success',
          confirmButtonColor: '#22654e',
          confirmButtonText: 'Continuar',
        });
        await getPatients();
      }
    });
  };

  return (
    <>
      {/* <Steps
        enabled={!stepsStorage.isDone(STEPS_SECTION)}
        steps={patientSteps.steps}
        initialStep={patientSteps.initialStep}
        options={{
          nextLabel: 'Siguiente',
          prevLabel: 'Anterior',
          skipLabel: 'Saltar',
          doneLabel: 'Finalizar',
          showStepNumbers: true,
        }}
        onExit={onExit}
      /> */}

      <div className={'d-flex flex-column fill-height ' + props.className}>
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <h3 className="patient-title mt-4 mb-4">Pacientes</h3>
            <div>
              <Link
                to={`${process.env.PUBLIC_URL}/patient/new`}
                className="px-1 my-1">
                <button type="button" className="btn btn-vpsy-solid">
                  <i className="fas fa-plus-circle text-white mr-1 md-hide"></i>
                  Nuevo paciente
                </button>
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="table-responsive table-sm">
              <table className="table table-bordered table-hover table-patient">
                <thead className="headPatient">
                  <tr>
                    <th
                      scope="col"
                      style={{ width: '25%' }}
                      onClick={() => {
                        setFilters({
                          order: {
                            lastName:
                              filters.order.lastName == 'ASC' ? 'DESC' : 'ASC',
                          },
                        });
                      }}>
                      Nombre{' '}
                      <span
                        className={`fa ${filterOrderArrow(
                          filters.order.lastName,
                        )}`}
                      />
                    </th>
                    <th
                      scope="col"
                      style={{ width: '10%' }}
                      onClick={() => {
                        setFilters({
                          order: {
                            birthDate:
                              filters.order.birthDate == 'ASC' ? 'DESC' : 'ASC',
                          },
                        });
                      }}>
                      Edad{' '}
                      <span
                        className={`fa ${filterOrderArrow(
                          filters.order.birthDate,
                        )}`}
                      />
                    </th>
                    <th scope="col" style={{ width: '35%' }}>
                      Email
                    </th>
                    <th scope="col" style={{ width: '15%' }}>
                      Teléfono
                    </th>
                    <th scope="col" style={{ width: '15%' }}>
                      Acciones
                    </th>
                  </tr>
                </thead>
                <tbody className="tablePatient">
                  {fetching && (
                    <tr style={{ height: '43px' }}>
                      <td colSpan={6} className="text-nowrap">
                        <div className="text-center">
                          <Loader />
                        </div>
                      </td>
                    </tr>
                  )}
                  {!fetching && !patients.length ? (
                    <tr style={{ height: '43px' }}>
                      <td colSpan={6} className="text-nowrap">
                        <div className="no-results text-center">
                          No se encontraron resultados:
                          <Link
                            to={`${process.env.PUBLIC_URL}/patient/new`}
                            className="pl-1">
                            <span>AGREGUE UN PACIENTE</span>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    patients.map(patient => {
                      return (
                        <tr key={patient.id}>
                          <td
                            className="td-patient-name"
                            onClick={() => {
                              history.push(
                                `${process.env.PUBLIC_URL}/patient/${patient.id}/records`,
                              );
                            }}>
                            {patient.lastName + ', ' + patient.firstName}
                          </td>
                          <td className="text-center">
                            {moment().diff(patient.birthDate, 'years', false)}
                          </td>
                          <td>{patient.email}</td>
                          <td>{formatter.formatPhoneNumber(patient.phone)}</td>
                          <td className="text-nowrap">
                            <div className="text-center mb-1">
                              <Link
                                to={`${process.env.PUBLIC_URL}/patient/${patient.id}/records`}>
                                <button
                                  type="button"
                                  className="btn"
                                  title="Ver ficha">
                                  <span className="fa fa-notes-medical icon-hover"></span>
                                </button>
                              </Link>
                              <Link
                                to={`${process.env.PUBLIC_URL}/patient/${patient.id}`}>
                                <button
                                  type="button"
                                  className="btn"
                                  title="Editar paciente">
                                  <span className="fa fa-edit icon-hover"></span>
                                </button>
                              </Link>
                              <button
                                type="button"
                                className="btn icon-red"
                                onClick={async () =>
                                  await deletePatient(patient)
                                }
                                title="Eliminar paciente">
                                <span className="fa fa-trash-alt icon-hover"></span>
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  )}
                  {patients.length < pageSize ? (
                    [...Array(pageSize - patients.length)].map(
                      (emptyRow, i) => {
                        return (
                          <tr key={i} style={{ height: '43px' }}>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        );
                      },
                    )
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <Pagination
            className="row justify-content-end"
            onChangePage={handleChangePage}
            currentPage={currentPage}
            totalPages={getNumberOfPages()}
          />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Patient;
