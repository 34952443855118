import React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import apiHelper from '../../../utils/apiHelpers';
import { withAlert } from 'react-alert';
import moment from 'moment';
import './PatientSummary.css';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router';

const PatientExamList = props => {
  const { patientId } = props;
  const [exams, setExams] = useState([]);
  const history = useHistory();

  const refreshExamList = () => {
    apiHelper
      .getExams({ patientId: patientId })
      .then(examsResponse => {
        examsResponse.data.forEach(exam => {
          const uniqueParentTests = [
            ...new Set(
              exam.results.map(test => {
                return test.studyType.parentTest.name;
              }),
            ),
          ];
          exam.parentTests = uniqueParentTests;
        });
        setExams(examsResponse.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  useEffect(() => {
    refreshExamList();
  }, [patientId]);

  const deleteExam = examId => {
    Swal.fire({
      title: `¿Desea eliminar examen?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#22654e',
      customClass: {
        cancelButton: 'swal-cancel-btn', // Add your custom class for the cancel button
      },
      confirmButtonText: 'Si, eliminar.',
    }).then(result => {
      if (result.isConfirmed) {
        apiHelper.deleteExam(examId);
        refreshExamList();
        Swal.fire({
          title: `Examen eliminado.`,
          icon: 'success',
          confirmButtonColor: '#22654e',
          confirmButtonText: 'Continuar',
        });
      }
    });
  };

  return (
    <>
      <div className="patient-summary w-100">
        <div className="w-100 table-responsive table-sm">
          <table className="table-bordered table-hover table-patient">
            <thead className="headPatient">
              <tr>
                <th scope="col" style={{ width: '5%' }}>
                  Estado{' '}
                </th>
                <th scope="col" style={{ width: '20%' }}>
                  Nº de Evaluación{' '}
                </th>
                <th scope="col" style={{ width: '45%' }}>
                  Tests incluidos
                </th>
                <th scope="col" style={{ width: '15%' }}>
                  Fecha
                </th>
                <th scope="col" style={{ width: '15%' }}>
                  Acciones
                </th>
              </tr>
            </thead>
            <tbody className="tablePatient">
              {exams.length <= 0 ? (
                <tr style={{ height: '43px' }}>
                  <td colSpan={6} className="text-nowrap">
                    <div className="no-results text-center">
                      El paciente no tiene ningún test
                    </div>
                  </td>
                </tr>
              ) : (
                exams
                  .sort((a, b) => {
                    return new Date(b.createdAt) - new Date(a.createdAt);
                  })
                  .map((exam, i) => {
                    const parentTestsString = exam.parentTests.map(
                      (parentTest, i) => {
                        return i ? `, ${parentTest}` : `${parentTest}`;
                      },
                    );
                    return (
                      <tr key={i}>
                        <td className="td-exam-status text-center">
                          {exam.results.length ? (
                            <i className={`fas fa-check-circle text-success`} />
                          ) : (
                            <i
                              className={`fas fa-exclamation-circle text-warning`}
                            />
                          )}
                        </td>
                        <td
                          className="text-center td-exam"
                          onClick={() => {
                            history.push(
                              `${process.env.PUBLIC_URL}/exam/${exam.id}/view`,
                            );
                          }}>
                          {`Evaluación ${exams.length - i}`}
                        </td>
                        <td
                          className="text-center td-exam"
                          title={parentTestsString}
                          onClick={() => {
                            history.push(
                              `${process.env.PUBLIC_URL}/exam/${exam.id}/view`,
                            );
                          }}>
                          {parentTestsString}
                        </td>
                        <td>{moment(exam.createdAt).format('DD MMM YYYY')}</td>
                        <td className="text-nowrap">
                          <div className="text-center mb-1">
                            <Link
                              to={`${process.env.PUBLIC_URL}/exam/${exam.id}/view`}>
                              <button
                                type="button"
                                className="btn"
                                title="Ver examen">
                                <span className="fa fa-eye icon-hover"></span>
                              </button>
                            </Link>
                            <Link
                              to={`${process.env.PUBLIC_URL}/exam/${exam.id}`}>
                              <button
                                type="button"
                                className="btn"
                                title="Editar examen">
                                <span className="fa fa-edit icon-hover"></span>
                              </button>
                            </Link>
                            <button
                              type="button"
                              className="btn icon-red"
                              onClick={() => {
                                deleteExam(exam.id);
                              }}
                              title="Eliminar examen">
                              <span className="fa fa-trash-alt icon-hover"></span>
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default PatientExamList;
