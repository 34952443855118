import React from 'react';
import './Loader.css';
import { SpinnerDotted } from 'spinners-react';
//import { Spinner } from "react-bootstrap";

const Loader = () => {
  return (
    <>
      <div className="background">
        {/*<SpinnerDotted className="spinner" size="6%" thickness="180" color='rgb(33, 126, 121)' speed="120" />*/}
        <div>
          <img
            className="logoload logoloader"
            src={process.env.PUBLIC_URL + '/img/loader.png'}
            alt="logo"
          />
        </div>
      </div>
    </>
  );
};

export default Loader;
