import React from 'react';
import PropTypes from 'prop-types';
import SelectPatient from '../common/selects/SelectPatient';
import { Link } from 'react-router-dom';
import './Patient.css';

export default class PatientFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getFiltersObject() {
    return {
      where: [
        {
          id: this.state.name,
        },
      ],
    };
  }

  getInitialState() {
    return {
      name: null,
    };
  }

  onChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  }

  clearFilters() {
    this.setState(this.getInitialState(), () => {
      this.props.onChangeFilters(this.getFiltersObject());
    });
  }

  onFiltersSubmit() {
    this.props.onChangeFilters(this.getFiltersObject());
  }

  render() {
    return (
      <div className="row my-1">
        <div className="col-md-4 mb-1 px-0">
          <SelectPatient
            name="name"
            placeholder="Nombre"
            value={this.state.name}
            onChange={this.onChange.bind(this)}
            required={false}
          />
        </div>

        <div className="row col-md mx-0 mb-1 px-0">
          <button
            type="button"
            className="col mx-1 btn button-filter fadeInfromRigth"
            onClick={this.onFiltersSubmit.bind(this)}>
            Filtrar{' '}
            <i className="fas fa-search" style={{ marginLeft: '3px' }}></i>
          </button>
          <button
            type="button"
            className="col mx-1 btn button-filter-rst fadeInfromRigth"
            onClick={this.clearFilters.bind(this)}>
            Reiniciar filtros <i className="fas fa-sync-alt"></i>
          </button>
        </div>

        {/* <Link to={`${process.env.PUBLIC_URL}/patient/new`}
            className="col-md-3 my-1 mx-1 btn pull-right button-patient fadeInfromRigth ">
              Nuevo Paciente <i className="fas fa-plus-square"></i>                  
          </Link> */}

        {/* <div className="col-md-3 my-1 mx-1 btn pull-right"> */}
        <Link
          to={`${process.env.PUBLIC_URL}/patient/new`}
          className="col-md-3 mb-1 mx-1 px-0 pull-right">
          <button
            type="button"
            className="col h-100 btn pull-right button-patient fadeInfromRigth">
            <span className="glyphicon glyphicon-pencil">
              Nuevo Paciente <i className="fas fa-plus-square"></i>
            </span>
          </button>
        </Link>
        {/* </div> */}
      </div>
    );
  }
}
PatientFilters.propTypes = {
  onChangeFilters: PropTypes.func.isRequired,
};
