import apiHelper from '../../utils/apiHelpers';
import Footer from '../common/Footer';
import React from 'react';
import formatter from '../../utils/formatter';
import LoadingOverlay from 'react-loading-overlay';
import { withAlert } from 'react-alert';
import SelectDates from '../common/selects/SelectDates';
import './Patient.css';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
const emailRegex = new RegExp(
  /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
);

class PatientDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      loading: false,
      isNew: props.match.params.id === 'new',
      patientId: props.match.params.id,
      patient: {
        firstName: '',
        lastName: '',
        birthDate: null,
        email: '',
        phone: '',
        description: '',
      },
    };
  }

  componentDidMount() {
    if (!this.state.isNew) {
      apiHelper
        .getPatient(this.state.patientId)
        .then(response => {
          this.setState({
            patient: response.data,
          });
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      if (!this.state.isNew) {
        apiHelper
          .getPatient(this.props.match.params.id)
          .then(response => {
            this.setState({
              isNew: this.props.match.params.id === 'new',
              patientId: this.props.match.params.id,
              patient: response.data,
            });
          })
          .catch(error => {
            console.log(error);
          });
      }
    }
  }

  handleChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    value =
      target.type === 'react-datetime'
        ? formatter.formatPastDateForAPI(target.value)
        : target.value;
    const name = target.name;
    const patient = this.state.patient;
    patient[name] = value;

    this.setState({
      patient: patient,
    });
  }

  async deletePatient() {
    Swal.fire({
      title: `¿Desea eliminar al paciente?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#22654e',
      customClass: {
        cancelButton: 'swal-cancel-btn', // Add your custom class for the cancel button
      },
      confirmButtonText: 'Si, eliminar.',
    }).then(async result => {
      if (result.isConfirmed) {
        await apiHelper.deletePatient(this.state.patientId);
        Swal.fire({
          title: `Paciente eliminado.`,
          icon: 'success',
          confirmButtonColor: '#22654e',
          confirmButtonText: 'Continuar',
        }).then(() => {
          this.props.history.push('/patient');
        });
      }
    });
  }

  async submitPatient(event) {
    event.preventDefault();
    if (!emailRegex.test(this.state.patient.email)) {
      this.setState({
        errorMessage: 'El email no es válido. Ej: ejemplo@email.com',
      });
      return;
    }

    try {
      this.setState({ loading: true });
      //If requests already exists, update it. If not, create it
      if (this.state.isNew) {
        await apiHelper.postPatient(this.state.patient);
      } else {
        await apiHelper.patchPatient(this.state.patientId, this.state.patient);
      }
      this.setState({ loading: false });
      Swal.fire({
        title: 'Paciente guardado con éxito',
        icon: 'success',
        confirmButtonColor: '#22654e',
      }).then(() => {
        this.props.history.push('/patient');
      });
    } catch (error) {
      this.setState({ loading: false });
      const statusCode = error.response.status;
      let errorMessage;
      if (statusCode === 422 || statusCode === 400) {
        errorMessage = 'Datos invalidos o incompletos';
      } else if (statusCode === 403) {
        // FreeTrial limit
        errorMessage = 'Límite de la prueba gratuita excedido.';
      } else if (statusCode === 401) {
        errorMessage = error.response.data;
      } else {
        errorMessage = error.response.data.error.message;
      }
      this.setState({
        errorMessage: errorMessage,
      });
    }
  }

  render() {
    let alert;
    let title = '';
    let deleteButton = false;
    let name = null;
    let role = null;
    let document = null;
    let edited = null;
    let password = null;

    if (this.state.errorMessage) {
      alert = (
        <div className="alert alert-warning">{this.state.errorMessage}</div>
      );
    }

    if (this.state.isNew) {
      title = 'Agregar paciente';
    } else {
      title = this.state.patient.firstName + ' ' + this.state.patient.lastName;
      deleteButton = (
        <button
          className="btn button-delete btn-vpsy-outline pull-right"
          type="button"
          onClick={this.deletePatient.bind(this)}
          title="Eliminar">
          <i className="fa fa-trash-alt"></i>
        </button>
      );
      edited =
        'Creado el ' + formatter.formatDate(this.state.patient.createdAt);
    }

    return (
      <div
        className={
          'd-flex flex-column h-100 fill-height ' + this.props.className
        }>
        <div className="container ">
          <LoadingOverlay
            active={this.state.loading}
            spinner
            text="Guardando...">
            <h3 className="patient-title mt-4 mb-4">
              <Link to={`${process.env.PUBLIC_URL}/appointment`} className="">
                <i className="fas fa-chevron-left patient-title-arrow" />
              </Link>
              {title}
            </h3>

            <div className="subtitle-section justify-content-between align-items-center">
              <h4>{edited}</h4> {deleteButton}
            </div>
            <form
              onSubmit={this.submitPatient.bind(this)}
              className="p-4 patient-form-card">
              <div className="row justify-content-center mt-3">
                <div className="col-md-12 col-lg-6">
                  <div className="form-group">
                    <label htmlFor="name" className="patient-label">
                      Nombre(s)
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      className="form-control patient-input px-4"
                      placeholder="No definido"
                      value={this.state.patient.firstName}
                      onChange={this.handleChange.bind(this)}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-12 col-lg-6">
                  <div className="form-group">
                    <label htmlFor="name" className="patient-label">
                      Apellido(s)
                    </label>
                    <input
                      type="text"
                      name="lastName"
                      className="form-control patient-input px-4"
                      placeholder="No definido"
                      value={this.state.patient.lastName}
                      onChange={this.handleChange.bind(this)}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row justify-content-center mt-3">
                <div className="col-md-12 col-lg-4">
                  <div className="form-group">
                    <label htmlFor="birthDate" className="patient-label">
                      Fecha de nacimiento
                    </label>
                    <SelectDates
                      name="birthDate"
                      placeholder={'DD/MM/AAAA'}
                      isDisabled={false}
                      value={this.state.patient.birthDate}
                      onChange={this.handleChange.bind(this)}
                      yearsFirst={true}
                      readOnly={false}
                    />
                  </div>
                </div>
                <div className="col-md-12 col-lg-4">
                  <div className="form-group">
                    <label htmlFor="email" className="patient-label">
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      className="form-control patient-input px-4"
                      placeholder="No definido"
                      value={this.state.patient.email}
                      onChange={this.handleChange.bind(this)}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-12 col-lg-4">
                  <div className="form-group">
                    <label htmlFor="email" className="patient-label">
                      Teléfono
                    </label>
                    <input
                      type="number"
                      name="phone"
                      className="form-control patient-input patient-input-phone px-4"
                      placeholder="No definido"
                      value={this.state.patient.phone}
                      onChange={this.handleChange.bind(this)}
                      required
                      min={0}
                    />
                  </div>
                </div>
              </div>
              <div className="row justify-content-center mt-3">
                <div className="col-md-12 col-lg-12">
                  <div className="form-group">
                    <label htmlFor="email" className="patient-label">
                      Descripción
                    </label>
                    <textarea
                      name="description"
                      className="form-control patient-input-description px-4 pt-3"
                      placeholder="No definido"
                      value={this.state.patient.description}
                      onChange={this.handleChange.bind(this)}
                      rows="10"
                    />
                  </div>
                </div>
              </div>

              {alert}
              <div className="row justify-content-center">
                <Link
                  to={`${process.env.PUBLIC_URL}/appointment`}
                  className="col-md-5 btn-lg btn-vpsy-outline my-3 mx-1">
                  <button className="" type="button">
                    Cancelar
                  </button>
                </Link>

                <button
                  className="col-md-5 btn-lg btn-vpsy-gradient my-3 mx-1"
                  type="submit">
                  Guardar
                </button>
              </div>
            </form>
          </LoadingOverlay>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withAlert()(PatientDetail);
