import { createContext } from 'react';

export const ExamContext = createContext({
  exam: {
    id: null,
    createdAt: '',
    conclusion: '',
    patient: {
      id: null,
      firstName: '',
      lastName: '',
    },
    results: [],
    testConclusion: [],
  },
  setExam: () => {},
  patient: null,
  setPatient: () => {},
  currentSection: 0,
  setCurrentSection: () => {},
});
