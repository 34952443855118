import React from 'react';
import { GroupInput, Input, Icons, ErrorMsg, leyendError } from './Form';

const ComponentInput = ({
  label,
  placeholder,
  type,
  name,
  dataTip,
  theError,
  regularExpretion,
  setInfo,
  info,
  validatePassword,
  validateEmail,
}) => {
  const handleChange = e => {
    setInfo({ ...info, data: e.target.value });
  };
  const validationText = () => {
    if (regularExpretion) {
      if (!info.data.length) {
        setInfo({ ...info, validate: 'false' });
        return;
      }
      if (regularExpretion.test(info.data)) {
        setInfo({ ...info, validate: 'true' });
      } else {
        setInfo({ ...info, validate: 'false' });
      }
    }
    if (validatePassword) {
      validatePassword();
    }
    if (validateEmail) {
      validateEmail();
    }
  };

  return (
    <div className="form-group mb-3">
      <label htmlFor={name} className="sr-only">
        {label}
      </label>
      <GroupInput>
        <Input
          type={type}
          name={name}
          className="form-control mb-2 pl-3 fadeIn second"
          placeholder={placeholder}
          data-tip={dataTip}
          regularExpretion={regularExpretion}
          required
          /* autoFocus */
          value={info.data}
          isValid={info.validate}
          onChange={handleChange}
          onKeyUp={validationText}
          onBlur={validationText}
        />
        <Icons isValid={info.validate}>
          {' '}
          {info.validate === 'true' ? (
            <i className="fas fa-check-circle"></i>
          ) : (
            <i className="fas fa-times-circle"></i>
          )}
        </Icons>

        <ErrorMsg isValid={info.validate}>{theError}</ErrorMsg>
      </GroupInput>
    </div>
  );
};

export default ComponentInput;
