import React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../common/Footer';
import apiHelper from '../../utils/apiHelpers';
import { withAlert } from 'react-alert';
import moment from 'moment';
import { Steps } from 'intro.js-react';
import '../common/Steps.css';
import 'intro.js/introjs.css';
import { stepsStorage, STEPS_SECTIONS } from '../common/Steps';
import SelectDates from '../common/selects/SelectDates';
import PatientSummary from './components/PatientSummary';
import PatientExamList from './components/PatientExamList';

const STEPS_SECTION = STEPS_SECTIONS.EXAMS;

const PatientRecords = props => {
  const [exams, setExams] = useState([]);
  const [patient, setPatient] = useState({
    name: 'nombre',
    lastName: 'apelido',
    birthDate: '1.1.1',
    email: 'asd@asd',
    phone: '1111111111',
    description: 'descri',
  });
  useEffect(() => {
    apiHelper
      .getExams()
      .then(examsResponse => {
        setExams(examsResponse.data);
      })
      .catch(e => {
        console.log(e);
      });
  }, []);

  const onExit = () => {
    stepsStorage.setDone(STEPS_SECTION);
  };

  return (
    <>
      <div
        className={
          'd-flex flex-column h-100 fill-height my-3 ' + props.className
        }>
        <div className="container ">
          <div className="row justify-content-between align-items-center">
            <h3 className="patient-title mt-4 mb-4">
              <Link to={`${process.env.PUBLIC_URL}/patient`} className="">
                <i className="fas fa-chevron-left patient-title-arrow" />
              </Link>
              Ficha de paciente
            </h3>
            <div>
              <Link
                to={`${process.env.PUBLIC_URL}/exam/new`}
                className="px-1 my-1">
                <button type="button" className="btn btn-vpsy-solid">
                  <i className="fas fa-plus-circle text-white mr-1 md-hide"></i>
                  Nueva evaluación
                </button>
              </Link>
            </div>
          </div>

          <div className="row">
            <PatientSummary patientId={props.match.params.id} />
          </div>

          <br></br>

          <div className="row">
            <PatientExamList patientId={props.match.params.id} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PatientRecords;
