import React, { useContext } from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import apiHelper from '../../../utils/apiHelpers';
import './ExamSection.css';
import SelectPatient from '../../common/selects/SelectPatient';
import { ExamContext } from '../ExamContext';
import { EXAM_SECTION } from '../ExamConstants';

const ExamPatient = () => {
  const {
    exam,
    setExam,
    patient,
    setPatient,
    currentSection,
    setCurrentSection,
  } = useContext(ExamContext);

  const handleChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setPatient(value);
    setExam(exam => {
      return { ...exam, patient: value };
    });
  };

  return (
    <>
      <div className="d-flex flex-column h-100">
        <div className="row">
          <div className="col-6 exam-section-title my-2">
            Seleccione paciente
          </div>
          {exam.patient.id && (
            <>
              <div className="col-6 px-0">
                <div className="d-flex justify-content-end w-100">
                  <Link
                    to={`${process.env.PUBLIC_URL}/patient`}
                    className="col-md-3 btn-vpsy-outline exam-btn-txt my-1 mx-1">
                    <button className="bg-transparent" type="button">
                      Atrás
                    </button>
                  </Link>
                  <button
                    className="col-md-3 btn-vpsy-gradient exam-btn-txt my-1 mx-1"
                    onClick={() => {
                      setCurrentSection(EXAM_SECTION.TESTS);
                    }}>
                    Siguiente
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="w-100 d-flex">
          <div className="exam-line-green" />
          <div className="exam-line-divider" />
        </div>
        <div className="mt-4">
          <label htmlFor="patient" className="exam-input-label">
            Paciente
          </label>
          <SelectPatient
            name="patient"
            placeholder={'Seleccione paciente'}
            value={exam.patient}
            onChange={handleChange}
            isDisabled={false}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderRadius: '50px',
                padding: '3px 17.4px 3px 20px',
                border: 'solid 1px #e1e1e1',
              }),
              container: (baseStyles, state) => ({
                ...baseStyles,
                textWrap: 'nowrap',
                width: '50%',
                height: '43px',
              }),
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ExamPatient;
