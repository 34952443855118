import React, { useEffect } from 'react';
import './Landing.css';
import { motion } from 'framer-motion';
import About from './sections/About';
import Advantages from './sections/Advantages';
import Subscription from './sections/Subscription';
import TopBar from './sections/TopBar';
import { useState, useRef } from 'react';
import apiHelpers from '../../utils/apiHelpers';

const containerVar = {
  initial: {
    opacity: 0,
    scale: 0,
  },
  show: {
    scale: 1,
    opacity: 1,
    transition: {
      type: 'spring',
      stiffness: 80,
      damping: 10,
    },
  },
};

const SIGN_UP_LOCATION = '#signup';

const Landing = props => {
  const { history, location } = props;
  const signUpForm = useRef();

  //const fondo = 'img/fondo.png';

  let background = {
    //backgroundImage: `url(${fondo})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };

  // AutoScroll to SIGN-UP Form
  useEffect(() => {
    if (location.hash === SIGN_UP_LOCATION) {
      setTimeout(() => {
        signUpForm.current.scrollIntoView({ behavior: 'smooth' });
      }, 2000);
    }
  }, []);

  return (
    <div style={background}>
      <div className="d-flex flex-column justify-content-center">
        <TopBar />
        <div className="w-100 d-inline-block justify-content-center align-items-center py-5">
          <h1 className="fadeIn text-center align-self-center">
            Bienvenidx a una nueva forma de atender...
          </h1>
        </div>

        <div className="left d-flex align-items-start mb-5">
          <motion.div
            className="logos"
            variants={containerVar}
            initial="initial"
            animate="show">
            <img
              className="landingiso"
              src={process.env.PUBLIC_URL + '/img/landingIso.png'}
              alt="logo"
            />
            <img
              className="landinglogo "
              src={process.env.PUBLIC_URL + '/img/landingLogo.png'}
              alt="logo"
            />
          </motion.div>
        </div>

        <motion.h2
          className="arrow mt-5"
          initial={{ y: -5 }}
          animate={{
            y: 5,
            transition: {
              duration: 1,
              ease: 'easeIn',
              repeat: Infinity,
              repeatType: 'reverse',
            },
          }}>
          <i className="fas fa-angle-double-down"></i>
        </motion.h2>
      </div>

      <br />
      <About history={history} />
      <br />
      <Advantages history={history} />
      <br ref={signUpForm} />
      <Subscription history={history} />
    </div>
  );
};

export default Landing;
