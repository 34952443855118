import React, { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import {
  pdf,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Link,
  Font,
} from '@react-pdf/renderer';
import moment, { max } from 'moment';
import apiHelpers from '../../utils/apiHelpers';
import formatter from '../../utils/formatter';
import {
  SECTION_SEPARATOR,
  TITLE_SEPARATOR,
  TITLE_INDEX,
  BODY_INDEX,
} from './ExamConstants';
import { react_pdf_styles } from './ExamPDFStyles';
import Html from 'react-pdf-html';
import { deentitizeHTML } from './examSection/ExamConclusions';

// This is not a component!

// Styles!!
const styles = StyleSheet.create(react_pdf_styles);

// Fonts
Font.register({
  family: 'Poppins',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrFJDUc1NECPY.ttf',
    }, // font-style: normal, font-weight: normal
    {
      src: 'https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6V1tvFP-KUEg.ttf',
      fontStyle: 'bold',
      fontWeight: 600,
    },
  ],
});

const getExamResultArray = (exam, includedParentTests = []) => {
  //the takes the exam state and returns an array to send to ChartB props

  let results = exam.results
    .filter(result => {
      // This filters unwanted results
      return result.studyType.category.categoryName !== 'ÍNDICES SECUNDARIOS';
    })
    .sort((resultA, resultB) => {
      return resultA.studyType.id - resultB.studyType.id;
    });
  let resultsObject = {};

  for (let i = 0; i < results.length; i++) {
    if (!includedParentTests.includes(results[i].studyType.parentTest.name)) {
      continue;
    }

    let parentName = results[i].studyType.parentTest.name;
    let categoryName = results[i].studyType.category.categoryName;
    let testName = results[i].studyType.name;
    let score = results[i].result;
    let mean = results[i].studyType.mean;
    let deviation = results[i].studyType.deviation;
    let calculatedResult = formatter.calculateResult(score, mean, deviation);

    if (!resultsObject.hasOwnProperty(parentName)) {
      resultsObject[parentName] = {};
    }

    if (!resultsObject[parentName].hasOwnProperty(categoryName)) {
      resultsObject[parentName][categoryName] = {};
    }

    resultsObject[parentName][categoryName][testName] = {
      result: calculatedResult, // percentile
      score,
      min: mean - deviation,
      max: mean + deviation,
    };
  }

  return resultsObject;
};

// Processes conclussion array into this format [...{title:string , body:string}]
const getConclusionsArray = exam => {
  let conclusions = exam.testConclusion;
  if (!conclusions.length) {
    let examSections = exam.conclusion.split(SECTION_SEPARATOR);
    for (const section of examSections) {
      if (section.length > 0) {
        let splitSection = section.split(TITLE_SEPARATOR);
        conclusions.push({
          title: splitSection[TITLE_INDEX],
          body: splitSection[BODY_INDEX],
        });
      }
    }
  }
  return conclusions;
};

// This takes a string and inserts a '\n' to prevent hyphen wrap
const addLineBreakAtSpace = str => {
  const MIN_CHAR_NUMBER = 20;
  const spaceIndex = str.indexOf(' ', MIN_CHAR_NUMBER);
  if (spaceIndex !== -1) {
    return str.substring(0, spaceIndex) + '\n' + str.substring(spaceIndex + 1);
  }
  return str;
};

export const pdfTemplate = (
  professional,
  exam,
  includedParentTests,
  graphsPngObj = null,
  conclusions,
) => {
  let { patient, results } = exam;

  // Calculating patient age
  let patientYears = moment().diff(moment(patient.birthDate), 'year');
  let patientMonths = moment().diff(
    moment(patient.birthDate).add(patientYears, 'years'),
    'months',
  );
  patient.age = {
    years: patientYears,
    months: patientMonths,
  };

  let resultArray = getExamResultArray(exam, includedParentTests);

  // Results table content
  let resultTable = [];
  for (let parentTest in resultArray) {
    let tempTestTitle = <Text></Text>;
    let tempTopRow = <Text></Text>;
    let tempTestRows = [];

    tempTestTitle = (
      <View style={[styles.flexRow, styles.examTopSection]}>
        <Text style={styles.examTitle} key={`${parentTest}Title`}>
          {parentTest}
        </Text>
        <Text style={styles.examTitleDate}>
          {`Fecha: ${moment(exam.updatedAt).format('DD/MM/YYYY')}`}
        </Text>
      </View>
    );

    // Inserting Top table row
    tempTopRow = (
      <View style={styles.resultRow} key={`${parentTest}Row`}>
        <View style={styles.nameColumn}>
          <Text style={[styles.tableFirstRowText, styles.textLeft]}>
            Categoría
          </Text>
        </View>
        <View style={styles.nameColumn}>
          <Text style={[styles.tableFirstRowText, styles.textLeft]}>Test</Text>
        </View>
        <View style={styles.scoreColumn}>
          <Text style={[styles.tableFirstRowText, styles.textCenter]}>
            Puntaje
          </Text>
        </View>
        {/* Z table header*/}
        <View style={[styles.zScoreHeader]}>
          <View style={[styles.zScoreHeaderRow]}>
            <Text style={styles.zScoreHeaderCell}>1</Text>
            <Text style={styles.zScoreHeaderCell}>1</Text>
            <Text style={styles.zScoreHeaderCell}>2</Text>
            <Text style={styles.zScoreHeaderCell}>7</Text>
            <Text style={[styles.zScoreHeaderCell, styles.zScore1SigmaCell]}>
              16
            </Text>
            <Text style={[styles.zScoreHeaderCell, styles.zScore1SigmaCell]}>
              31
            </Text>
            <Text style={[styles.zScoreHeaderCell, styles.zScoreZeroCell]}>
              50
            </Text>
            <Text style={[styles.zScoreHeaderCell, styles.zScore1SigmaCell]}>
              69
            </Text>
            <Text style={[styles.zScoreHeaderCell, styles.zScore1SigmaCell]}>
              84
            </Text>
            <Text style={styles.zScoreHeaderCell}>93</Text>
            <Text style={styles.zScoreHeaderCell}>98</Text>
            <Text style={styles.zScoreHeaderCell}>99</Text>
            <Text style={styles.zScoreHeaderCell}>99</Text>
          </View>
          <View style={[styles.zScoreHeaderRow]}>
            <Text style={styles.zScoreHeaderCell}>-3</Text>
            <Text style={styles.zScoreHeaderCell}>-2.5</Text>
            <Text style={styles.zScoreHeaderCell}>-2</Text>
            <Text style={styles.zScoreHeaderCell}>-1.5</Text>
            <Text style={[styles.zScoreHeaderCell, styles.zScore1SigmaCell]}>
              -1
            </Text>
            <Text style={[styles.zScoreHeaderCell, styles.zScore1SigmaCell]}>
              -0.5
            </Text>
            <Text style={[styles.zScoreHeaderCell, styles.zScoreZeroCell]}>
              0
            </Text>
            <Text style={[styles.zScoreHeaderCell, styles.zScore1SigmaCell]}>
              0.5
            </Text>
            <Text style={[styles.zScoreHeaderCell, styles.zScore1SigmaCell]}>
              1
            </Text>
            <Text style={styles.zScoreHeaderCell}>1.5</Text>
            <Text style={styles.zScoreHeaderCell}>2</Text>
            <Text style={styles.zScoreHeaderCell}>2.5</Text>
            <Text style={styles.zScoreHeaderCell}>3</Text>
          </View>
        </View>
      </View>
    );
    let lastCategory = '';
    for (let category in resultArray[parentTest]) {
      for (let testName in resultArray[parentTest][category]) {
        const testPercentileResult =
          resultArray[parentTest][category][testName].result;
        tempTestRows.push(
          <View style={styles.resultRow} key={testName}>
            <View style={styles.nameColumn}>
              <Text
                style={[styles.tableText, styles.textBold, styles.textLeft]}>
                {lastCategory != category ? addLineBreakAtSpace(category) : ''}
              </Text>
            </View>
            <View style={styles.nameColumn}>
              <Text style={[styles.tableText, styles.textLeft]}>
                {testName}
              </Text>
            </View>
            <View style={styles.scoreColumn}>
              <Text style={[styles.tableText, styles.textCenter]}>
                {resultArray[parentTest][category][testName].score}
              </Text>
            </View>
            {/* Z table */}
            <View style={[styles.zScoreRow]}>
              <Text style={[styles.zScoreCell, styles.zScoreCellRed]}>
                {testPercentileResult == -3 && 'X'}
              </Text>
              <Text style={[styles.zScoreCell, styles.zScoreCellRed]}>
                {testPercentileResult == -2.5 && 'X'}
              </Text>
              <Text style={[styles.zScoreCell, styles.zScoreCellRed]}>
                {testPercentileResult == -2 && 'X'}
              </Text>
              <Text style={[styles.zScoreCell, styles.zScoreCellRed]}>
                {testPercentileResult == -1.5 && 'X'}
              </Text>
              <Text
                style={[
                  styles.zScoreCell,
                  styles.zScore1SigmaCell,
                  styles.zScoreCellGray,
                ]}>
                {testPercentileResult == -1 && 'X'}
              </Text>
              <Text
                style={[
                  styles.zScoreCell,
                  styles.zScore1SigmaCell,
                  styles.zScoreCellGray,
                ]}>
                {testPercentileResult == -0.5 && 'X'}
              </Text>
              <Text
                style={[
                  styles.zScoreCell,
                  styles.zScoreZeroCell,
                  styles.zScoreCellGray,
                ]}>
                {testPercentileResult == 0 && 'X'}
              </Text>
              <Text
                style={[
                  styles.zScoreCell,
                  styles.zScore1SigmaCell,
                  styles.zScoreCellGray,
                ]}>
                {testPercentileResult == 0.5 && 'X'}
              </Text>
              <Text
                style={[
                  styles.zScoreCell,
                  styles.zScore1SigmaCell,
                  styles.zScoreCellGray,
                ]}>
                {testPercentileResult == 1 && 'X'}
              </Text>
              <Text style={[styles.zScoreCell, styles.zScoreCellGreen]}>
                {testPercentileResult == 1.5 && 'X'}
              </Text>
              <Text style={[styles.zScoreCell, styles.zScoreCellGreen]}>
                {testPercentileResult == 2 && 'X'}
              </Text>
              <Text style={[styles.zScoreCell, styles.zScoreCellGreen]}>
                {testPercentileResult == 2.5 && 'X'}
              </Text>
              <Text style={[styles.zScoreCell, styles.zScoreCellGreen]}>
                {testPercentileResult == 3 && 'X'}
              </Text>
            </View>
          </View>,
        );
        lastCategory = category;
      }
    }
    resultTable.push(
      <View wrap={true} key={`${parentTest}Title`}>
        {tempTestTitle}
        {tempTopRow}
        {tempTestRows}
      </View>,
    );
  }

  // mapping graphs
  let graphsImageArray = [];
  for (let graphId in graphsPngObj) {
    graphsImageArray.push(<Image key={graphId} src={graphsPngObj[graphId]} />);
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Membrete */}
        <View style={styles.header} fixed>
          <View style={[styles.flexColumn, styles.headerBox]}>
            <Text style={styles.headerText}>
              {`Evaluación: ${patient.firstName}, ${patient.lastName}`}
            </Text>
            <Text style={styles.headerText}>
              {`Fecha: ${moment().format('DD/MM/YYYY')}`}
            </Text>
            <Link style={styles.headerText} src={'Mentaly.ar'}>
              Mentaly.ar
            </Link>
          </View>
          <Image style={styles.headerLogo} source={'/img/logovps.png'} />
        </View>

        {/* Patient Data */}
        <View style={styles.sectionTopBar} />
        <View style={[styles.patientSection, styles.flexColumn]}>
          <View style={[styles.flexRow, styles.borderBottom]}>
            <Text style={styles.patientName}>
              {`${patient.firstName}, ${patient.lastName}`}
            </Text>
          </View>

          <View style={[styles.flexRow, styles.patientFlexRow]}>
            <View style={[styles.flexColumn, styles.patientFirstColumn]}>
              <Text style={styles.patientDataTitle}>{`Edad:`}</Text>
              <Text style={styles.patientData}>
                {`${patient.age.years} años, ${patient.age.months} ${
                  patient.age.months === 1 ? `mes` : `meses`
                }.`}
              </Text>

              <Text style={styles.patientDataTitle}>{`Teléfono:`}</Text>
              <Text style={styles.patientData}>{`${formatter.formatPhoneNumber(
                patient.phone,
              )}`}</Text>

              <Text style={styles.patientDataTitle}>{`Email:`}</Text>
              <Text style={styles.patientData}>{`${patient.email}`}</Text>
            </View>

            <View style={[styles.flexColumn, styles.patientSecondColumn]}>
              <Text style={styles.patientDataTitle}>{`Descripción:`}</Text>
              <Text style={styles.patientData}>{`${patient.description}`}</Text>
            </View>
          </View>
        </View>

        {/* RESULTS TABLE */}
        <View style={styles.sectionTopBar} />
        <View style={styles.section}>
          {/* INSERT RES TABLE */}
          {resultTable}
        </View>

        {/* GRAPHS */}
        {graphsImageArray.length > 0 ? (
          <>
            <View style={styles.sectionTopBar} break />
            <View style={styles.section}>
              <Text style={styles.sectionTitle}>Gráficos</Text>
              {graphsImageArray}
            </View>
          </>
        ) : (
          <Text></Text>
        )}

        {/* CONCLUSIONS */}
        {conclusions.length > 0 ? (
          <View wrap={true} break>
            <View style={styles.sectionTopBar} />
            <View style={styles.section}>
              <View style={[styles.flexRow, styles.conclusionTopSection]}>
                <Text style={styles.examTitle}>
                  {`Conclusiones de evaluación`}
                </Text>
              </View>

              {conclusions.map((conclusion, i) => {
                return (
                  <View style={[styles.flexColumn, styles.conclusion]} key={i}>
                    <Text style={styles.conclusionTitle}>
                      {conclusion.title}
                    </Text>
                    <Html>
                      {`
												<style>
													p {
														font-size: 8;
														color: #5b5d5d;
														text-overflow: '-';
														margin: 3;
														padding: 0;
													}
													table {
    												border: 1px solid #5b5d5d;								
													}
													th {
														background-color: #ccc;
													}
													td, th {
														border: 1px solid #5b5d5d;
														max-width: 50%;
													}
												</style>
												${deentitizeHTML(conclusion.body)}`}
                    </Html>
                  </View>
                );
              })}
            </View>
          </View>
        ) : (
          <Text></Text>
        )}

        {/* FOOTER */}
        <View style={[styles.flexRow, styles.footer]} fixed>
          <Text style={styles.footerLeft}>
            {`Evaluación | ${patient.firstName} ${patient.lastName}`}
          </Text>
          <Link style={styles.footerLink} src={'Mentaly.ar'}>
            Mentaly.ar
          </Link>
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `Pág. ${pageNumber} / ${totalPages}`
            }
          />
        </View>
      </Page>
    </Document>
  );
};

// This magic functions takes a object with promises and returns a copy of the object with all promises resolved
function promisedProperties(object) {
  let promisedProperties = [];
  const objectKeys = Object.keys(object); // un array con las keys

  objectKeys.forEach(key => promisedProperties.push(object[key])); // llena promisedProps con las promesas

  return Promise.all(promisedProperties).then(resolvedValues => {
    //resolvedValues is [res0,res1,res2,res3...]
    return resolvedValues.reduce((resolvedObject, property, index) => {
      // (prev, curr, currIndx) => {ret}
      resolvedObject[objectKeys[index]] = property;
      return resolvedObject;
    }, object); //initial value= object
  });
}

export const generatePDFDocument = async (
  options,
  exam,
  graphPngPromisesObj,
  setTriggerExport,
) => {
  let { includeConclusions, includeCharts, includedParentTests } = options;
  const conclusions = includeConclusions ? getConclusionsArray(exam) : [];
  const chartsPNGs = includeCharts
    ? await promisedProperties(graphPngPromisesObj)
    : [];
  const currentUserResp = await apiHelpers.getCurrentUser();

  const generatedPdfTemplate = pdfTemplate(
    currentUserResp.data,
    exam,
    includedParentTests,
    chartsPNGs,
    conclusions,
  );

  console.log('Template DONE.');
  console.log('Generating PDf Blob.');
  // const blob = await pdf(generatedPdfTemplate).toBlob();
  pdf(generatedPdfTemplate)
    .toBlob()
    .then(blob => {
      console.log('Saving PDf Blob.');
      saveAs(
        blob,
        `${exam.patient.lastName}-${exam.patient.firstName}-Informe`,
      ); // Download pdf file
    })
    .catch(e => {
      console.log('error generating pdf');
      console.error(e);
    })
    .finally(() => {
      setTriggerExport(false);
    });
};
