import apiHelper from '../../utils/apiHelpers';
import React from 'react';
import './User.css';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import Loader from '../common/Loader';
import apiHelpers from '../../utils/apiHelpers';
const qs = require('qs');

const EmailValidation = props => {
  const { userId, validationString } = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  });
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (validationString) {
      apiHelpers
        .validateUserEmail(userId, validationString)
        .then(resp => {
          if (resp.status == 200) {
            setSuccess(true);
          }
          setIsLoading(false);
        })
        .catch(e => {
          console.log(e);
        });
    } else if (userId) {
      apiHelper
        .checkUserEmailValidation(userId)
        .then(resp => {
          if (resp.data.isEmailValid) {
            setSuccess(true);
          }
          setIsLoading(false);
        })
        .catch(e => {
          console.log(e);
        });
    }
  }, []);

  const sendValidationEmail = () => {
    setIsLoading(true);
    apiHelper
      .sendUserValidationEmail(userId)
      .then(resp => {
        setIsLoading(false);
        return Swal.fire({
          title: 'Email de validación enviado',
          icon: 'success',
          confirmButtonColor: '#22654e',
          confirmButtonText: 'Continuar',
        });
      })
      .catch(e => {
        console.error(e);
      });
  };

  if (isLoading) return <Loader />;

  return (
    <div className={`d-flex flex-column h-100 fill-height ${props.className}`}>
      <div className="container mt-3 ">
        <div className="px-5 py-4 w-50 m-auto userForm ">
          <h4 className="text-center vpsy-font vpsy-font-color my-4">
            {success == true
              ? 'Tu mail ha sido validado!'
              : 'Tu mail no se pudo validar'}
          </h4>

          {/* Button submit */}
          <div className="row justify-content-md-center my-3">
            <div className="col mx-2">
              {success == true ? (
                <Link to="/appointment">
                  <button
                    className="btn btn-vpsy-outline btn-block fadeIn fourth"
                    type="button">
                    Continuar
                  </button>
                </Link>
              ) : (
                <button
                  className="btn btn-vpsy-solid btn-block mr-4 fadeIn fourth"
                  onClick={sendValidationEmail}
                  type="button">
                  Reenviar email
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailValidation;
