import moment from 'moment';
import React from 'react';

export default {
  formatDateForAPI: date => {
    return moment(date).toISOString(); //.format('YYYY-MM-DDTHH:mm:ss.sss');
  },

  formatPastDateForAPI: date => {
    // this caps date to now
    return moment(date).isBefore(moment())
      ? moment(date).toISOString()
      : moment().toISOString();
  },

  formatNextDayForAPI: date => {
    return moment(date).add(1, 'days').toISOString(); //.format('YYYY-MM-DDTHH:mm:ss.sss');
  },

  dateStringToDate: dateString => {
    return moment(dateString).toDate();
  },

  formatDate: date => {
    return moment(date).format('DD/MM/YYYY');
  },

  formatPaymentDate: date => {
    return moment(date).format('YYYY-MM-DD');
  },

  formatDateTime: date => {
    return moment(date).format('DD/MM/YYYY HH:mm:ss');
  },

  formatDateTimeHours: date => {
    return moment(date).format('YYYY-MM-DDT00:00:00.000');
  },

  getColorByIndex: index => {
    const colors = [
      '#3cb44b',
      '#4363d8',
      '#e6194b',
      '#f58231',
      '#f032e6',
      '#bcf60c',
      '#008080',
      '#9a6324',
      '#800000',
      '#808000',
    ];
    return colors[index % colors.length];
  },

  formatFromTarget: target => {
    let value;
    switch (target.type) {
      case 'checkbox':
        value = target.checked;
        break;
      case 'number':
        value = Number(target.value);
        break;
      case 'react-datetime':
        value = moment(target.value).toISOString();
        break;
      default:
        value = target.value;
    }
    return value;
  },

  isSameDayOrAfter: date => {
    let appointmentDate = moment(date).hours(1).minutes(0).seconds(0);
    let currentDate = moment().hours(0).minutes(0).seconds(0);
    return appointmentDate.isSameOrAfter(currentDate);
  },

  formatSubscriptionStatus(status) {
    switch (status) {
      case 'unauthorized':
        return 'No suscripto';
        break;
      case 'authorized':
        return 'Activa';
        break;
      case 'paused':
        return 'Pausada';
        break;
      case 'cancelled':
        return 'No suscripto';
        break;
      case 'freeTrial':
        return 'Prueba gratuita';
        break;
      default:
        return 'Procesando';
    }
  },

  calculateResult(score, mean, deviation) {
    let result = (score - mean) / deviation; // equals Z value

    //testing bypassing shitty DB I designed
    return this.calculateDs(score, mean, deviation);
    // return scaleResult? scaleResult.result : 0;
  },

  // Literally rounding Z-score
  calculateDs(score, mean, deviation) {
    let result = (score - mean) / deviation;
    if (!score) return '';
    if (result >= -5.68 && result <= -2.76) return -3;
    if (result >= -2.75 && result <= -2.26) return -2.5;
    if (result >= -2.25 && result <= -1.76) return -2;
    if (result >= -2.75 && result <= -1.26) return -1.5;
    if (result >= -1.25 && result <= -0.76) return -1;
    if (result >= -0.75 && result <= -0.26) return -0.5;
    if (result >= -0.25 && result <= 0.24) return 0;
    if (result >= 0.25 && result <= 0.75) return 0.5;
    if (result >= 0.76 && result <= 1.25) return 1;
    if (result >= 1.26 && result <= 1.75) return 1.5;
    if (result >= 1.76 && result <= 2.25) return 2;
    if (result >= 2.26 && result <= 2.75) return 2.5;
    if (result >= 2.76 && result <= 107.19) return 3;
    return '';
  },

  // Adds dashes between numbers
  formatPhoneNumber(numberString) {
    const formattedNumber = numberString.replace(
      /(\d+)(\d{4})(\d{4})/,
      '$1-$2-$3',
    );
    return formattedNumber;
  },
};
