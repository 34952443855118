import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import './ExamSection.css';
import { ExamContext } from '../ExamContext';
import { EXAM_SECTION } from '../ExamConstants';
import './Conclusions.css';

const ExamFinish = () => {
  const {
    exam,
    setExam,
    patient,
    setPatient,
    currentSection,
    setCurrentSection,
  } = useContext(ExamContext);

  return (
    <>
      <div className="d-flex flex-column h-100">
        <div className="exam-section-title mt-y">Finalizar evaluación</div>
        <div className="w-100 d-flex">
          <div className="exam-line-green" />
          <div className="exam-line-divider" />
        </div>

        <div className="mt-3">
          <div className="container">
            <div className="container w-100 h-100 no-conclusions-sign">
              <div class="row justify-content-center mb-3">
                <div class="col-8 text-center">
                  <i className="fas fa-check-circle"></i>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-8 text-center no-conclusions-text font-weight-bold mb-2">
                  COMPLETASTE TODOS LOS PASOS
                </div>
              </div>
              <div class="row justify-content-center">
                <div className="d-flex justify-content-center w-100">
                  <button
                    className="col-md-3 btn-vpsy-outline exam-btn-txt my-3 mx-1 bg-white"
                    onClick={() => {
                      setCurrentSection(EXAM_SECTION.ATTACHMENTS);
                    }}>
                    Volver
                  </button>
                  <Link
                    to={`${process.env.PUBLIC_URL}/patient/${exam.patient.id}/records`}
                    className="col-md-3 btn-vpsy-gradient exam-btn-txt my-3 mx-1">
                    <button className="bg-transparent" type="button">
                      Ir a ficha de paciente
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExamFinish;
