import React, { useContext } from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../common/Footer';
import apiHelper from '../../utils/apiHelpers';
import Pagination from '../common/Pagination';
import { withAlert } from 'react-alert';
import moment from 'moment';
import Exam from './Exam';
import './Exam.css';
import { Steps } from 'intro.js-react';
import ExamSideBar from './examSection/ExamSideBar';
import '../common/Steps.css';
import 'intro.js/introjs.css';
import { examsSteps } from './ExamSteps';
import { stepsStorage, STEPS_SECTIONS } from '../common/Steps';
import ExamPatient from './examSection/ExamPatient';
import ExamTests from './examSection/ExamTests';
import { ExamContext } from './ExamContext';
import ExamConclusions from './examSection/ExamConclusions';
import { EXAM_SECTION } from './ExamConstants';
import ExamAttachments from './examSection/ExamAttachments';
import ExamFinish from './examSection/ExamFinish';
import { HELP_DATA, videoHeight, videoWidth } from '../help/Help';
import Modal from 'react-bootstrap/Modal';

const STEPS_SECTION = STEPS_SECTIONS.EXAMS;

const Exams = props => {
  const examId = props.match.params.id;
  const [exam, setExam] = useState({
    id: null,
    createdAt: '',
    conclusion: '',
    patient: {
      id: null,
      firstName: '',
      lastName: '',
    },
    results: [],
    testConclusion: [],
  });
  const [patient, setPatient] = useState(null);
  const [currentSection, setCurrentSection] = useState(0);
  const examContextValue = {
    exam,
    setExam,
    patient,
    setPatient,
    currentSection,
    setCurrentSection,
  };
  const [modalVideoID, setModalVideoID] = useState('');

  const sections = [
    <ExamPatient />,
    <ExamTests />,
    <ExamConclusions />,
    <ExamAttachments />,
    <ExamFinish />,
  ];

  useEffect(() => {
    // if test already exists:
    if (examId) {
      apiHelper.getExam(examId).then(result => {
        setExam(result.data);
      });
    }
  }, [examId]);

  return (
    <>
      <Modal
        show={modalVideoID}
        animation={false}
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => {
          setModalVideoID('');
        }}
        className="flex-column"
        centered>
        <iframe
          width={videoWidth}
          height={videoHeight}
          src={'https://www.youtube.com/embed/{id}?autoplay=1&rel=0'.replace(
            '{id}',
            modalVideoID,
          )}
          title="YouTube video player"
          frameborder="0"
          allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
      </Modal>

      <div className={'d-flex flex-column fill-height ' + props.className}>
        <div className="container">
          {/* Add patient banner */}
          {currentSection === 0 ? (
            <div className="flip-card">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  {/* Content */}
                  <div className="add-patient-banner mt-2">
                    <div className="container h-100">
                      <div className="row h-100 align-items-center px-3">
                        <div className="col-1 mx-3 add-patient-banner-icon">
                          <i className="fas fa-clipboard-check "></i>
                        </div>
                        <div className="col">
                          <div className="row">
                            <p className="text-white add-patient-banner-text mb-0">
                              <span className="font-weight-bold">¡Recordá</span>{' '}
                              que para realizar un estudio{' '}
                              <span className="font-weight-bold">
                                el paciente debe estar ingresado!
                              </span>
                            </p>
                          </div>
                          <div className="row">
                            <Link
                              to={`${process.env.PUBLIC_URL}/patient/new`}
                              className="pl-1">
                              <span className="add-patient-banner-link">
                                AGREGAR PACIENTE
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flip-card-back">
                  {/* CONTENT */}
                  <div className="watch-video-banner mt-2">
                    <div className="container h-100">
                      <div className="row h-100 align-items-center px-3">
                        <div className="col-1 mx-3 watch-video-banner-icon">
                          <i
                            className="fab fa-youtube"
                            onClick={() => {
                              setModalVideoID(HELP_DATA.Evaluaciones[0].ytID);
                            }}></i>
                        </div>
                        <div className="col">
                          <div className="row">
                            <p className="watch-video-banner-text mb-0">
                              <span className="font-weight-bold">
                                {'¿Necesitas ayuda? '}
                              </span>
                              Mirá el ejemplo para orientarte.
                            </p>
                          </div>
                          <div
                            className="row"
                            onClick={() => {
                              setModalVideoID(HELP_DATA.Evaluaciones[0].ytID);
                            }}>
                            <span className="watch-video-banner-link">
                              Ver video: Cómo cargar un examen"
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="watch-video-banner mt-2">
              <div className="container h-100">
                <div className="row h-100 align-items-center px-3">
                  <div className="col-1 mx-3 watch-video-banner-icon">
                    <i
                      className="fab fa-youtube"
                      onClick={() => {
                        setModalVideoID(HELP_DATA.Evaluaciones[0].ytID);
                      }}></i>
                  </div>
                  <div className="col">
                    <div className="row">
                      <p className="watch-video-banner-text mb-0">
                        <span className="font-weight-bold">
                          {'¿Necesitas ayuda? '}
                        </span>
                        Mirá el ejemplo para orientarte.
                      </p>
                    </div>
                    <div
                      className="row"
                      onClick={() => {
                        setModalVideoID(HELP_DATA.Evaluaciones[0].ytID);
                      }}>
                      <span className="watch-video-banner-link">
                        Ver video: Cómo cargar un examen
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <h3 className="exam-title mt-3 mb-2">Realizar evaluación</h3>

          <ExamContext.Provider value={examContextValue}>
            <div className="exam-card w-100">
              <div className="exam-card-topbar w-100" />
              <div className="container w-100">
                <div className="row">
                  <div className="col-2 md-hide">
                    <ExamSideBar />
                  </div>
                  <div className="col-10 exam-content-min-height md-full-width">
                    {/* exam card main display */}
                    {sections[currentSection % sections.length]}
                  </div>
                </div>
              </div>
            </div>
          </ExamContext.Provider>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Exams;
