import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import './Sections.css';

const style = {
  'background-color': '#D0CFCF',
};

const containerVar = {
  initial: {
    opacity: 0,
    scale: 0,
  },
  show: {
    scale: 1,
    opacity: 1,
    transition: { duration: 0.3, type: 'tween' },
  },
};

const TopBar = props => {
  return (
    <Link to="/login" className="p-2">
      <motion.div variants={containerVar} initial="initial" animate="show">
        <div className="d-flex justify-content-center">
          <button type="button" className="btn login-sign btn-lg btn-block">
            <h3 className="text-white">
              ¿Ya sos usuario? Hacé click acá para iniciar sesión.
            </h3>
          </button>
        </div>
      </motion.div>
    </Link>
  );
};

export default TopBar;
