// This connects onboard steps with localStorage
export const stepsStorage = {
  isDone: sectionName => {
    return localStorage.getItem(sectionName) === 'done';
  },
  setDone: sectionName => {
    return localStorage.setItem(sectionName, 'done');
  },
  removeOneDone: sectionName => {
    return localStorage.removeItem(sectionName);
  },
  removeAllDone: () => {
    for (const section in STEPS_SECTIONS) {
      localStorage.removeItem(STEPS_SECTIONS[section]);
    }
    return;
  },
};

export const STEPS_SECTIONS = {
  APPOINTMENT: 'appointment',
  APPOINTMENT_DETAIL: 'appointmentDetail',
  EXAMS: 'exams',
  EXAMS_ROWS: 'examsRows',
  PATIENT: 'patient',
};
