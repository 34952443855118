import React, { useState, useEffect, useContext } from 'react';
import './Exam.css';
import { ExamContext } from './ExamContext';

const ExportPDFBtn = props => {
  const {
    className,
    disabled,
    // parentTabs,
    setTriggerExport,
    pdfExportOptions,
    setPdfExportOptions,
  } = props;

  const {
    exam,
    setExam,
    patient,
    setPatient,
    currentSection,
    setCurrentSection,
  } = useContext(ExamContext);

  const [parentTabs, setParentTabs] = useState([
    ...new Set(exam.results.map(result => result.studyType.parentTest.name)),
  ]);

  useEffect(() => {
    // Setting initital options
    let allParentTestsNames = [
      ...new Set(exam.results.map(result => result.studyType.parentTest.name)),
    ];
    setParentTabs(allParentTestsNames);
    setPdfExportOptions(curr => {
      return {
        ...curr,
        includedParentTests: allParentTestsNames,
      };
    });
  }, [exam]);

  const onChangeHandler = event => {
    const target = event.target;
    const checked = target.checked;

    setPdfExportOptions(curr => {
      let options = curr;
      if (target.name === 'parentTest') {
        let index = options.includedParentTests.indexOf(target.value);
        if (checked && index === -1) {
          options.includedParentTests.push(target.value);
        } else if (index !== -1) {
          options.includedParentTests.splice(index, 1);
        }
        return options;
      }
      options[target.name] = checked;
      return options;
    });
  };

  return (
    <>
      <button
        type="button"
        className={className}
        disabled={disabled}
        data-toggle="modal"
        data-target="#exampleModalCenter">
        <i className="fas fa-download md-hide"></i> Descargar PDF{' '}
      </button>

      <div
        className="modal fade"
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        style={{ opacity: 1 }}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Exportar PDF
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              {/* MODAL CONTENT */}
              <div className="container-fluid">
                {/* {parentTabs.some(
                  testName => testName == 'WISC_V' || testName == 'WAIS_IV',
                ) ? (
                  <div className="row">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="includeCharts"
                        name="includeCharts"
                        onChange={onChangeHandler}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="includeCharts">
                        Incluir gráficos (solo WISC_V y WAIS_IV)
                      </label>
                    </div>
                  </div>
                ) : (
                  <></>
                )} */}

                <div className="row">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="includeConclusions"
                      name="includeConclusions"
                      onChange={onChangeHandler}
                      defaultChecked
                    />
                    <label
                      className="form-check-label"
                      htmlFor="includeConclusions">
                      Incluir conclusiones
                    </label>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col p-0">
                    <div className="h6 mb-1"> Estudios incluidos: </div>
                    {parentTabs.map((val, i) => {
                      return (
                        <div className="row px-3" key={val}>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={val}
                              id={val}
                              name="parentTest"
                              onChange={onChangeHandler}
                              defaultChecked
                            />
                            <label className="form-check-label" htmlFor={val}>
                              {val}
                            </label>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-vpsy-outline"
                data-dismiss="modal">
                Cerrar
              </button>
              <button
                type="button"
                className="btn btn-vpsy-solid"
                data-dismiss="modal"
                onClick={async () => {
                  // trigger export png, then export
                  await setTriggerExport(true); // toggle to trigger update pnd data
                }}>
                Exportar PDF
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExportPDFBtn;
