import apiHelper from '../../utils/apiHelpers';
import React from 'react';
import { useState, useEffect, useRef } from 'react';
import './ProfilePicture.css';
import apiHelpers from '../../utils/apiHelpers';

const pfpPlaceholder = process.env.PUBLIC_URL + '/img/mentaly-512.png';
const imageExtensions = ['jpg', 'png', 'jpeg', 'gif'];

const ProfilePicture = props => {
  const [pfpBlob, setPfpBlob] = useState(null);
  const pfpButton = useRef(null);

  useEffect(() => {
    refreshPFP();
  }, []);

  const refreshPFP = () => {
    apiHelper
      .getProfilePicture()
      .then(resp => {
        if (resp.status === 204) return; // no content
        let blob = new Blob([resp.data]);
        setPfpBlob(URL.createObjectURL(blob));
      })
      .catch(e => {
        console.error(e);
      });
  };

  const handlePFPUpload = event => {
    let selectedFile = event.target.files[0];
    const fileExtension = selectedFile.name.split('.').pop();

    if (imageExtensions.includes(fileExtension)) {
      // Create an object of formData
      const formData = new FormData();

      // Update the formData object
      formData.append('img', selectedFile, selectedFile.name);

      // Request made to the backend api
      // Send formData object
      apiHelpers.setProfilePicture(formData).then(response => {
        if (response.status === 200) {
          refreshPFP();
        }
      });
    } else {
      alert("La foto de perfil debe ser 'jpg', 'png', 'jpeg' o 'gif'.");
    }
  };

  return (
    <>
      <img
        id="image"
        src={pfpBlob ? pfpBlob : pfpPlaceholder}
        // className="pfp img-thumbnail rounded-circle"
        className={`${props.profileCard ? 'pfp-card' : 'pfp-img'} ${
          props.clickable ? '' : 'no-hover'
        }`}
        onClick={
          props.clickable &&
          (() => {
            pfpButton.current.click();
          })
        }
        title="Click para cambiar la foto de perfil."
      />
      <input
        type="file"
        id="myfile"
        ref={pfpButton}
        className="pfp-input"
        onChange={handlePFPUpload}
      />
    </>
  );
};

export default ProfilePicture;
