import React from 'react';
import { useEffect, useState, useRef } from 'react';
import apiHelper from '../../utils/apiHelpers';
import Footer from '../common/Footer';
import NewExam from './NewExam';
import moment from 'moment';
import SelectPatient from './../common/selects/SelectPatient';
import CollapsableCharts from './CollapsableCharts';
import ExportPDFBtn from './ExportPDFBtn';
import { generatePDFDocument } from './ExamPDF';
// import Conclusions from './Conclusions';
import SpinnerModal from '../common/SpinnerModal';
import AddStudyBtn from './AddStudyBtn';
import Attachments from './Attachments';
import { wiscV_ids, waisIV_ids } from '../chart/ChartsFunctions';
import { Steps } from 'intro.js-react';
import { examRowsSteps } from './ExamSteps';
import '../common/Steps.css';
import 'intro.js/introjs.css';
import { stepsStorage, STEPS_SECTIONS } from '../common/Steps';
import Swal from 'sweetalert2';
import apiHelpers from '../../utils/apiHelpers';

const STEPS_SECTION = STEPS_SECTIONS.EXAMS_ROWS;

const ExamRows = props => {
  const examId = props.match.params.id;
  const isNew = examId === 'new';
  const [parentTests, setParentTests] = useState([]);
  const [studyTypes, setStudyTypes] = useState([]);
  const [patient, setPatient] = useState({
    id: -1,
    firstName: '',
    lastName: '',
    age: -1,
    birthDate: '',
    email: '',
    phone: '',
    description: '',
  });
  const [exam, setExam] = useState({
    id: examId,
    createdAt: '',
    conclusion: '',
    patient: {
      id: null,
      firstName: '',
      lastName: '',
    },
    results: [],
  });
  const [addedTestsTabs, setAddedTestsTabs] = useState({
    selectedIndex: -1,
    highlightedName: '',
    tabNames: ['Todo'],
  });

  const [tabRows, setTabRows] = useState([]);

  const [rawConclusions, setRawConclusions] = useState('');

  // Charts PNG data && PDF export
  const [triggerExport, setTriggerExport] = useState(false);
  const [graphsPngObj, setGraphsPngObj] = useState(null); // {..., graphId: Promise<blobString>, ...}
  const [pdfExportOptions, setPdfExportOptions] = useState({
    includeConclusions: false,
    includeCharts: false,
    includedParentTests: [],
  });

  const removeTest = index => {
    Swal.fire({
      title: `¿Desea eliminar el estudio de la lista?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#22654e',
      customClass: {
        cancelButton: 'swal-cancel-btn', // Add your custom class for the cancel button
      },
      confirmButtonText: 'Si, eliminar.',
    }).then(result => {
      if (result.isConfirmed) {
        let newResultsArray = exam.results.slice();
        newResultsArray.splice(index, 1);
        const newExam = { ...exam, ['results']: newResultsArray };
        setExam(newExam);
        Swal.fire({
          title: `Estudio eliminado.`,
          icon: 'success',
          confirmButtonColor: '#22654e',
          confirmButtonText: 'Continuar',
        });
      }
    });
  };

  const onChangeTest = (index, result) => {
    let newResultsArray = exam.results.slice();
    newResultsArray.splice(index, 1, result);
    const newExam = { ...exam, ['results']: newResultsArray };
    setExam(newExam);
  };

  const saveExam = async e => {
    e.preventDefault();
    try {
      //If requests already exists, update it. If not, create it
      if (isNew) {
        await apiHelper.postExam(exam);
      } else {
        await apiHelper.patchExam(exam.id, exam);
      }

      props.history.push('/exam');
    } catch (e) {
      console.error(e);
    }
  };

  //////////////
  // UseEffects
  //////////////

  // UseEffect: Always
  useEffect(() => {
    apiHelper
      .getParentTests()
      .then(parentTestsRes => {
        setParentTests(parentTestsRes.data);
      })
      .catch(e => {
        console.error(e);
      });

    apiHelper
      .getStudyTypes()
      .then(studyTypesRes => {
        setStudyTypes(studyTypesRes.data);
      })
      .catch(e => {
        console.error(e);
      });

    if (!isNew) {
      let patientAge = -1; // because states are async
      apiHelper
        .getExam(examId)
        .then(examResponse => {
          setExam(examResponse.data);
          setRawConclusions(examResponse.data.conclusion);
          patientAge = calculatePatientAge(examResponse.data.patient.birthDate);
          setPatient({
            ...examResponse.data.patient,
            age: patientAge,
          });
        })

        .then(() => {
          setAddedTestsTabs(curr => {
            return { ...curr, selectedIndex: 0 };
          });
        })
        .catch(e => {
          console.log(e);
        });
    }

    // adding ids properties to graphPngObj
    setGraphsPngObj(prevState => {
      let newIds = prevState;
      if (
        exam.results.some(result => {
          return result.studyType.parentTest.name === 'WISC_V';
        })
      ) {
        for (const chart_id of wiscV_ids) {
          if (newIds === null) {
            newIds = { [chart_id]: null };
          } else if (!newIds.hasOwnProperty(chart_id)) {
            newIds = { ...newIds, [chart_id]: null };
          }
        }
      }

      return newIds;
    });
  }, []);

  // UseEffect: addedTestsTabs
  useEffect(() => {
    const uniqueParentTests = [
      ...new Set(
        exam.results.map(test => {
          return test.studyType.parentTest.name;
        }),
      ),
    ];
    setAddedTestsTabs(currTests => {
      return {
        ...currTests,
        tabNames: ['Todo', ...uniqueParentTests.reverse()],
      };
    });
  }, [exam.results]);

  // UseEffect: Tab selected index, processes what tests are rendered
  useEffect(() => {
    let studyArray = exam.results
      .filter(test => {
        if (addedTestsTabs.selectedIndex === 0) {
          return true;
        }
        return (
          test.studyType.parentTest.name ===
          addedTestsTabs.tabNames[addedTestsTabs.selectedIndex]
        );
      })
      .sort((a, b) => {
        if (a.studyType.category.id > b.studyType.category.id) return 1;
        if (a.studyType.category.id < b.studyType.category.id) return -1;
        return 0;
      });
    setTabRows(studyArrayToJSX(studyArray));
  }, [addedTestsTabs.selectedIndex, exam.results, parentTests]);

  // UseEffect: rawConclusions
  useEffect(() => {
    let newExamState = { ...exam, conclusion: rawConclusions };
    setExam(newExamState);
  }, [rawConclusions]);

  // UseEffect: png render
  const pdfExportingFlag = useRef(null);
  useEffect(() => {
    if (pdfExportingFlag.current) return;
    if (triggerExport && !pdfExportOptions.includeCharts) {
      // No chart rendering
      pdfExportingFlag.current = setTimeout(function () {
        generatePDFDocument(
          pdfExportOptions,
          exam,
          graphsPngObj,
          setTriggerExport,
        );
        pdfExportingFlag.current = null;
      }, 1000);
    } else if (triggerExport && graphsPngObj !== null) {
      let pngPromisesLoaded = !Object.values(graphsPngObj).some(
        val => val === null,
      );
      if (pngPromisesLoaded) {
        const promiseArrayPNG = [];
        for (const id in graphsPngObj) {
          promiseArrayPNG.push(graphsPngObj[id]);
        }
        setTimeout(function () {
          generatePDFDocument(
            pdfExportOptions,
            exam,
            graphsPngObj,
            setTriggerExport,
          );
          pdfExportingFlag.current = null;
        }, 1000);
      }
    }
  }, [triggerExport, graphsPngObj]);

  /************
    END OF USEEFFECTS
  *******************/

  const onChange = async e => {
    const name = e.target.name;
    const value = e.target.value;
    let newExamState = { ...exam, [name]: value };
    setExam(newExamState);
    apiHelper
      .getPatient(newExamState.patient.id)
      .then(res => {
        setPatient({
          ...res.data,
          age: calculatePatientAge(newExamState.patient.birthDate),
        });
      })
      .catch(e => {
        console.log(e);
      });
  };

  const calculatePatientAge = birthDate => {
    let now = moment();
    let duration = moment.duration(now.diff(birthDate));
    let years = duration.asYears();
    return Math.floor(years);
  };

  let patient_bar;
  if (patient.id < 0) {
    patient_bar = (
      <SelectPatient
        placeholder="Ingrese Paciente"
        className={patient.id < 0 ? 'animated-glow' : null}
        name="patient"
        value={patient}
        onChange={onChange}
        isDisabled={false}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderRadius: '50px',
            fontSize: '12px',
          }),
          container: (baseStyles, state) => ({
            ...baseStyles,
            width: '100%',
            height: '43px',
          }),
        }}
      />
    );
  } else {
    patient_bar = (
      <div className="container">
        <div className="row mt-2">
          <div className="col">
            <div className="h2 font-weight-bold text-sm-left">
              {' '}
              {patient.firstName} {patient.lastName}{' '}
            </div>
          </div>
          <div className="col">
            <p className="h2 text-sm-center">
              {exam.createdAt != ''
                ? moment(exam.createdAt).format('DD/MM/YYYY')
                : moment().format('DD/MM/YYYY')}
            </p>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-sm">
            <div className="row h6 font-weight-bold">Fecha de nacimiento</div>
            <div className="row">{`${moment(patient.birthDate).format(
              'DD/MM/YYYY',
            )}  [${patient.age} años]`}</div>
          </div>
          <div className="col-sm">
            <div className="row h6 font-weight-bold">Email</div>
            <div className="row">{`${patient.email}`}</div>
          </div>
          <div className="col-sm">
            <div className="row h6 font-weight-bold">Contacto</div>
            <div className="row">{`${patient.phone}`}</div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col">
            <div className="row h6 mb-2 font-weight-bold">Detalles</div>
            <div className="row">{`${patient.details || 'Sin notas.'}`}</div>
          </div>
        </div>
      </div>
    );
  }

  const studyArrayToJSX = studyArray => {
    let lastCategory = '';
    return studyArray.map((test, i) => {
      let isNewCategory = false;
      if (lastCategory != test.studyType.category.categoryName) {
        lastCategory = test.studyType.category.categoryName;
        isNewCategory = true;
      }
      return (
        <NewExam
          key={i}
          isNewCategory={isNewCategory}
          result={test}
          removeTest={removeTest}
          index={i}
          onChangeTest={onChangeTest}
          parentTestsRaw={parentTests}
          studyTypesRaw={studyTypes}
          deleteCategory={deleteCategory}
        />
      );
    });
  };

  const deleteCategory = category => {
    Swal.fire({
      title: `¿Desea eliminar la categoría "${category}"?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#22654e',
      customClass: {
        cancelButton: 'swal-cancel-btn', // Add your custom class for the cancel button
      },
      confirmButtonText: 'Si, eliminar.',
    }).then(result => {
      if (result.isConfirmed) {
        setAddedTestsTabs({ ...addedTestsTabs, selectedIndex: 0 });
        setExam(currExam => {
          let deleteFromAll = addedTestsTabs.selectedIndex === 0; // 0 = delete category from all tests
          let results = currExam.results.filter(result => {
            if (deleteFromAll) {
              return result.studyType.category.categoryName !== category;
            }
            return (
              result.studyType.category.categoryName !== category ||
              result.studyType.parentTest.name !==
                addedTestsTabs.tabNames[addedTestsTabs.selectedIndex]
            );
          });
          return { ...currExam, results };
        });
        Swal.fire({
          title: `Categoría "${category}" eliminada.`,
          icon: 'success',
          confirmButtonColor: '#22654e',
          confirmButtonText: 'Continuar',
        });
      }
    });
  };

  const onExit = () => {
    stepsStorage.setDone(STEPS_SECTION);
  };

  return (
    <>
      <SpinnerModal
        titlestring={'Generando Informe...'}
        show={triggerExport}
        animation={false}
        onHide={() => {}}
      />

      {/* <Steps
        enabled={!stepsStorage.isDone(STEPS_SECTION)}
        steps={examRowsSteps.steps}
        initialStep={examRowsSteps.initialStep}
        options={{
          nextLabel: 'Siguiente',
          prevLabel: 'Anterior',
          skipLabel: 'Saltar',
          doneLabel: 'Finalizar',
          showStepNumbers: true,
        }}
        onExit={onExit}
      /> */}
      <div className="d-flex flex-column h-100">
        <div className="container-fluid">
          <div className="row my-3">
            <div
              className={`col-sm-6 mt-1 ${
                patient.id < 0 ? null : 'border border-secondary rounded'
              }`}>
              <div> {patient_bar}</div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <AddStudyBtn
                  className="secondStep col-sm fadeInfromRigth my-1"
                  isDisabled={patient.id < 0}
                  studyEmpty={exam.results.length < 1}
                  parentTests={parentTests}
                  exam={exam}
                  setExam={setExam}
                  setAddedTestsTabs={setAddedTestsTabs}
                  patient={patient}
                />
                <ExportPDFBtn
                  className="seventhStep col-sm btn btn-dl-pdf fadeInfromRigth mx-1 my-1 text-nowrap"
                  disabled={exam.results.length <= 0 || patient.id < 0}
                  exam={exam}
                  parentTabs={addedTestsTabs.tabNames}
                  setTriggerExport={setTriggerExport}
                  setPdfExportOptions={setPdfExportOptions}
                />
                <button
                  type="submit"
                  className="col-sm btn button-exam fadeInfromRigth mx-1 my-1 text-nowrap"
                  disabled={patient.id < 0}
                  onClick={saveExam}>
                  Guardar <i className="fas fa-save saveicon"></i>{' '}
                </button>
              </div>
            </div>
          </div>

          <h4 className="thirdStep text-oceandrive mt-5">
            <i className="fas fa-list mx-2"></i>
            Estudios y puntajes
          </h4>

          {/* TABS with parent test */}
          <ul className="nav nav-tabs mt-3">
            {addedTestsTabs.tabNames.map((title, index) => {
              return (
                <li className="nav-item" key={index}>
                  <a
                    className={`nav-link nav-font
                      ${
                        index == addedTestsTabs.selectedIndex ? ' active' : ''
                      }                      
                      ${
                        title == addedTestsTabs.highlightedName
                          ? ' animated-glow'
                          : ''
                      }
                      `}
                    href="#"
                    onClick={() => {
                      setAddedTestsTabs(curr => {
                        return {
                          ...curr,
                          highlightedName:
                            curr.highlightedName === curr.tabNames[index]
                              ? ''
                              : curr.highlightedName,
                          selectedIndex: index,
                        };
                      });
                    }}>
                    {title}
                  </a>
                </li>
              );
            })}
          </ul>

          <table className="table-responsive table-fixed">
            <thead className="headExams">
              <tr>
                <th className="col-test-type">Tipo de estudio</th>
                <th className="col-test-name">Nombre de estudio</th>
                <th className="col-number-input">Puntaje</th>
                <th className="col-number">Media</th>
                <th className="col-number">Desvio</th>
                <th className="col-number">Resultado</th>
                <th>
                  -1
                  <hr />
                  -3
                </th>
                <th>
                  1
                  <hr />
                  -2,5
                </th>
                <th>
                  2
                  <hr />
                  -2
                </th>
                <th>
                  7
                  <hr />
                  -1,5
                </th>
                <th>
                  16
                  <hr />
                  -1
                </th>
                <th>
                  31
                  <hr />
                  -0,5
                </th>
                <th>
                  50
                  <hr />0
                </th>
                <th>
                  69
                  <hr />
                  0,5
                </th>
                <th>
                  84
                  <hr />1
                </th>
                <th>
                  93
                  <hr />
                  1,5
                </th>
                <th>
                  98 <hr />2
                </th>
                <th>
                  99
                  <hr />
                  2,5
                </th>
                <th>
                  99 <hr />3
                </th>
                <th style={{ width: '5%' }}>*</th>
              </tr>
            </thead>
            <tbody className="tableExams">
              {studyTypes !== [] && parentTests !== [] ? tabRows : <></>}
            </tbody>
          </table>

          <br />
          {/* Graphs and Collapsibles */}
          <h4 className="text-oceandrive mt-5">
            <i className="fas fa-chart-area mx-2"></i>
            Gráficos de resultados
          </h4>
          {exam.results.length ? (
            <CollapsableCharts
              {...{
                exam,
                patientName: patient.firstName,
                graphsPngObj,
                setGraphsPngObj,
                triggerExport,
              }}
            />
          ) : (
            <></>
          )}

          <h4 className="text-oceandrive mt-5">
            <i className="fas fa-pencil-alt mx-2"></i>Conclusiones de examenes
          </h4>
          {/* <Conclusions
            rawConclusions={rawConclusions}
            setRawConclusions={setRawConclusions}
          /> */}

          <h4 className="text-oceandrive mt-5">
            <i className="fas fa-paperclip mx-2"></i>Archivos adjuntos
          </h4>
          <Attachments examId={examId} />

          <div className="row mb-3">
            <button
              type="submit"
              className="ml-auto col-md-2 btn button-exam fadeInfromRigth mt-5"
              onClick={saveExam}>
              Guardar <i className="fas fa-save saveicon"></i>{' '}
            </button>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ExamRows;
