import React, { useContext } from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import apiHelper from '../../../utils/apiHelpers';
import './ExamSection.css';
import { ExamContext } from '../ExamContext';
import { EXAM_SECTION } from '../ExamConstants';

const ExamSideBar = () => {
  const {
    exam,
    setExam,
    patient,
    setPatient,
    currentSection,
    setCurrentSection,
  } = useContext(ExamContext);
  const addedParentTestsNames = [
    ...new Set(exam.results?.map(result => result.studyType.parentTest.name)),
  ];

  const saveTest = async e => {
    e.preventDefault();
    try {
      // clear all results with no score
      let cleanResults = exam.results.filter(score => {
        return parseFloat(score.result) !== NaN && score.result !== '';
      });
      let cleanExam = { ...exam, results: cleanResults };

      //If requests already exists, update it. If not, create it
      let response;
      if (!exam.id) {
        response = await apiHelper.postExam(cleanExam);
      } else {
        response = await apiHelper.patchExam(exam.id, cleanExam);
      }
      setExam(response.data);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    // https://stackoverflow.com/questions/37119835/add-horizontal-lines-between-elements
    <div className="d-flex flex-column justify-content-between exam-sidebar my-5">
      <div>
        <div
          className={`exam-sidebar-item 
					${exam.patient.id ? 'cursor-pointer ' : ' '}
					${currentSection === 0 ? 'active' : 'inactive'}`}
          onClick={e => {
            setCurrentSection(EXAM_SECTION.PATIENT);
          }}>
          <i className={`fas fa-user-circle`} />
          Paciente
        </div>
        <p className="pl-4 mt-1 exam-sidebar-sub-item">{`${
          exam.patient?.firstName || ''
        } ${exam.patient?.lastName || ''}`}</p>
      </div>

      <div className="exam-sidebar-divider"></div>

      <div>
        <div
          className={`exam-sidebar-item 
					${exam.patient.id ? 'cursor-pointer ' : ' '}
					${currentSection === 1 ? 'active' : 'inactive'}`}
          onClick={e => {
            if (!exam.patient.id) return;
            setCurrentSection(EXAM_SECTION.TESTS);
          }}>
          <i className={`fas fa-file-signature`} />
          Tests
        </div>
        {addedParentTestsNames.map((name, i) => {
          return (
            <p
              className="pl-4 mt-1 exam-sidebar-sub-item"
              key={i}>{`${name}`}</p>
          );
        })}
      </div>

      <div className="exam-sidebar-divider"></div>

      <div
        className={`exam-sidebar-item  
					${exam.patient.id ? 'cursor-pointer ' : ' '}
					${currentSection === 2 ? 'active' : 'inactive'}`}
        onClick={e => {
          if (!exam.patient.id) return;
          setCurrentSection(EXAM_SECTION.CONCLUSIONS);
        }}>
        <i className={`fas fa-pen-nib`} />
        Conclusiones
      </div>
      <div className="exam-sidebar-divider"></div>
      <div
        className={`exam-sidebar-item 
					${exam.patient.id ? 'cursor-pointer' : ' '}
					${currentSection === 3 ? 'active' : 'inactive'}`}
        onClick={e => {
          if (!exam.patient.id) return;
          setCurrentSection(EXAM_SECTION.ATTACHMENTS);
        }}>
        <i className={`fas fa-paperclip`} />
        Adjuntos
      </div>
      <div className="exam-sidebar-divider"></div>
      <div
        className={`exam-sidebar-item ${
          currentSection === EXAM_SECTION.FINISH ? 'active' : 'inactive'
        }`}>
        <i className={`fas fa-check-circle`} />
        Finalizar
      </div>
    </div>
  );
};

export default ExamSideBar;
