import React from 'react';
import PropTypes from 'prop-types';
import './Pagination.css';

function Pagination(props) {
  if (!props.totalPages) return null;

  const isFirstPage = props.currentPage === 1;
  const isLastPage = props.totalPages === props.currentPage;

  function range(start, end) {
    return Array.apply(0, Array(end - start)).map(
      (element, index) => index + start,
    );
  }

  return (
    <div className={props.className}>
      <ul className="pagination justify-content-center">
        <li
          className={'page-item' + (isFirstPage ? ' disabled' : '')}
          onClick={
            isFirstPage
              ? () => {}
              : () => props.onChangePage(props.currentPage - 1)
          }>
          <span className="page-arrow m-3">
            <i className="fas fa-chevron-left " />
          </span>
        </li>

        <li>
          <span className="page-text" href="">
            <b>Pag {props.currentPage}</b> de {props.totalPages}
          </span>
        </li>

        <li
          className={'page-item' + (isLastPage ? ' disabled' : '')}
          onClick={
            isLastPage
              ? () => {}
              : () => props.onChangePage(props.currentPage + 1)
          }>
          <span className="page-arrow m-3">
            <i className="fas fa-chevron-right" />
          </span>
        </li>
      </ul>
    </div>
  );
}

Pagination.propTypes = {
  onChangePage: PropTypes.func.isRequired,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
};

export default Pagination;
