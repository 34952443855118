import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import apiHelper from '../../utils/apiHelpers';
import formatter from '../../utils/formatter';
import { Tooltip, Whisper } from 'rsuite';
import moment from 'moment';

export default () => {
  const [subscription, setSubscription] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [subHTML, setSubHTML] = useState(null);

  useEffect(() => {
    apiHelper
      .getCurrentUserSubscription()
      .then(response => {
        setSubscription(response.data);
      })
      .catch(error => {
        console.log(error);
        setErrorMsg(error);
      });
  }, []);

  useEffect(() => {
    let subStatus = <></>;
    let end_date = null;

    let tooltip = (
      <Tooltip>
        This is a help <i>tooltip</i> .
      </Tooltip>
    );

    if (
      subscription.status === 'unsubscribed' ||
      subscription.status === 'cancelled'
    ) {
      tooltip = (
        <Tooltip>
          <Link className="item-list " to={`${process.env.PUBLIC_URL}/profile`}>
            Ir al perfil para suscribirse.
          </Link>
        </Tooltip>
      );

      subStatus = (
        <div className="col-sm text-danger text-center">
          {formatter.formatSubscriptionStatus(subscription.status)}
        </div>
      );
    } else if (subscription.status === 'freeTrial') {
      end_date = moment(subscription.end_date).format('DD/MM/YYYY');
      tooltip = (
        <Tooltip>
          Límite de <b>{subscription.maxPatients}</b> pacientes y{' '}
          <b>{subscription.maxAppointments}</b> turnos. Finaliza el{' '}
          <b>{end_date}</b>.
        </Tooltip>
      );
      subStatus = (
        <div className="col-sm text-primary text-center">
          {formatter.formatSubscriptionStatus(subscription.status)}
        </div>
      );
    } else {
      if (!!subscription.status) {
        end_date = moment(subscription.end_date).format('DD/MM/YYYY');
        tooltip = (
          <Tooltip>
            Finaliza el <b>{end_date}</b>.
          </Tooltip>
        );
        subStatus = (
          <div className="col-sm text-success text-center">
            {formatter.formatSubscriptionStatus(subscription.status)}
          </div>
        );
      }
    }

    if (!!subscription.status) {
      setSubHTML(
        <div className="container">
          <div className="row justify-content-center">
            Estado de suscripción
          </div>
          <div className="row justify-content-center">
            <Whisper
              placement="top"
              controlId="control-id-hover"
              trigger="hover"
              speaker={tooltip}>
              {subStatus}
            </Whisper>
          </div>
        </div>,
      );
    }
  }, [subscription, errorMsg]);

  return (
    <footer className="footer mt-auto">
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-sm-4 mb-2">{subHTML}</div>
        </div>
      </div>
    </footer>
  );
};
