import './styles.scss';

import Document from '@tiptap/extension-document';
import Gapcursor from '@tiptap/extension-gapcursor';
import Paragraph from '@tiptap/extension-paragraph';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import Text from '@tiptap/extension-text';
import { EditorContent, useEditor, BubbleMenu } from '@tiptap/react';
import React, { useEffect, useImperativeHandle } from 'react';
import StarterKit from '@tiptap/starter-kit';

function TipTap(props, ref) {
  const { htmlContent, editable } = props;
  const editor = useEditor({
    extensions: [
      StarterKit,
      Document,
      Paragraph,
      Text,
      Gapcursor,
      Table.configure({
        resizable: true,
      }),
      TableRow,
      TableHeader,
      TableCell,
    ],
    editorProps: {
      attributes: {
        class: 'section-text w-90 px-2 py-3',
      },
    },
    content:
      htmlContent ||
      `
				<table>
          <tbody>
            <tr>
              <th>Name</th>
              <th colspan="3">Description</th>
            </tr>
            <tr>
              <td>Cyndi Lauper</td>
              <td>Singer</td>
              <td>Songwriter</td>
              <td>Actress</td>
            </tr>
          </tbody>
        </table>
      `,
  });

  editor.setEditable(editable);

  // Expose the increment function to the parent component
  useImperativeHandle(ref, () => ({
    getHTML() {
      return editor.getHTML();
    },
  }));

  useEffect(() => {
    // this is just an example. do whatever you want to do here
    // to retrieve your editors content from somewhere
    editor.commands.setContent(htmlContent);
  }, [editor]);

  if (!editor) {
    return null;
  }

  return (
    <>
      <EditorContent editor={editor} />
      <BubbleMenu editor={editor}>
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={`${editor.isActive('bold') ? 'is-active' : ''} + px-1`}>
          Bold
        </button>
        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={`${editor.isActive('italic') ? 'is-active' : ''} + px-1`}>
          Italic
        </button>
        <button
          onClick={() => editor.chain().focus().toggleStrike().run()}
          className={`${editor.isActive('strike') ? 'is-active' : ''} + px-1`}>
          Strike
        </button>
      </BubbleMenu>
    </>
  );
}

export default React.forwardRef(TipTap);
