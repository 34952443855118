import React from 'react';
import apiHelper from '../../../utils/apiHelpers';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { useEffect, useState } from 'react';
//import Select from "react-select/src/Select";

const SelectParentTest = props => {
  const { parentTests, selectedParentTest, superOnChange } = props;
  const [selectedParentTestState, setSelectedParentTestState] = useState(
    parentTests.filter(test => {
      return selectedParentTest.id === test.value.id;
    }),
  );

  useEffect(() => {
    setSelectedParentTestState(
      parentTests.filter(test => {
        return selectedParentTest.id === test.value.id;
      }),
    );
  }, [props]);

  const handleChange = selectedValue => {
    let newValue = {
      mean: '',
      deviation: '',
      category: {
        categoryName: '',
      },
      parentTest: { id: -1, name: '' },
    };

    if (!!selectedValue) {
      newValue.parentTest = selectedValue.value;
    }

    let event = { target: {} };
    event.target.name = props.name ? props.name : 'select-parenttest-nameless';
    event.target.value = newValue;
    event.target.type = 'react-select';

    superOnChange(event);
  };

  if (parentTests) {
    return (
      <Select
        className="parent-test-select"
        onChange={handleChange}
        isClearable={true}
        name="parentTest"
        options={parentTests}
        value={selectedParentTestState}
        menuPosition="fixed"
        required={props.required}
        // Z Index props
        menuPortalTarget={document.body}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
      />
    );
  } else {
    return <></>;
  }
};

export default SelectParentTest;
